import { Http } from 'services/HttpService';
import { helperRedirectReplace } from 'helpers/redirect';
import { notify } from 'utils/notify';

export const deleteProject = (id: number) => {
  Http.delete(`projects/${id}`).then(response => {
    if (response.status === 200) {
      helperRedirectReplace('/projects');
      notify.success('Вы удалили проект');
    }
  });
};
