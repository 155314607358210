export const actions = {
  fetchProjectRequest: (id: string) =>
    ({
      type: '[PROJECT] FETCH_PROJECT_REQUEST',
      payload: id
    } as const),

  fetchProjectSuccess: (project: IProject) =>
    ({
      type: '[PROJECT] FETCH_PROJECT_SUCCESS',
      payload: { project }
    } as const),

  fetchProjectError: (message: string) =>
    ({
      type: '[PROJECT] FETCH_PROJECT_ERROR',
      payload: { message }
    } as const),

  clearProjectData: () =>
    ({
      type: '[PROJECT] CLEAR_PROJECT_DATA'
    } as const)
};
