import { Http } from 'services/HttpService';
// import { helperRedirect } from 'helpers/redirect'
import { notify } from 'utils/notify';

interface ISubmitValues {
  name: string;
  post: string;
  avatar: string;
  description: string;
}

export function submit(values: ISubmitValues, id: number | null) {
  const data = { ...values };
  if (id) {
    Http.patch(`authors/${id}`, { ...data })
      .then(response => {
        if (response.status === 200) {
          notify.success('Автор успешно изменен');
        }
      })
      .catch(notify.error);
  } else {
    Http.post('authors', { ...data })
      .then(response => {
        if (response.status === 200) {
          notify.success('Вы создали автора');
        }
      })
      .catch(notify.error);
  }
}
