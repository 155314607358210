import { v4 as uuidv4 } from 'uuid';
import { ICard } from '.';
import { BLOCK_COLOR_NUM, NUM_OF_COLS } from '../consts';
import { BlockColors, NumOfCols } from '../enums';
import { make } from '../utils/makeElement';

interface IStatus {
  EMPTY: string;
  UPLOADING: string;
  FILLED: string;
}

export default class Ui {
  api: any;
  config: any;
  _status: IStatus[];
  onSelectFile: any;
  setTitle: any;
  setDesc: any;
  setAlt: any;
  setUrl: any;
  addCard: any;
  deleteCard: any;
  nodes: {
    wrapper: HTMLDivElement;
    wrapperGrid: HTMLDivElement;
    cards: HTMLDivElement[];
    buttonAdd: HTMLButtonElement;
    labelBlockColor: HTMLLabelElement;
    selectBlockColor: HTMLSelectElement;
    labelNumCols: HTMLLabelElement;
    selectNumCols: HTMLSelectElement;
  };

  constructor({
    api,
    config,
    onSelectFile,
    addCard,
    setTitle,
    setDesc,
    setAlt,
    setUrl,
    deleteCard
  }: any) {
    this.addCard = addCard;
    this.deleteCard = deleteCard;
    this.api = api;
    this.config = config;
    this.onSelectFile = onSelectFile;
    this.setTitle = setTitle;
    this.setDesc = setDesc;
    this.setAlt = setAlt;
    this.setUrl = setUrl;
    this._status = [];
    this.nodes = {
      wrapper: make('div', [this.CSS.baseClass, this.CSS.wrapper]),
      wrapperGrid: make('div', this.CSS.wrapperGrid),
      cards: [],
      buttonAdd: make('button', this.CSS.buttonAdd),
      labelBlockColor: make('label', this.CSS.labelBlockColor),
      selectBlockColor: make('select', this.CSS.selectBlockColor),
      labelNumCols: make('label', this.CSS.labelNumCols),
      selectNumCols: make('select', this.CSS.selectNumCols)
    };

    this.nodes.buttonAdd.innerText = '+';
    this.nodes.labelBlockColor.innerText = 'Цвет блока: ';
    this.nodes.labelNumCols.innerText = 'Количество колонок: ';

    for (let i = 0; i < BLOCK_COLOR_NUM; i++) {
      let option = document.createElement('option');
      option.text = BlockColors[i];
      this.nodes.selectBlockColor.add(option);
    }

    for (let i = 0; i < NUM_OF_COLS; i++) {
      let option = document.createElement('option');
      option.text = NumOfCols[i];
      this.nodes.selectNumCols.add(option);
    }

    this.nodes.buttonAdd.onclick = () => {
      this.addCard();
      let container: HTMLDivElement = make('div', this.CSS.item);

      this.nodes.cards.push(container);

      let altElem = make('input', this.CSS.itemAlt);
      let titleElem = make('input', this.CSS.itemTitle);
      let descriptionElem = make('div', this.CSS.itemDescription, {
        id: uuidv4()
      });
      let imageContainer = make('div', [this.CSS.imageContainer]);
      let fileButton = this.createFileButton(this.nodes.cards.length - 1);
      let imagePreloader = make('div', this.CSS.imagePreloader);
      let urlElem = make('input', this.CSS.itemUrl);

      altElem.placeholder = 'alt';
      titleElem.placeholder = 'Заголовок';
      descriptionElem.placeholder = 'Описание';
      urlElem.placeholder = 'URL';

      let deleteButton = make('button', this.CSS.deleteButton);
      deleteButton.innerText = 'x';

      descriptionElem.addEventListener('click', () => {
        const id = descriptionElem.id;
        const customEvent = new CustomEvent('openTinymcePlugginModal', {
          detail: {
            id,
            value: descriptionElem.innerHTML
          }
        });
        document.dispatchEvent(customEvent);
      });

      deleteButton.addEventListener('click', (e: Event) => {
        for (let i = 0; i < this.nodes.wrapperGrid.childNodes.length; i++) {
          if (
            this.nodes.wrapperGrid.childNodes[i].childNodes[0] === deleteButton
          ) {
            deleteCard(i);
          }
        }
        deleteButton.parentElement.remove();
      });

      titleElem.addEventListener('input', (e: Event) => {
        for (let i = 0; i < this.nodes.wrapperGrid.childNodes.length; i++) {
          if (
            this.nodes.wrapperGrid.childNodes[i].childNodes[1] ===
            (e.currentTarget as Node)
          ) {
            this.setTitle(
              (
                this.nodes.wrapperGrid.childNodes[i]
                  .childNodes[1] as HTMLInputElement
              ).value,
              i
            );
          }
        }
      });

      descriptionElem.addEventListener('DOMSubtreeModified', (e: Event) => {
        for (let i = 0; i < this.nodes.wrapperGrid.childNodes.length; i++) {
          if (
            this.nodes.wrapperGrid.childNodes[i].childNodes[6] ===
            (e.currentTarget as Node)
          ) {
            this.setDesc(
              (
                this.nodes.wrapperGrid.childNodes[i]
                  .childNodes[6] as HTMLDivElement
              ).innerHTML,
              i
            );
          }
        }
      });

      altElem.addEventListener('input', (e: Event) => {
        for (let i = 0; i < this.nodes.wrapperGrid.childNodes.length; i++) {
          if (
            this.nodes.wrapperGrid.childNodes[i].childNodes[4] ===
            (e.currentTarget as Node)
          ) {
            this.setAlt(
              (
                this.nodes.wrapperGrid.childNodes[i]
                  .childNodes[4] as HTMLInputElement
              ).value,
              i
            );
          }
        }
      });

      urlElem.addEventListener('input', (e: Event) => {
        for (let i = 0; i < this.nodes.wrapperGrid.childNodes.length; i++) {
          if (
            this.nodes.wrapperGrid.childNodes[i].childNodes[5] ===
            (e.currentTarget as Node)
          ) {
            this.setUrl(
              (
                this.nodes.wrapperGrid.childNodes[i]
                  .childNodes[5] as HTMLInputElement
              ).value,
              i
            );
          }
        }
      });

      container.appendChild(deleteButton);
      container.appendChild(titleElem);
      container.appendChild(imageContainer);
      container.appendChild(fileButton);
      container.appendChild(altElem);
      imageContainer.appendChild(imagePreloader);
      container.appendChild(urlElem);
      container.appendChild(descriptionElem);

      this.nodes.wrapperGrid.appendChild(container);

      this.addStatus();
    };

    this.nodes.selectNumCols.addEventListener('change', () => {
      if (this.nodes.selectNumCols.value === 'две') {
        this.nodes.wrapperGrid.classList.add('cards_no_numbers__items-twocol');
        this.nodes.selectNumCols.value = 'две';
      } else if (this.nodes.selectNumCols.value === 'три') {
        this.nodes.wrapperGrid.classList.remove(
          'cards_no_numbers__items-twocol'
        );
        this.nodes.selectNumCols.value = 'три';
      }
    });

    this.nodes.selectBlockColor.oninput = () => {
      this.nodes.wrapper.style.backgroundColor =
        this.setOutputColorValue() as string;
    };

    this.nodes.wrapper.appendChild(this.nodes.labelBlockColor);
    this.nodes.wrapper.appendChild(this.nodes.selectBlockColor);
    this.nodes.wrapper.appendChild(this.nodes.labelNumCols);
    this.nodes.wrapper.appendChild(this.nodes.selectNumCols);
    this.nodes.wrapper.appendChild(this.nodes.buttonAdd);
    this.nodes.wrapper.appendChild(this.nodes.wrapperGrid);
  }

  setOutputColorValue() {
    if (this.nodes.selectBlockColor.value === 'Белый') {
      return '#ffffff';
    } else if (this.nodes.selectBlockColor.value === 'Серый') {
      return '#f5f5f5';
    }
  }

  get CSS() {
    return {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      input: this.api.styles.input,
      button: this.api.styles.button,

      wrapper: 'image-tool',
      imageContainer: 'image-tool__image',
      imagePreloader: 'image-tool__image-preloader',
      imageEl: 'image-tool__image-picture',
      textInput: 'image-tool__input',
      inputWrapper: 'image-tool__input-wrapper',
      inputLabel: 'image-tool__input-label',

      wrapperGrid: [
        'cards_no_numbers__items',
        'cards_no_numbers__items-twocol'
      ],
      item: 'cards_no_numbers__item',
      buttonAdd: ['cards_no_numbers__button'],
      itemAlt: ['cards_no_numbers__item_alt'],
      itemTitle: 'cards_no_numbers__item_title',
      itemDescription: 'cards_no_numbers__item_description',
      itemUrl: 'cards_no_numbers__item_url',
      deleteButton: 'cards_no_numbers__item_delete',
      labelBlockColor: 'cards_no_numbers__label_block_color',
      selectBlockColor: 'cards_no_numbers__input_color',
      labelNumCols: 'cards_no_numbers__label_num_cols',
      selectNumCols: 'cards_no_numbers__input_num_cols'
    };
  }

  render(toolData: ICard[]) {
    if (toolData.length !== 0) {
      for (let i = 0; i < toolData.length; i++) {
        if (toolData[0].image && !toolData[0].image) {
          this.toggleStatus(this.status[i].EMPTY, i);
        } else {
          this.toggleStatus(this.status[i].UPLOADING, i);
        }
      }
    }
    return this.nodes.wrapper;
  }

  createFileButton(index: number) {
    const button: HTMLButtonElement = make('div', [this.CSS.button]);

    button.innerHTML = `Выберите изображение`;
    button.classList.add('image-tool__button');

    button.addEventListener('click', () => {
      this.onSelectFile(index);
      this.showPreloader('', index);
    });

    return button;
  }

  addStatus() {
    this.status.push({
      EMPTY: 'empty',
      UPLOADING: 'loading',
      FILLED: 'filled'
    });
  }

  get status(): IStatus[] {
    return this._status;
  }

  showPreloader(src: any, index: number) {
    (
      this.nodes.cards[index].childNodes[2].childNodes[0] as HTMLElement
    ).style.backgroundImage = `url(${src})`;

    this.toggleStatus(this.status[index].UPLOADING, index);
  }

  hidePreloader(index: number) {
    (
      this.nodes.cards[index].childNodes[2].childNodes[0] as HTMLElement
    ).style.backgroundImage = '';

    this.toggleStatus(this.status[index].EMPTY, index);
  }

  fillImage(url: any, index: number) {
    const attributes = {
      src: url
    };

    let imageEl = make('img', this.CSS.imageEl, attributes);

    this.nodes.cards[index].childNodes[2].appendChild(imageEl);

    if (!this.nodes.cards[index].childNodes[2]) {
      return;
    }

    this.nodes.cards[index].childNodes[2].childNodes[1].addEventListener(
      'load',
      () => {
        this.toggleStatus(this.status[index].FILLED, index);

        if (this.nodes.cards[index].childNodes[2].childNodes[0]) {
          (
            this.nodes.cards[index].childNodes[2].childNodes[0] as HTMLElement
          ).style.backgroundImage = '';
          (
            this.nodes.cards[index].childNodes[2].childNodes[0] as HTMLElement
          ).classList.remove('image-tool__image-preloader');
        }
      }
    );

    while (this.nodes.cards[index].childNodes[2].firstChild) {
      //error
      this.nodes.cards[index].childNodes[2].removeChild(
        this.nodes.cards[index].childNodes[2].firstChild as Node
      );
    }

    (this.nodes.cards[index].childNodes[2] as HTMLElement).classList.add(
      'image-tool--withBackground__image'
    );
    this.nodes.cards[index].childNodes[2].appendChild(imageEl);
  }

  toggleStatus(status: any, index: number) {
    for (const statusType in this.status[index]) {
      if (
        Object.prototype.hasOwnProperty.call(this.status[index], statusType)
      ) {
        this.nodes.cards[index].classList.toggle(
          `${this.CSS.wrapper}--${(this.status[index] as any)[statusType]}`,
          status === (this.status[index] as any)[statusType]
        );
      }
    }
  }
}
