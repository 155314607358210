import { API } from '@editorjs/editorjs';
import { omit } from 'lodash';
import { hasKey } from 'helpers/hasKey';
import { buttonIcon } from './icons/buttonIcon';
import { make } from '../utils/makeElement';

import './styles.css';

interface IInputValues {
  quote: string;
  name: string;
  company: string;
  position: string;
  alt: string;
}

interface IProps {
  api: API;
  onSelectFile: () => void;
  deleteImage: () => void;
  handleChange: (event: Event, field: string) => void;
}

const inputs = [
  { key: 'quote', placeholder: 'Текст цитаты' },
  { key: 'name', placeholder: 'Полное имя' },
  { key: 'company', placeholder: 'Компания' },
  { key: 'position', placeholder: 'Должность' },
  { key: 'alt', placeholder: 'Alt' }
];

export default class Ui {
  api: API;
  onSelectFile: () => void;
  nodes: {
    wrapper: HTMLDivElement;
    imageContainer: HTMLDivElement;
    imageItem: HTMLDivElement;
    fileButton: HTMLButtonElement;
    quote: HTMLDivElement;
    name: HTMLDivElement;
    company: HTMLDivElement;
    position: HTMLDivElement;
    alt: HTMLDivElement;
  };
  deleteImage: () => void;
  handleChange: (event: Event, field: string) => void;

  constructor({ api, onSelectFile, deleteImage, handleChange }: IProps) {
    this.api = api;
    this.onSelectFile = onSelectFile;
    this.deleteImage = deleteImage;
    this.handleChange = handleChange;

    this.nodes = {
      wrapper: make('div', [this.CSS.baseClass, this.CSS.wrapper]),
      imageContainer: make('div', [this.CSS.imageContainer]),
      imageItem: make('div', this.CSS.imageItem),
      fileButton: this.createFileButton(),

      quote: this.createInput(this.CSS.quote),
      name: this.createInput(),
      company: this.createInput(),
      position: this.createInput(),
      alt: this.createInput()
    };

    inputs.forEach(input => {
      if (hasKey(this.nodes, input.key)) {
        this.nodes[input.key].dataset.placeholder = input.placeholder;
        this.nodes[input.key].addEventListener('input', event =>
          this.handleChange(event, input.key)
        );
        if (input.key !== 'alt') {
          this.nodes.wrapper.append(this.nodes[input.key]);
        }
      }
    });

    this.nodes.wrapper.append(this.nodes.fileButton);
  }

  get CSS() {
    return {
      baseClass: this.api.styles.block,
      button: this.api.styles.button,

      wrapper: 'quote-tool',
      quote: 'quote-tool__quote',
      input: 'quote-tool__input',
      imageContainer: 'quote-tool__image-container',
      imageItem: 'quote-tool__image-item',
      deleteImageButton: 'quote-tool__delete-image-button'
    };
  }

  createInput(className?: string) {
    return make('div', className || this.CSS.input, { contentEditable: true });
  }

  createFileButton() {
    let button = make('div', [this.CSS.button]);
    button.innerHTML = `${buttonIcon} Загрузить изображение`;

    button.addEventListener('click', () => {
      this.onSelectFile();
    });

    return button;
  }

  createDeleteImageButton() {
    const button: HTMLDivElement = make('div', this.CSS.deleteImageButton);
    button.addEventListener('click', this.deleteImage);
    button.innerHTML = 'X';
    return button;
  }

  showPreview(image: string) {
    if (image.length) {
      this.nodes.imageItem.style.backgroundImage = `url(${image})`;

      this.nodes.wrapper.insertBefore(
        this.nodes.imageContainer,
        this.nodes.wrapper.children[this.nodes.wrapper.children.length - 1]
      );
      this.nodes.imageContainer.append(this.nodes.imageItem);
      this.nodes.imageContainer.append(this.nodes.alt);
      this.nodes.imageContainer.append(this.createDeleteImageButton());
    }
  }

  hidePreview() {
    this.nodes.imageContainer.remove();
  }

  setInputValues(values: IInputValues) {
    const keys = Object.keys(omit(values, 'image'));
    keys.forEach(key => {
      if (hasKey(this.nodes, key) && hasKey(values, key)) {
        this.nodes[key].innerHTML = values[key];
      }
    });
  }

  render() {
    return this.nodes.wrapper;
  }
}
