import { actions } from './actions';
import { Http } from 'services/HttpService';
import { takeLatest } from 'redux-saga/effects';
import { notify } from 'utils/notify';

export function* fetchCategory(
  action: ReturnType<typeof actions.fetchCategoryRequest>
) {
  try {
    yield Http.get<any>(`categories/${action.payload.id}`)
      .then(response => {
        window.store.dispatch(actions.fetchCategorySuccess(response.data));
      })
      .catch((error: { message: string }) => {
        notify.error(error);
        window.store.dispatch(actions.fetchCategoryError(error.message));
        window.store.dispatch(actions.clearCategoryData());
      });
  } catch (error) {
    console.error(error);
  }
}

export default function* categorySaga() {
  yield takeLatest('[CATEGORY] FETCH_CATEGORY_REQUEST', fetchCategory);
}
