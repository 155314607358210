import { Component } from 'react';
import { API } from '@editorjs/editorjs';
import { make } from 'plugins/utils/makeElement';
import { BLOCK_COLOR_NUM, NUM_OF_COLS } from '../consts';
import { BlockColors, NumOfCols } from '../enums';

import './index.css';

interface IItem {
  description: string;
  title: string;
  url: string | null;
}

interface IPropsData {
  content: IItem[];
  params: {
    color: {
      label: string;
      value: string;
    };
    isHidden: boolean;
    numCols: string;
  };
}

interface ICardsPluginProps {
  data: IPropsData;
  api: API;
}

class CardsPlugin extends Component<ICardsPluginProps> {
  api: API;

  nodes: {
    wrapper: HTMLDivElement;
    wrapperGrid: HTMLDivElement;
    item: [];
    buttonAdd: HTMLButtonElement;
    labelBlockColor: HTMLLabelElement;
    selectBlockColor: HTMLSelectElement;
    labelNumCols: HTMLLabelElement;
    selectNumCols: HTMLSelectElement;
  };

  _data: {
    content: IItem[];
    params: {
      color: {
        label: string;
        value: string;
      };
      isHidden: boolean;
      numCols: string;
    };
  };

  constructor(props: ICardsPluginProps) {
    super(props);
    this.api = this.props.api;

    this.nodes = {
      wrapper: make('div', this.CSS.wrapper),
      wrapperGrid: make('div', this.CSS.wrapperGrid),
      item: [],
      buttonAdd: make('button', this.CSS.buttonAdd),
      labelBlockColor: make('label', this.CSS.labelBlockColor),
      selectBlockColor: make('select', this.CSS.selectBlockColor),
      labelNumCols: make('label', this.CSS.labelNumCols),
      selectNumCols: make('select', this.CSS.selectNumCols)
    };

    this._data = {
      content: [],
      params: {
        color: {
          label: 'Белый',
          value: '#ffffff'
        },
        isHidden: false,
        numCols: ''
      }
    };

    this.initNodes();

    this.data = this.props.data;
  }

  initNodes() {
    this.initNodesInnerText();
    this.initSelectsOptions();
    this.initNodesAppend();
  }

  initNodesInnerText() {
    this.nodes.buttonAdd.innerText = '+';
    this.nodes.labelBlockColor.innerText = 'Цвет блока: ';
    this.nodes.labelNumCols.innerText = 'Количество колонок: ';
  }

  initSelectsOptions() {
    for (let i = 0; i < BLOCK_COLOR_NUM; i++) {
      let option = document.createElement('option');
      option.text = BlockColors[i];
      this.nodes.selectBlockColor.add(option);
    }

    for (let i = 0; i < NUM_OF_COLS; i++) {
      let option = document.createElement('option');
      option.text = NumOfCols[i];
      this.nodes.selectNumCols.add(option);
    }
  }

  initNodesAppend() {
    this.nodes.wrapper.appendChild(this.nodes.labelBlockColor);
    this.nodes.wrapper.appendChild(this.nodes.selectBlockColor);

    this.nodes.wrapper.appendChild(this.nodes.labelNumCols);
    this.nodes.wrapper.appendChild(this.nodes.selectNumCols);

    this.nodes.wrapper.appendChild(this.nodes.buttonAdd);

    this.nodes.wrapper.appendChild(this.nodes.wrapperGrid);
  }

  get CSS() {
    return {
      wrapper: 'wrapper',
      wrapperGrid: ['cards__items', 'cards__items-twocol'],
      item: 'cards__item',
      buttonAdd: ['cards__button'],
      itemTitle: 'cards__item_title',
      itemDescription: 'cards__item_description',
      itemUrl: 'cards__item_url',
      deleteButton: 'cards__item_delete',
      labelBlockColor: 'cards__label_block_color',
      selectBlockColor: 'cards__input_color',
      labelNumCols: 'cards__label_num_cols',
      selectNumCols: 'cards__input_num_cols'
    };
  }

  get data() {
    return this._data;
  }

  set data(data) {
    if (Object.keys(data).length !== 0) {
      this._data = data;
    }

    if (this.data.params !== undefined) {
      if (this.data.params.numCols === '2') {
        this.nodes.wrapperGrid.classList.add('cards__items-twocol');
        this.nodes.selectNumCols.value = 'две';
      } else if (this.data.params.numCols === '3') {
        this.nodes.wrapperGrid.classList.remove('cards__items-twocol');
        this.nodes.selectNumCols.value = 'три';
      }
    }

    this.setSelectsEventListeners();

    this.nodes.wrapper.style.backgroundColor =
      this.setOutputColorValue() as string;

    this.nodes.buttonAdd.onclick = () => {
      this.addCard();
    };

    for (let i in this.data.content) {
      let container = make('div', this.CSS.item);

      let { title, description, url } = this.data.content[i];
      let titleElem = make('input', this.CSS.itemTitle);
      let descriptionElem = make('textarea', this.CSS.itemDescription);
      let urlElem = make('input', this.CSS.itemUrl);

      let deleteButton = make('button', this.CSS.deleteButton);
      deleteButton.innerText = 'x';

      this.cardSetListeners(deleteButton, titleElem, descriptionElem, urlElem);

      titleElem.value = title !== undefined ? title : '';
      descriptionElem.value = description !== undefined ? description : '';
      urlElem.value = url !== undefined ? url : '';

      titleElem.placeholder = 'Заголовок';
      descriptionElem.placeholder = 'Описание';
      urlElem.placeholder = 'URL';

      container.appendChild(deleteButton);
      container.appendChild(titleElem);
      container.appendChild(descriptionElem);
      container.appendChild(urlElem);

      this.nodes.wrapperGrid.appendChild(container);
    }
  }

  setSelectsEventListeners() {
    this.nodes.selectBlockColor.oninput = () => {
      this.data.params.color.label = this.nodes.selectBlockColor.value;
      this.nodes.wrapper.style.backgroundColor =
        this.setOutputColorValue() as string;
    };

    this.nodes.selectNumCols.onchange = () => {
      if (this.nodes.selectNumCols.value === 'две') {
        this.nodes.wrapperGrid.classList.add('cards__items-twocol');
        this.nodes.selectNumCols.value = 'две';
      } else if (this.nodes.selectNumCols.value === 'три') {
        this.nodes.wrapperGrid.classList.remove('cards__items-twocol');
        this.nodes.selectNumCols.value = 'три';
      }
    };
  }

  addCard() {
    this.data.content.push({
      description: '',
      title: '',
      url: ''
    });

    let container = make('div', this.CSS.item);
    let deleteButton = make('button', this.CSS.deleteButton);
    let titleElem = make('input', this.CSS.itemTitle);
    let descriptionElem = make('textarea', this.CSS.itemDescription);
    let urlElem = make('input', this.CSS.itemUrl);

    deleteButton.innerText = 'x';
    titleElem.placeholder = 'Заголовок';
    descriptionElem.placeholder = 'Описание';
    urlElem.placeholder = 'URL';

    this.cardSetListeners(deleteButton, titleElem, descriptionElem, urlElem);

    container.appendChild(deleteButton);
    container.appendChild(titleElem);
    container.appendChild(descriptionElem);
    container.appendChild(urlElem);

    this.nodes.wrapperGrid.appendChild(container);
  }

  cardSetListeners(
    deleteButton: HTMLButtonElement,
    titleElem: HTMLInputElement,
    descriptionElem: HTMLTextAreaElement,
    urlElem: HTMLInputElement
  ) {
    deleteButton.onclick = (e: Event) => {
      for (let i = 0; i < this.data.content.length; i++) {
        if (
          this.nodes.wrapperGrid.childNodes[i].childNodes[0] === deleteButton
        ) {
          this.data.content.splice(i, 1);
        }
      }
      deleteButton.parentElement?.remove();
    };

    titleElem.oninput = (e: Event) => {
      for (let i = 0; i < this.nodes.wrapperGrid.childNodes.length; i++) {
        if (
          this.nodes.wrapperGrid.childNodes[i].childNodes[1] ===
          (e.currentTarget as Node)
        ) {
          this.data.content[i].title = (
            this.nodes.wrapperGrid.childNodes[i]
              .childNodes[1] as HTMLInputElement
          ).value;
        }
      }
    };

    descriptionElem.oninput = (e: Event) => {
      for (let i = 0; i < this.nodes.wrapperGrid.childNodes.length; i++) {
        if (
          this.nodes.wrapperGrid.childNodes[i].childNodes[2] ===
          (e.currentTarget as Node)
        ) {
          this.data.content[i].description = (
            this.nodes.wrapperGrid.childNodes[i]
              .childNodes[2] as HTMLInputElement
          ).value;
        }
      }
    };

    urlElem.oninput = (e: Event) => {
      for (let i = 0; i < this.nodes.wrapperGrid.childNodes.length; i++) {
        if (
          this.nodes.wrapperGrid.childNodes[i].childNodes[3] ===
          (e.currentTarget as Node)
        ) {
          this.data.content[i].url = (
            this.nodes.wrapperGrid.childNodes[i]
              .childNodes[3] as HTMLInputElement
          ).value;
        }
      }
    };
  }

  setOutputColorValue() {
    if (this.data.params.color.label === 'Белый') {
      return '#ffffff';
    } else if (this.data.params.color.label === 'Серый') {
      return '#f5f5f5';
    }
  }

  save() {
    return {
      content: this.data.content,
      params: {
        color: {
          label: this.nodes.selectBlockColor.value,
          value: this.setOutputColorValue()
        },
        isHidden: false, //warning
        numCols: this.nodes.selectNumCols.value
      }
    };
  }

  render() {
    return this.nodes.wrapper;
  }

  static get toolbox() {
    return {
      icon: '<b>CN</b>',
      title: 'Карточки'
    };
  }
}

export default CardsPlugin;
