import { takeLatest } from 'redux-saga/effects';
import { Http } from 'services/HttpService';
import { actions } from './actions';

import {
  setCurrentPage,
  setPageSize,
  setTotalPages
} from 'store/pagination/actions';
import { notify } from 'utils/notify';

export function* fetchProjects(
  action: ReturnType<typeof actions.fetchProjectsRequest>
) {
  try {
    yield Http.get<IWithPagination<IProjectItem[]>>(
      `projects?${action.payload.query}`
    )
      .then(response => {
        window.store.dispatch(actions.fetchProjectsSuccess(response.data.data));
        window.store.dispatch(setCurrentPage(response.data.current_page));
        window.store.dispatch(setPageSize(response.data.per_page));
        window.store.dispatch(setTotalPages(response.data.total));
      })
      .catch((error: { message: string }) => {
        notify.error(error);
        window.store.dispatch(actions.fetchProjectsError(error.message));
        window.store.dispatch(actions.clearProjectsData());
      });
  } catch (error) {
    console.error(error);
  }
}

export default function* projectsSaga() {
  yield takeLatest('[PROJECTS] FETCH_PROJECTS_REQUEST', fetchProjects);
}
