import React, { useEffect } from 'react';
import { Editor, Spinner } from 'components';
import { ServiceParams } from 'components/Forms';
import { useSelector } from 'react-redux';
import { transformBlocksFromBackend } from 'utils/transformBlocksFromBackend';
import { useDispatch } from 'react-redux';
import { actions } from 'store/service/actions';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { getInitialValues } from './utils/getInitialValues';

const EditServicePage: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  useEffect(() => {
    dispatch(actions.fetchServiceRequest(params.id));
  }, [dispatch, params.id]);

  const { data, loading, error } = useSelector(
    (store: IStore) => store.service
  );
  const { showEditor } = useSelector((store: IStore) => store.layout.services);

  const hasContent = Boolean(!error && !loading && data);

  return (
    <>
      {hasContent && <Content data={data} showEditor={showEditor} />}
      {loading && <Spinner size="large" />}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

const Content: React.FC<{ data: IService | null; showEditor: boolean }> = ({
  data,
  showEditor
}) => {
  if (!data) {
    return null;
  }

  const initialValues = getInitialValues(data);

  return (
    <Page>
      <ServiceParams initialValues={initialValues} isCreateService={false} />
      {showEditor && (
        <EditorWrapper>
          <Editor blocks={transformBlocksFromBackend(data.service.blocks)} />
        </EditorWrapper>
      )}
    </Page>
  );
};

const Page = styled.article`
  width: 100%;
`;

const EditorWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 50px;
  background-color: $white;
  box-shadow: 0 0 4px;
`;

const ErrorMessage = styled.div`
  font-size: 40px;
  text-align: center;
`;

export default EditServicePage;
