export const actions = {
  fetchRewardsRequest: () =>
    ({
      type: '[REWARDS] FETCH_REWARDS_REQUEST'
    } as const),

  fetchRewardsSuccess: (rewards: IRewardItem[]) =>
    ({
      type: '[REWARDS] FETCH_REWARDS_SUCCESS',
      payload: { rewards }
    } as const),

  fetchRewardsError: (message: string) =>
    ({
      type: '[REWARDS] FETCH_REWARDS_ERROR',
      payload: { message }
    } as const),

  clearRewardsData: () =>
    ({
      type: '[REWARDS] CLEAR_REWARDS_DATA'
    } as const)
};
