import React, { useEffect } from 'react';
import { Editor, Spinner } from 'components';
import { ArticleParams } from 'components/Forms';
import { useSelector } from 'react-redux';
import { transformBlocksFromBackend } from 'utils/transformBlocksFromBackend';
import { useDispatch } from 'react-redux';
import { actions } from 'store/article/actions';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { getInitialValues } from './utils/getInitialValues';

const EditArticlePage: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  useEffect(() => {
    dispatch(actions.fetchArticleRequest(params.id));
  }, [dispatch, params.id]);

  const { data, loading, error } = useSelector(
    (store: IStore) => store.article
  );

  const hasContent = Boolean(!error && !loading && data);

  return (
    <>
      {hasContent && <Content data={data} />}
      {loading && <Spinner size="large" />}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

const Content: React.FC<{ data: IArticle | null }> = ({ data }) => {
  if (!data) {
    return null;
  }

  const initialValues = getInitialValues(data);

  return (
    <Page>
      <ArticleParams initialValues={initialValues} isCreateArticle={false} />
      <EditorWrapper>
        <Editor blocks={transformBlocksFromBackend(data.article.blocks)} />
      </EditorWrapper>
    </Page>
  );
};

const Page = styled.article`
  width: 100%;
`;

const EditorWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 50px;
  background-color: $white;
  box-shadow: 0 0 4px;
`;

const ErrorMessage = styled.div`
  font-size: 40px;
  text-align: center;
`;

export default EditArticlePage;
