import React from 'react';
import { Form, Icon, Button } from 'antd';
import { SelectField } from 'components/Forms/Fields';
import styled from 'styled-components';
import usePresenter from './usePresenter';
import { WithPassedFormProps } from 'enhancers/withPassedFormProps';

interface ISelectableGroupFieldProps {
  name: string;
  rules: any[];
  buttonLabel: string;
  label: string;
  initialValues?: number[];
  disabled?: boolean;
  inputProps?: {
    placeholder: string;
  };
}

const SelectableGroupField: React.FC<
  ISelectableGroupFieldProps & IFormProps
> = ({
  name,
  rules,
  form,
  buttonLabel,
  label,
  inputProps,
  disabled,
  initialValues
}) => {
  const { add, remove, keys } = usePresenter(form, name);

  const formItems = keys.map((k: string, index: number) => (
    <Form.Item label={index === 0 ? label : ''} required={false} key={k}>
      <Row>
        <SelectField
          name={`${name}[${k}]`}
          source={`${name}/selectable`}
          placeholder={inputProps && inputProps.placeholder}
          initialValue={initialValues && initialValues[Number(k)]}
          form={form}
          mode="default"
          rules={rules}
          styles={{
            margin: '0',
            width: '95%'
          }}
        />
        <Icon type="minus-circle-o" onClick={() => remove(k)} />
      </Row>
    </Form.Item>
  ));

  return (
    <>
      {formItems}
      <Form.Item>
        <Button
          type="dashed"
          onClick={add}
          style={{ width: '15%' }}
          disabled={disabled}
        >
          <Icon type="plus" /> {buttonLabel}
        </Button>
      </Form.Item>
    </>
  );
};

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default WithPassedFormProps(SelectableGroupField);
