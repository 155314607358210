import { Http } from 'services/HttpService';
import { notify } from 'utils/notify';

export function submit(values: IVacancies) {
  const data = {
    title: values.title,
    blocks: values.blocks
  };

  Http.put('vacancies', { ...data })
    .then(response => {
      if (response.status === 200) {
        notify.success('Страница обновлена');
      }
    })
    .catch(notify.error);
}
