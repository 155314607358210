import { Http } from 'services/HttpService';
// import { helperRedirect } from 'helpers/redirect'
import { ITagParamsFormValues } from './usePresenter';
import { notify } from 'utils/notify';

interface ISubmitValues extends ITagParamsFormValues {
  slug: string;
  text: string;
  title: string;
  service: number;
  description: string;
}

export function submit(values: ISubmitValues, id: number | null) {
  const data = { ...values };
  if (id) {
    Http.patch(`tags/${id}`, { ...data })
      .then(response => {
        if (response.status === 200) {
          notify.success('Тег успешно изменен');
        }
      })
      .catch(notify.error);
  } else {
    Http.post('tags', { ...data })
      .then(response => {
        if (response.status === 200) {
          notify.success('Вы создали тег');
        }
      })
      .catch(notify.error);
  }
}
