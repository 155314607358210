import { Http } from 'services/HttpService';
// import { helperRedirect } from 'helpers/redirect'
import { ICategoryParamsFormValues } from './usePresenter';
import { notify } from 'utils/notify';

interface ISubmitValues {
  description: string | null;
  seo: {
    title: string;
    description: string | null;
  };
  slug: string;
  title: string;
  service: number[];
  type: string;
}

export function submit(values: ICategoryParamsFormValues, id: number | null) {
  const data = mapperFormToInitial(values);
  if (id) {
    Http.patch(`categories/${id}`, { ...data })
      .then(response => {
        if (response.status === 200) {
          notify.success('Категория успешно изменена');
        }
      })
      .catch(notify.error);
  } else {
    Http.post('categories', { ...data })
      .then(response => {
        if (response.status === 200) {
          notify.success('Вы создали категорию');
        }
      })
      .catch(notify.error);
  }
}

const mapperFormToInitial = (
  form: ICategoryParamsFormValues
): ISubmitValues => {
  return {
    description: form.description,
    seo: {
      title: form.seo_title,
      description: form.seo_description
    },
    service: form.service,
    slug: form.slug,
    title: form.title,
    type: form.type
  };
};
