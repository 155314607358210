import { Http } from 'services/HttpService';
import { helperRedirectReplace } from 'helpers/redirect';
import { notify } from 'utils/notify';

export const deleteArticle = (id: number) => {
  Http.delete(`articles/${id}`).then(response => {
    if (response.status === 200) {
      helperRedirectReplace('/articles');
      notify.success('Вы удалили статью');
    }
  });
};
