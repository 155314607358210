import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setEditorblocks } from 'store/editor/actions';
// import { clearArticleData } from 'store/article/actions'

const usePresenter = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setEditorblocks([]));
    // dispatch(clearArticleData())
    // eslint-disable-next-line
  }, []);
};

export default usePresenter;
