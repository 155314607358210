import React from 'react';

interface ITrProps {
  label: string;
  text: string;
  leftClass?: string;
  rightClass?: string;
}

const Tr: React.FC<ITrProps> = ({ label, text, leftClass, rightClass }) => (
  <tr>
    <td className={leftClass}>{label}:</td>
    <td className={rightClass}>{text}</td>
  </tr>
);

export default Tr;
