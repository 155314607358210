export const actions = {
  fetchServiceRequest: (id: string) =>
    ({
      type: '[SERVICE] FETCH_SERVICE_REQUEST',
      payload: id
    } as const),

  fetchServiceSuccess: (article: IService) =>
    ({
      type: '[SERVICE] FETCH_SERVICE_SUCCESS',
      payload: article
    } as const),

  fetchServiceError: (message: string) =>
    ({
      type: '[SERVICE] FETCH_SERVICE_ERROR',
      payload: message
    } as const),

  clearServiceData: () =>
    ({
      type: '[SERVICE] CLEAR_SERVICE_DATA'
    } as const)
};
