import { v4 as uuidv4 } from 'uuid';
import { IToolData } from '.';
import { make } from '../utils/makeElement';

export default class Ui {
  api: any;
  config: any;
  onSelectFile: any;
  nodes: {
    wrapper: HTMLDivElement;
    imageContainer: HTMLDivElement;
    fileButton: HTMLButtonElement;
    imageEl: HTMLImageElement | undefined;
    imagePreloader: HTMLDivElement;

    textInputWrapper: HTMLDivElement;
    textInputLabel: HTMLDivElement;
    textInput: HTMLDivElement;

    titleInputWrapper: HTMLDivElement;
    titleInputLabel: HTMLDivElement;
    titleInput: HTMLDivElement;

    altInputWrapper: HTMLDivElement;
    altInputLabel: HTMLDivElement;
    altInput: HTMLDivElement;
  };

  constructor({ api, config, onSelectFile }: any) {
    this.api = api;
    this.config = config;
    this.onSelectFile = onSelectFile;
    this.nodes = {
      wrapper: make('div', [this.CSS.baseClass, this.CSS.wrapper]),
      imageContainer: make('div', [this.CSS.imageContainer]),
      fileButton: this.createFileButton(),
      imageEl: undefined,
      imagePreloader: make('div', this.CSS.imagePreloader),

      /**
       * create alt nodes
       */
      altInputWrapper: make('div', [this.CSS.inputWrapper]),
      altInputLabel: make('div', [this.CSS.inputLabel]),
      altInput: make('div', [this.CSS.input, this.CSS.textInput], {
        contentEditable: true
      }),

      /**
       * create text nodes
       */
      textInputWrapper: make('div', [this.CSS.inputWrapper]),
      textInputLabel: make('div', [this.CSS.inputLabel]),
      textInput: make('div', [this.CSS.input, this.CSS.textInput], {
        id: uuidv4()
      }),

      /**
       * create title nodes
       */
      titleInputWrapper: make('div', [this.CSS.inputWrapper]),
      titleInputLabel: make('div', this.CSS.inputLabel),
      titleInput: make('div', [this.CSS.input, this.CSS.textInput], {
        contentEditable: true
      })
    };

    /**
     * add event listener for open tinymce modal
     */
    this.nodes.textInput.onclick = () => {
      const id = this.nodes.textInput.id;

      const customEvent = new CustomEvent('openTinymcePlugginModal', {
        detail: {
          id,
          value: this.nodes.textInput.innerHTML
        }
      });

      document.dispatchEvent(customEvent);
    };

    /**
     * create text for labels
     */
    this.nodes.altInputLabel.innerHTML = 'Alt:';
    this.nodes.titleInputLabel.innerHTML = 'Заголовок:';
    this.nodes.textInputLabel.innerHTML = 'Текст:';

    /**
     * append alt input
     */
    this.nodes.altInputWrapper.appendChild(this.nodes.altInputLabel);
    this.nodes.altInputWrapper.appendChild(this.nodes.altInput);

    /**
     * append title input
     */
    this.nodes.titleInputWrapper.appendChild(this.nodes.titleInputLabel);
    this.nodes.titleInputWrapper.appendChild(this.nodes.titleInput);

    /**
     * append text input
     */
    this.nodes.textInputWrapper.appendChild(this.nodes.textInputLabel);
    this.nodes.textInputWrapper.appendChild(this.nodes.textInput);

    /**
     * append image preloader
     */
    this.nodes.imageContainer.appendChild(this.nodes.imagePreloader);

    /**
     * append image container
     */
    this.nodes.wrapper.appendChild(this.nodes.imageContainer);

    /**
     * append wrappers
     */
    this.nodes.wrapper.appendChild(this.nodes.altInputWrapper);
    this.nodes.wrapper.appendChild(this.nodes.titleInputWrapper);
    this.nodes.wrapper.appendChild(this.nodes.textInputWrapper);

    /**
     * append file button
     */
    this.nodes.wrapper.appendChild(this.nodes.fileButton);
  }

  /**
   * CSS classes
   */
  get CSS() {
    return {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      input: this.api.styles.input,
      button: this.api.styles.button,

      /**
       * Tool's classes
       */
      wrapper: 'image-tool',
      imageContainer: 'image-tool__image',
      imagePreloader: 'image-tool__image-preloader',
      imageEl: 'image-tool__image-picture',
      textInput: 'image-tool__input',
      inputWrapper: 'image-tool__input-wrapper',
      inputLabel: 'image-tool__input-label'
    };
  }

  /**
   * Ui statuses:
   * - empty
   * - uploading
   * - filled
   */
  static get status() {
    return {
      EMPTY: 'empty',
      UPLOADING: 'loading',
      FILLED: 'filled'
    };
  }

  /**
   * Creates upload-file button
   */
  createFileButton() {
    const button: HTMLButtonElement = make('div', [this.CSS.button]);

    button.innerHTML = `Выберете изображение`;
    button.classList.add('image-tool__button');

    button.onclick = () => {
      this.onSelectFile();
      this.showPreloader('');
    };

    return button;
  }

  /**
   * Shows uploading preloader
   */
  showPreloader(src: any) {
    this.nodes.imagePreloader.style.backgroundImage = `url(${src})`;

    this.toggleStatus(Ui.status.UPLOADING);
  }

  /**
   * Hide uploading preloader
   */
  hidePreloader() {
    this.nodes.imagePreloader.style.backgroundImage = '';
    this.toggleStatus(Ui.status.EMPTY);
  }

  /**
   * Shows an image
   */
  fillImage(url: any) {
    const attributes = {
      src: url
    };

    /**
     * Compose tag with defined attributes
     */
    this.nodes.imageEl = make('img', this.CSS.imageEl, attributes);

    /**
     * Add load event listener
     */
    if (!this.nodes.imageEl) {
      return;
    }

    this.nodes.imageEl.onload = () => {
      this.toggleStatus(Ui.status.FILLED);

      /**
       * Preloader does not exists on first rendering with presaved data
       */
      if (this.nodes.imagePreloader) {
        this.nodes.imagePreloader.style.backgroundImage = '';
        this.nodes.imagePreloader.classList.remove(
          'image-tool__image-preloader'
        );
      }
    };

    while (this.nodes.imageContainer.firstChild) {
      this.nodes.imageContainer.removeChild(
        this.nodes.imageContainer.firstChild
      );
    }

    this.nodes.imageContainer.classList.add(
      'image-tool--withBackground__image'
    );
    this.nodes.imageContainer.appendChild(this.nodes.imageEl);
  }

  /**
   * Shows caption input
   */
  fillInputs(text: string, title: string, alt: string) {
    this.nodes.textInput.innerHTML = text || '';
    this.nodes.titleInput.innerHTML = title || '';
    this.nodes.altInput.innerHTML = alt || '';
  }

  /**
   * Changes UI status
   */
  toggleStatus(status: any) {
    for (const statusType in Ui.status) {
      if (Object.prototype.hasOwnProperty.call(Ui.status, statusType)) {
        this.nodes.wrapper.classList.toggle(
          `${this.CSS.wrapper}--${(Ui.status as any)[statusType]}`,
          status === (Ui.status as any)[statusType]
        );
      }
    }
  }

  /**
   * Apply visual representation of activated tune
   */
  applyTune(float: string) {
    this.nodes.wrapper.classList.toggle(float);
  }

  /**
   * Renders tool UI
   */
  render(toolData: IToolData) {
    if (toolData.image && !toolData.image.url) {
      this.toggleStatus(Ui.status.EMPTY);
    } else {
      this.toggleStatus(Ui.status.UPLOADING);
    }

    return this.nodes.wrapper;
  }
}
