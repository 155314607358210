import { notification } from 'antd';

const error = (error: any) => {
  const {
    response: { data }
  } = error;

  if (data.hasOwnProperty('errors')) {
    if (Array.isArray(data.errors)) {
      data.errors.forEach((error: any) => {
        notification.error({
          message: 'Ошибка!',
          description: error.message,
          duration: 4.5
        });
      });
    }
  }

  if (data.hasOwnProperty('message')) {
    notification.error({
      message: 'Ошибка!',
      description: data.message,
      duration: 4.5
    });
  }
};

const success = (message: string) => {
  notification.success({
    message: 'Успех!',
    description: message,
    duration: 4.5
  });
};

export const notify = {
  error,
  success
};
