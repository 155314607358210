export const actions = {
  fetchCategoryRequest: (id: string) =>
    ({
      type: '[CATEGORY] FETCH_CATEGORY_REQUEST',
      payload: { id }
    } as const),

  fetchCategorySuccess: (category: any) =>
    ({
      type: '[CATEGORY] FETCH_CATEGORY_SUCCESS',
      payload: { category }
    } as const),

  fetchCategoryError: (message: string) =>
    ({
      type: '[CATEGORY] FETCH_CATEGORY_ERROR',
      payload: { message }
    } as const),

  clearCategoryData: () =>
    ({
      type: '[CATEGORY] CLEAR_CATEGORY_DATA'
    } as const)
};
