import { actions } from './actions';
import { Http } from 'services/HttpService';
import { takeLatest } from 'redux-saga/effects';
import { setEditorblocks } from 'store/editor/actions';
import { transformBlocksFromBackend } from 'utils/transformBlocksFromBackend';
import { notify } from 'utils/notify';

export function* fetchProject(
  action: ReturnType<typeof actions.fetchProjectRequest>
) {
  try {
    yield Http.get<IProject>(`projects/${action.payload}`)
      .then(response => {
        window.store.dispatch(actions.fetchProjectSuccess(response.data));
        window.store.dispatch(
          setEditorblocks(
            transformBlocksFromBackend(response.data.project.blocks)
          )
        );
      })
      .catch((error: { message: string }) => {
        notify.error(error);
        window.store.dispatch(actions.fetchProjectError(error.message));
        window.store.dispatch(actions.clearProjectData());
        window.store.dispatch(setEditorblocks([]));
      });
  } catch (error) {
    console.error(error);
  }
}

export default function* projectSaga() {
  yield takeLatest('[PROJECT] FETCH_PROJECT_REQUEST', fetchProject);
}
