import { make } from '../utils/makeElement';
import { IToolData } from './index';

export default class Tunes {
  api: any;
  onChange: any;
  buttons: any;

  constructor({ api, onChange }: any) {
    this.api = api;
    this.onChange = onChange;
    this.buttons = [];
  }

  static get tunes() {
    return [
      {
        name: 'left' as 'left',
        icon: 'L',
        title: 'Left'
      },
      {
        name: 'right' as 'right',
        icon: 'R',
        title: 'Right'
      },
      {
        name: 'change' as 'change',
        icon: 'C',
        title: 'Change Image'
      }
    ];
  }

  get CSS() {
    return {
      wrapper: '',
      buttonBase: this.api.styles.settingsButton,
      button: 'image-tool__tune',
      buttonActive: this.api.styles.settingsButtonActive
    };
  }

  tuneClicked(tuneName: 'right' | 'left' | 'change') {
    this.onChange(tuneName);

    if (tuneName === 'change') {
      return;
    }

    let rigthButton: HTMLButtonElement = this.buttons.find(
      (el: HTMLButtonElement) => el.dataset.tune === 'right'
    );
    let leftButton: HTMLButtonElement = this.buttons.find(
      (el: HTMLButtonElement) => el.dataset.tune === 'left'
    );

    if (tuneName === 'right') {
      rigthButton.classList.add(this.CSS.buttonActive);
      leftButton.classList.remove(this.CSS.buttonActive);
    } else {
      rigthButton.classList.remove(this.CSS.buttonActive);
      leftButton.classList.add(this.CSS.buttonActive);
    }
  }

  render(toolData: IToolData) {
    let wrapper = make('div', this.CSS.wrapper);

    this.buttons = [];

    Tunes.tunes.forEach(tune => {
      let el = make('div', [this.CSS.buttonBase, this.CSS.button], {
        innerHTML: tune.icon,
        title: tune.title
      });

      el.addEventListener('click', () => {
        this.tuneClicked(tune.name);
      });

      el.dataset.tune = tune.name;
      el.classList.toggle(this.CSS.buttonActive, toolData.float === tune.name);

      this.buttons.push(el);

      wrapper.appendChild(el);
    });

    return wrapper;
  }
}
