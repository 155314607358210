import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

interface ISpinnerProps {
  size: 'large' | 'small' | 'default';
}

const Spinner: React.FC<ISpinnerProps> = props => (
  <Container>
    <Spin {...props} />
  </Container>
);

export default Spinner;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
