import moment from 'moment';

export function getInitialValues(data: IArticle) {
  const { article, isPublished } = data;

  const initialValues = {
    id: article.id,
    alt: article.alt,
    tags: article.tags,
    slug: article.slug,
    title: article.title,
    author: article.author,
    services: article.services,
    articles: article.articles,
    projects: article.projects,
    cover: article.images.cover,
    complexity: article.complexity,
    interested: article.interested,
    categories: article.categories,
    description: article.description,
    seoTitle: article.seo ? article.seo.title : '',
    seoDescription: article.seo ? article.seo.description : '',
    publishedAt: article.publishedAt ? moment(article.publishedAt) : moment(),
    isPublished
  };

  return initialValues;
}
