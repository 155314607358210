import { takeLatest } from 'redux-saga/effects';
import { Http } from 'services/HttpService';
import { actions } from './actions';
import { notify } from 'utils/notify';

export function* fetchReward(
  action: ReturnType<typeof actions.fetchRewardRequest>
) {
  try {
    yield Http.get<{ data: IRewardItem }>(`rewards/${action.payload.id}`)
      .then(response => {
        window.store.dispatch(actions.fetchRewardSuccess(response.data.data));
      })
      .catch((error: { message: string }) => {
        notify.error(error);
        window.store.dispatch(actions.fetchRewardError(error.message));
        window.store.dispatch(actions.clearRewardData());
      });
  } catch (error) {
    console.error(error);
  }
}

export default function* rewardSaga() {
  yield takeLatest('[REWARD] FETCH_REWARD_REQUEST', fetchReward);
}
