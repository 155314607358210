import { Http } from 'services/HttpService';
import { helperRedirectReplace } from 'helpers/redirect';
import { notify } from 'utils/notify';

export const deleteService = (id: number) => {
  Http.delete(`services/${id}`).then(response => {
    if (response.status === 200) {
      helperRedirectReplace('/services');
      notify.success('Вы удалили услугу');
    }
  });
};
