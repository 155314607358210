import { takeLatest } from 'redux-saga/effects';
import { Http } from 'services/HttpService';
import { actions } from './actions';

import {
  setCurrentPage,
  setPageSize,
  setTotalPages
} from 'store/pagination/actions';
import { notify } from 'utils/notify';

export function* fetchServices(
  action: ReturnType<typeof actions.fetchServicesRequest>
) {
  try {
    yield Http.get<IWithPagination<Array<IServiceItem>>>(
      `services?${action.payload.query}`
    )
      .then(response => {
        window.store.dispatch(actions.fetchServicesSuccess(response.data.data));
        window.store.dispatch(setCurrentPage(response.data.current_page));
        window.store.dispatch(setPageSize(response.data.per_page));
        window.store.dispatch(setTotalPages(response.data.total));
      })
      .catch((error: { message: string }) => {
        notify.error(error);
        window.store.dispatch(actions.fetchServicesError(error.message));
        window.store.dispatch(actions.clearServicesData());
      });
  } catch (error) {
    console.error(error);
  }
}

export default function* servicesSaga() {
  yield takeLatest('[SERVICES] FETCH_SERVICES_REQUEST', fetchServices);
}
