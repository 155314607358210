import { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { API } from '@editorjs/editorjs';
import { make } from 'plugins/utils/makeElement';
import { BLOCK_COLOR_NUM } from '../consts';
import { BlockColors } from '../enums';

import './index.css';

interface IItem {
  content: string;
  description: string;
  params: {
    color: {
      label: string;
      value: string;
    };
    isHidden: boolean;
  };
  title: string;
  type: string;
}

interface IPropsData {
  content: IItem[];
  params: {
    color: {
      label: string;
      value: string;
    };
    isHidden: boolean;
  };
}

interface IExpanderPluginProps {
  data: IPropsData;
  api: API;
}

class ExpanderPlugin extends Component<IExpanderPluginProps> {
  api: API;

  nodes: {
    wrapper: HTMLDivElement;
    wrapperFlex: HTMLDivElement;
    item: [];
    buttonAdd: HTMLButtonElement;
    labelColor: HTMLLabelElement;
    selectColor: HTMLSelectElement;
  };

  _data: {
    content: IItem[];
    params: {
      color: {
        label: string;
        value: string;
      };
      isHidden: boolean;
    };
  };

  constructor(props: IExpanderPluginProps) {
    super(props);
    this.api = this.props.api;

    this.nodes = {
      wrapper: make('div', this.CSS.wrapper),
      wrapperFlex: make('div', this.CSS.wrapperFlex),
      item: [],
      buttonAdd: make('button', this.CSS.buttonAdd),
      labelColor: make('label', this.CSS.labelColor),
      selectColor: make('select', this.CSS.selectColor)
    };

    this._data = {
      content: [],
      params: {
        color: {
          label: 'Белый',
          value: '#ffffff'
        },
        isHidden: false
      }
    };

    this.initNodes();

    this.data = this.props.data;
  }

  initNodes() {
    this.nodes.buttonAdd.innerText = '+';
    this.nodes.labelColor.innerText = 'Цвет блока: ';

    for (let i = 0; i < BLOCK_COLOR_NUM; i++) {
      let option = document.createElement('option');
      option.text = BlockColors[i];
      this.nodes.selectColor.add(option);
    }

    this.nodes.wrapper.appendChild(this.nodes.labelColor);
    this.nodes.wrapper.appendChild(this.nodes.selectColor);
    this.nodes.wrapper.appendChild(this.nodes.buttonAdd);
    this.nodes.wrapper.appendChild(this.nodes.wrapperFlex);
  }

  get data() {
    return this._data;
  }

  set data(data) {
    if (Object.keys(data).length !== 0) {
      this._data = data;
    }

    this.setSelectEventListener();

    this.nodes.wrapper.style.backgroundColor =
      this.setColorBlockValue() as string;

    this.nodes.buttonAdd.onclick = () => {
      this.addBlock();
    };

    for (let i in this.data.content) {
      let { content, title, description, params } = this.data.content[i];
      let colorLabel = params.color.label;

      let container = make('div', this.CSS.item);
      let contentElem = make('div', this.CSS.itemContent, {
        id: uuidv4()
      });
      let titleElem = make('input', this.CSS.itemTitle);
      let descriptionElem = make('textarea', this.CSS.itemDescription);
      let labelBlockColor = make('label', this.CSS.labelBlockColor);
      let selectBlockColor = make('select', this.CSS.selectBlockColor);
      let deleteButton = make('button', this.CSS.deleteButton);

      for (let i = 0; i < BLOCK_COLOR_NUM; i++) {
        let option = document.createElement('option');
        option.text = BlockColors[i];
        selectBlockColor.add(option);
      }

      this.blockSetListeners(
        deleteButton,
        contentElem,
        titleElem,
        descriptionElem,
        selectBlockColor
      );

      contentElem.innerHTML = content !== undefined ? content : '';
      titleElem.value = title !== undefined ? title : '';
      descriptionElem.value = description !== undefined ? description : '';
      selectBlockColor.value = colorLabel !== undefined ? colorLabel : 'Белый';

      deleteButton.innerText = 'x';
      labelBlockColor.innerText = 'Цвет блока:';
      titleElem.placeholder = 'Заголовок';
      descriptionElem.placeholder = 'Описание';

      container.appendChild(deleteButton);
      container.appendChild(contentElem);
      container.appendChild(titleElem);
      container.appendChild(descriptionElem);
      container.appendChild(labelBlockColor);
      container.appendChild(selectBlockColor);

      this.nodes.wrapperFlex.appendChild(container);
    }
  }

  setSelectEventListener() {
    this.nodes.selectColor.oninput = () => {
      this.data.params.color.label = this.nodes.selectColor.value;
      this.data.params.color.value = this.setColorBlockValue() as string;
    };
  }

  addBlock() {
    this.data.content.push({
      content: '',
      description: '',
      params: {
        color: {
          label: 'Белый',
          value: '#ffffff'
        },
        isHidden: false
      },
      title: '',
      type: 'paragraph-block'
    });

    let container = make('div', this.CSS.item);
    let contentElem = make('div', this.CSS.itemContent, {
      id: uuidv4()
    });
    let titleElem = make('input', this.CSS.itemTitle);
    let descriptionElem = make('textarea', this.CSS.itemDescription);
    let labelBlockColor = make('label', this.CSS.labelBlockColor);
    let selectBlockColor = make('select', this.CSS.selectBlockColor);
    let deleteButton = make('button', this.CSS.deleteButton);

    for (let i = 0; i < BLOCK_COLOR_NUM; i++) {
      let option = document.createElement('option');
      option.text = BlockColors[i];
      selectBlockColor.add(option);
    }

    deleteButton.innerText = 'x';
    titleElem.placeholder = 'Заголовок';
    descriptionElem.placeholder = 'Описание';
    labelBlockColor.innerText = 'Цвет блока:';

    this.blockSetListeners(
      deleteButton,
      contentElem,
      titleElem,
      descriptionElem,
      selectBlockColor
    );

    container.appendChild(deleteButton);
    container.appendChild(contentElem);
    container.appendChild(titleElem);
    container.appendChild(descriptionElem);
    container.appendChild(labelBlockColor);
    container.appendChild(selectBlockColor);

    this.nodes.wrapperFlex.appendChild(container);
  }

  blockSetListeners(
    deleteButton: HTMLButtonElement,
    contentElem: HTMLDivElement,
    titleElem: HTMLInputElement,
    descriptionElem: HTMLTextAreaElement,
    selectBlockColor: HTMLSelectElement
  ) {
    contentElem.addEventListener('click', () => {
      const id = contentElem.id;
      const customEvent = new CustomEvent('openTinymcePlugginModal', {
        detail: {
          id,
          value: contentElem.innerHTML
        }
      });
      document.dispatchEvent(customEvent);
    });

    deleteButton.onclick = (e: Event) => {
      for (let i = 0; i < this.data.content.length; i++) {
        if (
          this.nodes.wrapperFlex.childNodes[i].childNodes[0] === deleteButton
        ) {
          this.data.content.splice(i, 1);
        }
      }
      deleteButton.parentElement?.remove();
    };

    contentElem.addEventListener('DOMSubtreeModified', (e: Event) => {
      for (let i = 0; i < this.nodes.wrapperFlex.childNodes.length; i++) {
        if (
          this.nodes.wrapperFlex.childNodes[i].childNodes[1] ===
          (e.currentTarget as Node)
        ) {
          this.data.content[i].content = (
            this.nodes.wrapperFlex.childNodes[i].childNodes[1] as HTMLDivElement
          ).innerHTML;
        }
      }
    });

    titleElem.oninput = (e: Event) => {
      for (let i = 0; i < this.nodes.wrapperFlex.childNodes.length; i++) {
        if (
          this.nodes.wrapperFlex.childNodes[i].childNodes[2] ===
          (e.currentTarget as Node)
        ) {
          this.data.content[i].title = (
            this.nodes.wrapperFlex.childNodes[i]
              .childNodes[2] as HTMLInputElement
          ).value;
        }
      }
    };

    descriptionElem.oninput = (e: Event) => {
      for (let i = 0; i < this.nodes.wrapperFlex.childNodes.length; i++) {
        if (
          this.nodes.wrapperFlex.childNodes[i].childNodes[3] ===
          (e.currentTarget as Node)
        ) {
          this.data.content[i].description = (
            this.nodes.wrapperFlex.childNodes[i]
              .childNodes[3] as HTMLInputElement
          ).value;
        }
      }
    };

    selectBlockColor.oninput = (e: Event) => {
      for (let i = 0; i < this.nodes.wrapperFlex.childNodes.length; i++) {
        if (
          this.nodes.wrapperFlex.childNodes[i].childNodes[5] ===
          (e.currentTarget as Node)
        ) {
          this.data.content[i].params.color.label = (
            this.nodes.wrapperFlex.childNodes[i]
              .childNodes[5] as HTMLSelectElement
          ).value;
          this.data.content[i].params.color.value =
            this.setColorTextValue(i) || '';
        }
      }
    };
  }

  setColorTextValue(i: number) {
    if (this.data.content[i].params.color.label === 'Белый') {
      return '#ffffff';
    } else if (this.data.content[i].params.color.label === 'Серый') {
      return '#f5f5f5';
    }
  }

  setColorBlockValue() {
    if (this.data.params.color.label === 'Белый') {
      return '#ffffff';
    } else if (this.data.params.color.label === 'Серый') {
      return '#f5f5f5';
    }
  }

  save() {
    return {
      content: this.data.content,
      params: this.data.params
    };
  }

  render() {
    return this.nodes.wrapper;
  }

  static get toolbox() {
    return {
      icon: '<b>Exp</b>',
      title: 'Раскрывающийся блок'
    };
  }

  get CSS() {
    return {
      wrapper: 'expander',
      wrapperFlex: 'expander__wrapper-flex',
      buttonAdd: 'expander__button-add',
      labelColor: 'expander__label-color',
      selectColor: 'expander__select-color',

      item: 'expander-block',
      itemContent: 'expander-block__content',
      itemTitle: 'expander-block__title',
      itemDescription: 'expander-block__description',
      deleteButton: 'expander-block__delete-button',
      labelBlockColor: 'expander-block__label-color',
      selectBlockColor: 'expander-block__select-color'
    };
  }
}

export default ExpanderPlugin;
