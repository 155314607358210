import React from 'react';
import { Route } from 'react-router';
import Category from './category';

const categories = ['article', 'industry', 'project'];

const CategoriesPage: React.FC = () => {
  return (
    <React.Fragment>
      {categories.map((item, index) => (
        <Route path={`/${item}`} component={Category} key={index} />
      ))}
    </React.Fragment>
  );
};

export default CategoriesPage;
