import React from 'react';
import { Form, Button, Row, Col } from 'antd';
import { FieldsProvider } from 'components';
import styled from 'styled-components';
import { UploadField, TextAreaField, TinyMceField } from '../Fields';
import usePresenter, { IAuthorParamsProps } from './usePresenter';

const AuthorParams: React.FC<IAuthorParamsProps> = ({
  form,
  initialValues
}) => {
  const { handleSubmit } = usePresenter({ initialValues, form });

  return (
    <Form onSubmit={handleSubmit}>
      <FieldsProvider form={form}>
        <Row type="flex" justify="space-between">
          <Col span={8}>
            <TextAreaField
              form={form}
              name="name"
              label="Имя"
              placeholder="Введите имя автора"
              rules={[{ required: true, message: 'Введите имя автора' }]}
            />
          </Col>

          <Col span={14}>
            <TextAreaField
              form={form}
              name="post"
              label="Должность автора"
              placeholder="Введите должность"
              rules={[{ required: true, message: 'Введите должность' }]}
            />
          </Col>
        </Row>

        <TinyMceField
          name="description"
          label="Oписание"
          placeholder="Введите описание"
          height={300}
          rules={[{ required: true, message: 'Введите описание' }]}
        />

        <UploadField
          name="avatar"
          initialValue={initialValues && initialValues.avatar}
          label="Аватар"
        />
      </FieldsProvider>

      <FormSubmitButton>
        <Button type="primary" htmlType="submit" size="large">
          Опубликовать
        </Button>
      </FormSubmitButton>
    </Form>
  );
};

const FormSubmitButton = styled(Form.Item)`
  display: flex;
  position: absolute;
  top: 12px;
  right: 150px;
`;

export default Form.create<IAuthorParamsProps>({ name: 'AuthorParams' })(
  AuthorParams
);
