import React from 'react';
import styled from 'styled-components';
import { Button, Popconfirm } from 'antd';

interface IProps {
  onDeleteArticle: () => void;
  handlePublish: () => void;
  publishStatus: number;
  isCreate: boolean;
}

const ControlButtons: React.FC<IProps> = ({
  publishStatus,
  handlePublish,
  onDeleteArticle,
  isCreate
}) => {
  return (
    <Div>
      {isCreate ? (
        <Button type="primary" htmlType="submit">
          Создать
        </Button>
      ) : (
        <React.Fragment>
          <Button type="primary" htmlType="submit">
            Сохранить изменения
          </Button>
          <Button type="default" onClick={handlePublish}>
            {publishStatus ? 'Опубликовать' : 'Скрыть'}
          </Button>
          <Popconfirm
            title="Уверены что хотите удалить статью?"
            okText="Удалить"
            cancelText="Отменить"
            placement="left"
            onConfirm={onDeleteArticle}
          >
            <a href="/#">
              <Button type="danger">Удалить</Button>
            </a>
          </Popconfirm>
        </React.Fragment>
      )}
    </Div>
  );
};

const Div = styled.div`
  position: absolute;
  top: 16px;
  right: 150px;

  > * {
    margin-right: 10px;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export default ControlButtons;
