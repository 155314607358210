import { Component } from 'react';
import { API } from '@editorjs/editorjs';
import { make } from 'plugins/utils/makeElement';

import './index.css';

interface IPropsData {
  content: string;
}

interface IFactoidPluginProps {
  data: IPropsData;
  api: API;
}

class FactoidPlugin extends Component<IFactoidPluginProps> {
  api: API;
  _CSS: {
    input: string;
  };
  _element: HTMLElement;
  _data: {
    content: string;
  };

  constructor(props: IFactoidPluginProps) {
    super(props);
    this.api = this.props.api;

    this._CSS = {
      input: 'factoid-tool'
    };
    this.onKeyUp = this.onKeyUp.bind(this);

    this._data = {
      content: ''
    };
    this._element = this.drawView();
    this.data = this.props.data;
  }

  onKeyUp(e: any) {
    if (e.code !== 'Backspace' && e.code !== 'Delete') {
      return;
    }

    const { textContent } = this._element;

    if (textContent === '') {
      this._element.innerHTML = '';
    }
  }

  drawView() {
    let div = make('div', this._CSS.input);

    div.contentEditable = 'true';

    div.addEventListener('keyup', this.onKeyUp);

    return div;
  }

  merge(data: IPropsData) {
    let newData = {
      content: this.data.content + data.content
    };

    this.data = newData;
  }

  validate(savedData: IPropsData) {
    if (savedData.content.trim() === '') {
      return false;
    }

    return true;
  }

  get data() {
    let content = this._element.innerHTML;

    this._data.content = content;

    return this._data;
  }

  set data(data) {
    this._data = data || {};

    this._element.innerHTML = this._data.content || '';
  }

  save(toolsContent: HTMLElement) {
    return {
      content: toolsContent.innerHTML
    };
  }

  render() {
    return this._element;
  }

  static get conversionConfig() {
    return {
      export: 'text',
      import: 'text'
    };
  }

  static get sanitize() {
    return {
      content: {
        br: true
      }
    };
  }

  static get toolbox() {
    return {
      icon: '<b>F</b>',
      title: 'Врезка'
    };
  }

  static get enableLineBreaks() {
    return true;
  }

  static get contentless() {
    return true;
  }
}

export default FactoidPlugin;
