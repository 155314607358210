import { Component } from 'react';
import { API } from '@editorjs/editorjs';
import { BLOCK_COLOR_NUM } from '../consts';
import { BlockColors } from '../enums';
import { make } from 'plugins/utils/makeElement';
import './index.css';

interface IItem {
  label: string;
  value: number;
}

interface IPropsData {
  content: IItem[];
  params: {
    color: {
      label: string;
      value: string;
    };
    isHidden: boolean;
  };
}

interface IServicesPluginProps {
  data: IPropsData;
  api: API;
}

class ServicesPlugin extends Component<IServicesPluginProps> {
  api: API;

  response: any[];

  nodes: {
    wrapper: HTMLDivElement;
    selectServices: HTMLSelectElement;
    labelBlockColor: HTMLLabelElement;
    selectBlockColor: HTMLSelectElement;
  };

  _data: {
    content: IItem[];
    params: {
      color: {
        label: string;
        value: string;
      };
      isHidden: boolean;
    };
  };

  constructor(props: IServicesPluginProps) {
    super(props);
    this.api = this.props.api;

    this.nodes = {
      wrapper: make('div', this.CSS.wrapper),
      selectServices: make('select', this.CSS.selectServices),
      labelBlockColor: make('label', this.CSS.labelBlockColor),
      selectBlockColor: make('select', this.CSS.selectBlockColor)
    };

    this.response = [];

    this.nodes.selectServices.multiple = true;

    this.nodes.labelBlockColor.innerText = 'Цвет блока: ';

    this._data = {
      content: [],
      params: {
        color: {
          label: 'Белый',
          value: '#ffffff'
        },
        isHidden: false
      }
    };

    this.initNodes();

    this.data = this.props.data;
  }

  initNodes() {
    this.initSelectsOptions();

    this.initNodesAppend();
  }

  initNodesAppend() {
    this.nodes.selectBlockColor.oninput = () => {
      this.nodes.wrapper.style.backgroundColor =
        this.setOutputColorValue() as string;
    };

    this.nodes.wrapper.appendChild(this.nodes.labelBlockColor);
    this.nodes.wrapper.appendChild(this.nodes.selectBlockColor);
    this.nodes.wrapper.appendChild(this.nodes.selectServices);
  }

  initSelectsOptions() {
    for (let i = 0; i < BLOCK_COLOR_NUM; i++) {
      let option = document.createElement('option');
      option.text = BlockColors[i];
      this.nodes.selectBlockColor.add(option);
    }
  }

  get CSS() {
    return {
      wrapper: 'wrapper',
      selectServices: 'select_services',
      labelBlockColor: 'services__label-block-color',
      selectBlockColor: 'services__input-color'
    };
  }

  get data() {
    return this._data;
  }

  set data(data) {
    if (Object.keys(data).length !== 0) {
      this._data = data;
    }

    this.nodes.wrapper.style.backgroundColor =
      this.setOutputColorValue() as string;

    this.getDataFromBackend().then(response => {
      for (let section in response) {
        for (let article in response[section].content) {
          let option = make('option');
          option.text = response[section]['content'][article]['title'];

          this.response.push({
            id: response[section]['content'][article]['id'],
            title: response[section]['content'][article]['title']
          });
          for (let i in data.content) {
            if (data.content[i].label === option.text) {
              option.selected = true;
              break;
            }

            // с бекенда приходит значение - UI/UX-дизайн, а из метода API - Веб-дизайн, должно приходить одно и тоже значение
            if (
              data.content[i].label === 'Веб-дизайн' &&
              option.text === 'UI/UX-дизайн'
            ) {
              option.selected = true;
              break;
            }
            // с бекенда приходит значение - UI/UX-дизайн, а из метода API - Веб-дизайн, должно приходить одно и тоже значение
            if (
              data.content[i].label ===
                'Продвижение в социальных сетях (SMM)' &&
              option.text === 'Комплексное продвижение в социальных сетях'
            ) {
              option.selected = true;
              break;
            }
          }
          this.nodes.selectServices.add(option);
        }
      }
    });
  }

  save() {
    let outputContent = [];
    for (let option in this.nodes.selectServices.options) {
      if (this.nodes.selectServices.options[option].selected === true) {
        for (let item in this.response) {
          if (
            this.response[item].title ===
            this.nodes.selectServices.options[option].innerText
          ) {
            outputContent.push({
              label: this.response[item].title,
              value: this.response[item].id
            });
          }
          if (
            this.response[item].title === 'Дизайн мобильных  приложений' &&
            this.nodes.selectServices.options[option].innerText ===
              'Дизайн мобильных приложений'
          ) {
            outputContent.push({
              label: this.response[item].title,
              value: this.response[item].id
            });
          }
        }
      }
    }

    return {
      content: outputContent,
      params: {
        color: {
          label: this.nodes.selectBlockColor.value,
          value: this.setOutputColorValue()
        },
        isHidden: false //warning
      }
    };
  }

  setOutputColorValue() {
    if (this.nodes.selectBlockColor.value === 'Белый') {
      return '#ffffff';
    } else if (this.nodes.selectBlockColor.value === 'Серый') {
      return '#f5f5f5';
    }
  }

  async getDataFromBackend() {
    let data = await fetch('https://atwinta3.dev.atwinta.ru/api/v1/services');
    let dataJSON = data.json();
    return await dataJSON;
  }

  render() {
    return this.nodes.wrapper;
  }

  static get toolbox() {
    return {
      icon: '<b>Serv</b>',
      title: 'Сервисы'
    };
  }
}

export default ServicesPlugin;
