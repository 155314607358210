import React from 'react';
import { helperRedirect } from 'helpers/redirect';
import styled from 'styled-components';

type RouteType = {
  path: string;
  breadcrumbName: string;
};

const BreadcrumbItem = (
  route: RouteType,
  params: any,
  routes: Array<RouteType>,
  paths: Array<string>
) => {
  const last = routes.indexOf(route) === routes.length - 1;
  const disabled = Boolean(
    last ||
      route.path === '/articles/edit' ||
      route.path === '/services/edit' ||
      route.path === '/rewards/edit' ||
      route.path === '/tags/edit' ||
      route.path === '/projects/edit' ||
      route.path === '/authors/edit'
  );

  return disabled ? (
    <LastCrumb>{route.breadcrumbName}</LastCrumb>
  ) : (
    <StyledButton onClick={() => helperRedirect(route.path)}>
      {route.breadcrumbName}
    </StyledButton>
  );
};

const LastCrumb = styled.span`
  cursor: default;
  color: rgba(0, 0, 0, 0.65);
`;

const StyledButton = styled.button`
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  background: transparent;
  outline: none;
  &:hover {
    color: #1890ff;
  }
`;

export default BreadcrumbItem;
