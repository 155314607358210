import { Component } from 'react';
import { API } from '@editorjs/editorjs';
import { make } from '../utils/makeElement';

import './index.css';

interface IPropsData {
  content: string;
}

interface IAnchorPluginProps {
  data: IPropsData;
  api: API;
}

class AnchorPlugin extends Component<IAnchorPluginProps> {
  api: API;
  nodes: {
    wrapper: HTMLDivElement;
    anchor: HTMLInputElement;
  };

  _data: {
    content: string;
  };

  constructor(props: IAnchorPluginProps) {
    super(props);
    this.api = this.props.api;

    this.nodes = {
      wrapper: make('div', this.CSS.wrapper),
      anchor: make('div', this.CSS.input, { contentEditable: true })
    };

    this.nodes.anchor.dataset.placeholder = 'Якорь';
    this.nodes.anchor.classList.add('anchor');

    this.nodes.wrapper.appendChild(this.nodes.anchor);

    this._data = {
      content: ''
    };
    this.data = this.props.data;
  }

  get CSS() {
    return {
      wrapper: 'anchor-tool',
      input: 'anchor-tool__input'
    };
  }

  get data() {
    return this._data;
  }

  set data(data) {
    this._data = data || {};

    this.nodes.anchor.innerText = this._data.content ? this._data.content : '';
  }

  save() {
    const anchor = this.nodes.anchor.innerText;

    return Object.assign(this.data, {
      content: anchor
    });
  }

  render() {
    return this.nodes.wrapper;
  }

  static get toolbox() {
    return {
      icon: '<b>A</b>',
      title: 'Якорь'
    };
  }

  static get contentless() {
    return true;
  }

  static get enableLineBreaks() {
    return true;
  }
}

export default AnchorPlugin;
