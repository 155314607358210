export const actions = {
  fetchAuthorRequest: (id: string) =>
    ({
      type: '[AUTHOR] FETCH_AUTHOR_REQUEST',
      payload: { id }
    } as const),

  fetchAuthorSuccess: (author: IAuthor) =>
    ({
      type: '[AUTHOR] FETCH_AUTHOR_SUCCESS',
      payload: { author }
    } as const),

  fetchAuthorError: (message: string) =>
    ({
      type: '[AUTHOR] FETCH_AUTHOR_ERROR',
      payload: { message }
    } as const),

  clearAuthorData: () =>
    ({
      type: '[AUTHOR] CLEAR_AUTHOR_DATA'
    } as const)
};
