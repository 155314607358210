import { Component } from 'react';
import { API } from '@editorjs/editorjs';
import { make } from '../utils/makeElement';

import './index.css';

interface IPropsData {
  content: {
    url: string;
    description: string;
  };
}

interface IVideoPluginProps {
  data: IPropsData;
  api: API;
}

class VideoPlugin extends Component<IVideoPluginProps> {
  static get toolbox() {
    return {
      icon: '<b>V</b>',
      title: 'Видео'
    };
  }

  static get contentless() {
    return true;
  }

  static get enableLineBreaks() {
    return true;
  }

  api: API;
  nodes: {
    wrapper: HTMLDivElement;
    link: HTMLInputElement;
    description: HTMLInputElement;
  };

  _data: {
    content: {
      url: string;
      description: string;
    };
  };

  constructor(props: IVideoPluginProps) {
    super(props);
    this.api = this.props.api;

    this.nodes = {
      wrapper: make('div', this.CSS.wrapper),
      link: make('div', this.CSS.input, { contentEditable: true }),
      description: make('div', this.CSS.input, { contentEditable: true })
    };

    this.nodes.link.dataset.placeholder = 'Ссылка на видео';
    this.nodes.description.dataset.placeholder = 'Описание';
    this.nodes.link.classList.add('link');
    this.nodes.description.classList.add('description');

    this.nodes.wrapper.appendChild(this.nodes.link);
    this.nodes.wrapper.appendChild(this.nodes.description);

    this._data = {
      content: {
        url: '',
        description: ''
      }
    };
    this.data = this.props.data;
  }

  get CSS() {
    return {
      wrapper: 'video-tool',
      input: 'video-tool__input'
    };
  }

  render() {
    return this.nodes.wrapper;
  }

  save() {
    const url = this.nodes.link.innerText;
    const description = this.nodes.description.innerText;

    return Object.assign(this.data, {
      content: {
        url: url,
        description: description
      }
    });
  }

  get data() {
    return this._data;
  }

  set data(data) {
    this._data = data || {};
    this.nodes.link.innerText = this._data.content
      ? this._data.content.url
      : '';
    this.nodes.description.innerText = this._data.content
      ? this._data.content.description
      : '';
  }
}

export default VideoPlugin;
