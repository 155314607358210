import axios from 'axios';
import qs from 'query-string';

export const Http = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'https://blog.dev.atwinta.ru/api/admin/'
      : 'https://blog.dev.atwinta.ru/api/admin/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: 'bracket' });
  }
});

Http.interceptors.request.use(
  config => {
    const token = window.localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error.response.data)
);
