export const actions = {
  handleSidebarCollapsed: () =>
    ({
      type: '[LAYOUT] HANDLE_SIDEBAR_COLLAPSED'
    } as const),

  handleSearchArticleCollapsed: () =>
    ({
      type: '[LAYOUT] HANDLE_SEARCH_ARTICLE_COLLAPSED'
    } as const),

  setServiceEditorVisibility: (visibility: boolean) =>
    ({
      type: '[LAYOUT] HANDLE_SERVICE_EDITOR_VISIBILITY',
      payload: { visibility }
    } as const),

  setProjectEditorVisibility: (visibility: boolean) =>
    ({
      type: '[LAYOUT] HANDLE_PROJECT_EDITOR_VISIBILITY',
      payload: { visibility }
    } as const)
};
