import { takeLatest } from 'redux-saga/effects';
import { Http } from 'services/HttpService';
import { actions } from './actions';
import { notify } from 'utils/notify';

export function* fetchTags() {
  try {
    yield Http.get<IWithPagination<ITagItem[]>>('tags')
      .then(response => {
        window.store.dispatch(actions.fetchTagsSuccess(response.data.data));
      })
      .catch((error: { message: string }) => {
        notify.error(error);
        window.store.dispatch(actions.fetchTagsError(error.message));
        window.store.dispatch(actions.clearTagsData());
      });
  } catch (error) {
    console.error(error);
  }
}

export default function* tagsSaga() {
  yield takeLatest('[TAGS] FETCH_TAGS_REQUEST', fetchTags);
}
