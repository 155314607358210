import React from 'react';
import {
  Tags,
  Login,
  EditTag,
  Rewards,
  Authors,
  Articles,
  Services,
  Projects,
  CreateTag,
  EditReward,
  EditAuthor,
  EditArticle,
  EditCategory,
  EditProject,
  EditService,
  CreateAuthor,
  CreateReward,
  CreateArticle,
  CreateService,
  CreateProject,
  CreateCategory,
  FilterCategories,
  Vacancies,
  About
} from 'pages';
import { useSelector } from 'react-redux';
import { DefaultLayout } from 'components';
import { setHistory } from 'helpers/redirect';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router';

const Routes: React.FC = () => {
  const isAuth = useSelector((store: IStore) => store.user.isAuth);

  return (
    <>
      <Route component={HistorySetter} />
      {!isAuth && <Route path="/login" component={Login} />}
      {isAuth && (
        <Switch>
          <DefaultLayout>
            <Route exact path="/">
              <Redirect to="/articles" />
            </Route>

            <Route exact path="/projects" component={Projects} />
            <Route exact path="/projects/create" component={CreateProject} />
            <Route exact path="/projects/edit/:id" component={EditProject} />

            <Route exact path="/articles" component={Articles} />
            <Route exact path="/articles/create" component={CreateArticle} />
            <Route exact path="/articles/edit/:id" component={EditArticle} />

            <Route exact path="/services" component={Services} />
            <Route exact path="/services/create" component={CreateService} />
            <Route exact path="/services/edit/:id" component={EditService} />

            <Route exact path="/rewards" component={Rewards} />
            <Route exact path="/rewards/create" component={CreateReward} />
            <Route exact path="/rewards/edit/:id" component={EditReward} />

            <Route exact path="/tags" component={Tags} />
            <Route exact path="/tags/create" component={CreateTag} />
            <Route exact path="/tags/edit/:id" component={EditTag} />

            <Route exact path="/authors" component={Authors} />
            <Route exact path="/authors/create" component={CreateAuthor} />
            <Route exact path="/authors/edit/:id" component={EditAuthor} />

            <Route exact path="/categories/create" component={CreateCategory} />
            <Route exact path="/categories/edit/:id" component={EditCategory} />

            <Route exact path="/vacancies" component={Vacancies} />

            <Route exact path="/about" component={About} />

            <FilterCategories />
          </DefaultLayout>
        </Switch>
      )}
    </>
  );
};

function HistorySetter({ history }: RouteComponentProps) {
  setHistory(history);
  return null;
}

export default Routes;
