import React from 'react';
import styled from 'styled-components';
import usePresenter from './usePresenter';
import { Editor, Spinner } from 'components';
import { ProjectParams } from 'components/Forms';
import { getInitialValues } from './utils/getInitialValues';
import { transformBlocksFromBackend } from 'utils/transformBlocksFromBackend';
import { useSelector } from 'react-redux';

const EditProjectPage: React.FC = () => {
  const { data, loading, error, hasContent } = usePresenter();
  const { showEditor } = useSelector((store: IStore) => store.layout.projects);

  return (
    <>
      {hasContent && <Content data={data} showEditor={showEditor} />}
      {loading && <Spinner size="large" />}
      {error.message && <ErrorMessage>{error.message}</ErrorMessage>}
    </>
  );
};

const Content: React.FC<{ data: IProject | null; showEditor: boolean }> = ({
  data,
  showEditor
}) => {
  if (!data) {
    return null;
  }

  const initialValues = getInitialValues(data);

  return (
    <Page>
      <ProjectParams initialValues={initialValues} isCreateProject={false} />
      {showEditor && (
        <EditorWrapper>
          <Editor blocks={transformBlocksFromBackend(data.project.blocks)} />
        </EditorWrapper>
      )}
    </Page>
  );
};

const Page = styled.article`
  width: 100%;
`;

const EditorWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 50px;
  background-color: $white;
  box-shadow: 0 0 4px;
`;

const ErrorMessage = styled.div`
  font-size: 40px;
  text-align: center;
`;

export default EditProjectPage;
