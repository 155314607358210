export const actions = {
  fetchRewardRequest: (id: string) =>
    ({
      type: '[REWARD] FETCH_REWARD_REQUEST',
      payload: { id }
    } as const),

  fetchRewardSuccess: (reward: IRewardItem) =>
    ({
      type: '[REWARD] FETCH_REWARD_SUCCESS',
      payload: { reward }
    } as const),

  fetchRewardError: (message: string) =>
    ({
      type: '[REWARD] FETCH_REWARD_ERROR',
      payload: { message }
    } as const),

  clearRewardData: () =>
    ({
      type: '[REWARD] CLEAR_REWARD_DATA'
    } as const)
};
