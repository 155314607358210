import React from 'react';
import { Spinner } from 'components';
import styled from 'styled-components';
import usePresenter from './usePresenter';
import { Pagination, Button } from 'antd';
import { helperRedirect } from 'helpers/redirect';
import { ProjectItem, SearchProject } from './components';
import { SectionDescriptionModal } from 'components/modals';

const ProjectsPage: React.FC = () => {
  const {
    data,
    error,
    loading,
    pagination,
    hasContent,
    nothingFound,
    handleShowSearch,
    onChangeCurrentPage
  } = usePresenter();

  if (error.message) {
    return null;
  }

  return (
    <Page>
      <div>
        <ButtonsGroup>
          <Button onClick={() => helperRedirect('/projects/create')}>
            Создать проект
          </Button>
          <StyledButton onClick={handleShowSearch}>Поиск проектов</StyledButton>
          <SectionDescriptionModal slug="projects"></SectionDescriptionModal>
        </ButtonsGroup>

        <SearchProject />
      </div>

      <Content>
        {hasContent && <Projects data={data} />}
        {loading && <Spinner size="large" />}
        {nothingFound && (
          <NothingFoundMessage>ничего не найдено</NothingFoundMessage>
        )}
      </Content>

      <div>
        {hasContent && pagination.pageSize && (
          <StyledPagination
            total={pagination.total}
            pageSize={pagination.pageSize}
            onChange={onChangeCurrentPage}
            current={pagination.currentPage}
          />
        )}
      </div>
    </Page>
  );
};

const Projects: React.FC<{ data: IProjectItem[] | null }> = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <>
      {data.map((item, index) => (
        <ProjectItem data={item} key={index} />
      ))}
    </>
  );
};

const NothingFoundMessage = styled.div`
  font-size: 40px;
  text-align: center;
  width: 100%;
`;

const Page = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: 100%;
`;

const ButtonsGroup = styled.div`
  display: flex;
`;

const StyledButton = styled(props => <Button {...props} />)`
  margin-left: 10px;
  margin-right: 10px;
`;

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 -10px;
`;

const StyledPagination = styled(Pagination)`
  margin-top: 14px;
`;

export default ProjectsPage;
