export const actions = {
  fetchArticleRequest: (id: string) =>
    ({
      type: '[ARTICLE] FETCH_ARTICLE_REQUEST',
      payload: id
    } as const),

  fetchArticleSuccess: (article: IArticle) =>
    ({
      type: '[ARTICLE] FETCH_ARTICLE_SUCCESS',
      payload: article
    } as const),

  fetchArticleError: (message: string) =>
    ({
      type: '[ARTICLE] FETCH_ARTICLE_ERROR',
      payload: message
    } as const),

  clearArticleData: () =>
    ({
      type: '[ARTICLE] CLEAR_ARTICLE_DATA'
    } as const)
};
