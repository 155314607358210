export const FETCH_USER_REQUEST = '[USER] FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = '[USER] FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = '[USER] FETCH_USER_ERROR';

export const USER_LOGOUT = '[USER] USER_LOGOUT';

interface IParams {
  name: string;
  password: string;
}

export interface IFetchUserRequest {
  readonly type: typeof FETCH_USER_REQUEST;
  readonly payload: {
    name: string;
    password: string;
  };
}

export interface IFetchUserSuccess {
  readonly type: typeof FETCH_USER_SUCCESS;
}

export interface IFetchUserError {
  readonly type: typeof FETCH_USER_ERROR;
  readonly payload: string;
}

export interface IUserLogout {
  readonly type: typeof USER_LOGOUT;
}

export const fetchUserRequest = (params: IParams): IFetchUserRequest => ({
  type: FETCH_USER_REQUEST,
  payload: params
});

export const fetchUserSuccess = (): IFetchUserSuccess => ({
  type: FETCH_USER_SUCCESS
});

export const fetchUserError = (message: string): IFetchUserError => ({
  type: FETCH_USER_ERROR,
  payload: message
});

export const userLogout = (): IUserLogout => ({
  type: USER_LOGOUT
});
