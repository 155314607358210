import { buttonIcon } from './icons/buttonIcon';
import { findIndex, findLast } from 'lodash';
import { API } from '@editorjs/editorjs';
import { make } from '../utils/makeElement';

import './styles.css';

export default class Ui {
  api: API;
  config: any;
  onSelectFile: () => void;
  nodes: {
    wrapper: HTMLDivElement;
    imageContainer: HTMLDivElement;
    fileButton: HTMLButtonElement;
    imageEl: undefined | HTMLElement;
    imageWrapper: HTMLDivElement;
    slider: HTMLDivElement;
    slidesWrapper: HTMLDivElement;
    nextSlideButton: HTMLDivElement;
    prevSlideButton: HTMLDivElement;
    sliderToolbar: HTMLDivElement;
    altInput: HTMLInputElement;
    descriptionInput: HTMLTextAreaElement;
    imagePreloader?: HTMLElement;
  };
  getImages: () => IEditorImage[];
  deleteImageFromData: (id: number) => void;
  handleChangeAltValue: (event: Event, imageID: number) => void;
  handleChangeDescriptionValue: (event: Event, imageID: number) => void;
  currentSlide: number;
  currentImageID: number;

  constructor({
    api,
    config,
    onSelectFile,
    deleteImageFromData,
    getImages,
    handleChangeAltValue,
    handleChangeDescriptionValue
  }: any) {
    this.getImages = getImages;
    this.api = api;
    this.config = config;
    this.onSelectFile = onSelectFile;
    this.deleteImageFromData = deleteImageFromData;
    this.handleChangeAltValue = handleChangeAltValue;
    this.handleChangeDescriptionValue = handleChangeDescriptionValue;
    this.currentSlide = 0;
    this.currentImageID = 0;
    this.nodes = {
      wrapper: make('div', [this.CSS.baseClass, this.CSS.wrapper]),
      imageContainer: make('div', [this.CSS.imageContainer]),
      fileButton: this.createFileButton(),
      imageEl: undefined,
      imageWrapper: make('div', this.CSS.imageWrapper),
      slider: make('div', this.CSS.slider),
      slidesWrapper: make('div', this.CSS.slidesWrapper),
      nextSlideButton: make('div', this.CSS.swapSlideButton),
      prevSlideButton: make('div', this.CSS.swapSlideButton),
      sliderToolbar: make('div', this.CSS.sliderToolbar),
      altInput: make('input', this.CSS.altInput, { placeholder: 'alt' }),
      descriptionInput: make('textarea', this.CSS.descriptionInput, {
        placeholder: 'Описание'
      })
    };
    this.nodes.nextSlideButton.innerText = 'NEXT';
    this.nodes.prevSlideButton.innerText = 'PREV';
    this.nodes.slider.appendChild(this.nodes.slidesWrapper);
    this.nodes.wrapper.appendChild(this.nodes.imageContainer);
    this.nodes.wrapper.appendChild(this.nodes.fileButton);
    this.nodes.sliderToolbar.appendChild(this.nodes.altInput);
    this.nodes.sliderToolbar.appendChild(this.nodes.descriptionInput);
  }

  get CSS() {
    return {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      input: this.api.styles.input,
      button: this.api.styles.button,

      wrapper: 'image-tool',
      imageContainer: 'image-tool__images',
      imageWrapper: 'image-tool__wrapper',
      imageItem: 'image-tool__item',
      imageEl: 'image-tool__image-picture',
      deleteIcon: 'image-tool__delete-icon',
      slider: 'image-tool__slider',
      slidesWrapper: 'image-tool__slides-wrapper',
      swapSlideButton: 'image-tool__swap-slide-button',
      slides: 'image-tool__slides',
      slide: 'image-tool__slide',
      image: 'image-tool__image-slide',
      closeSlider: 'image-tool__close-slider',
      sliderToolbar: 'image-tool__slider-toolbar',
      altInput: 'image-tool__alt-input',
      descriptionInput: 'image-tool__description-input'
    };
  }

  static get status(): { [key: string]: string } {
    return {
      EMPTY: 'empty',
      UPLOADING: 'loading',
      FILLED: 'filled'
    };
  }

  createFileButton() {
    let button = make('div', [this.CSS.button]);

    button.innerHTML =
      this.config.buttonContent || `${buttonIcon} Select Images`;

    button.addEventListener('click', () => {
      this.onSelectFile();
    });

    return button;
  }

  onDeleteImage = (imageId: number) => {
    const removedImage = document.getElementById(String(imageId));
    if (!removedImage) {
      return;
    }
    this.deleteImageFromData(imageId);
    removedImage.remove();
  };

  onCloseSlider = () => {
    while (this.nodes.slidesWrapper.firstChild) {
      this.nodes.slidesWrapper.removeChild(this.nodes.slidesWrapper.firstChild);
    }
    this.nodes.slider.remove();
  };

  outsideSliderClick = (event: Event) => {
    if (event.composedPath().includes(this.nodes.slidesWrapper)) {
      return;
    }
    this.onCloseSlider();
  };

  nextSlide = () => {
    this.goToSlide(this.currentSlide + 1);
  };

  prevSlide = () => {
    this.goToSlide(this.currentSlide - 1);
  };

  goToSlide = (n: number) => {
    const slides = document.querySelectorAll(
      '.image-tool__slides .image-tool__slide'
    );
    slides[this.currentSlide].className = 'image-tool__slide';
    this.currentSlide = (n + slides.length) % slides.length;
    slides[this.currentSlide].className = 'image-tool__slide showing';
    const nextImageID = slides[this.currentSlide].attributes[1].value;
    this.currentImageID = Number(nextImageID);
    this.addToolbarListeners();
  };

  onOpenSlider = (currentImageID: number) => {
    this.currentImageID = currentImageID;

    this.nodes.slider.addEventListener('click', this.outsideSliderClick, false);

    const closeSliderButton: HTMLDivElement = make('div', this.CSS.closeSlider);
    closeSliderButton.innerText = 'X';
    closeSliderButton.addEventListener('click', this.onCloseSlider, false);

    const images = this.getImages();
    const itemList: HTMLUListElement = make('ul', this.CSS.slides);

    images.forEach(img => {
      const item: HTMLLIElement = make('li', this.CSS.slide, {
        id: img.image_id
      });
      const image: HTMLImageElement = make('img', this.CSS.image, {
        src: img.image.original
      });
      if (currentImageID === img.image_id) {
        item.classList.add('showing');
      }
      item.appendChild(image);
      itemList.appendChild(item);
    });

    itemList.appendChild(closeSliderButton);
    itemList.appendChild(this.nodes.sliderToolbar);

    this.nodes.nextSlideButton.addEventListener('click', this.nextSlide, false);
    this.nodes.prevSlideButton.addEventListener('click', this.prevSlide, false);
    this.nodes.slidesWrapper.appendChild(this.nodes.prevSlideButton);
    this.nodes.slidesWrapper.appendChild(this.nodes.nextSlideButton);
    this.nodes.slidesWrapper.insertBefore(
      itemList,
      this.nodes.slidesWrapper.children[1]
    );
    this.nodes.imageContainer.appendChild(this.nodes.slider);

    const slides = document.querySelectorAll(
      '.image-tool__slides .image-tool__slide'
    );

    const currentSlide = findIndex(slides, item => {
      const imageID = item.attributes[1].value;
      return Number(imageID) === currentImageID;
    });

    this.currentSlide = currentSlide;
    this.addToolbarListeners();
  };

  addToolbarListeners = () => {
    const images = this.getImages();
    this.nodes.altInput.addEventListener(
      'change',
      event => this.handleChangeAltValue(event, this.currentImageID),
      false
    );
    this.nodes.descriptionInput.addEventListener(
      'change',
      event => this.handleChangeDescriptionValue(event, this.currentImageID),
      false
    );

    const currentImage = findLast(
      images,
      image => image.image_id === this.currentImageID
    );

    if (currentImage) {
      this.nodes.altInput.value = currentImage.alt ? currentImage.alt : '';
      this.nodes.descriptionInput.value = currentImage.description
        ? currentImage.description
        : '';
    } else this.nodes.altInput.value = '';
  };

  showPreview(image: IImage) {
    const imageWrapper = make('div', this.CSS.imageWrapper, { id: image.id });
    const imageItem = make('div', this.CSS.imageItem);

    imageItem.style.backgroundImage = `url(${image.original})`;
    imageItem.addEventListener(
      'click',
      () => this.onOpenSlider(image.id),
      false
    );

    const deleteIcon = make('div', this.CSS.deleteIcon);
    deleteIcon.addEventListener(
      'click',
      () => this.onDeleteImage(Number(imageWrapper.attributes.id.value)),
      false
    );
    deleteIcon.innerText = 'X';

    imageWrapper.appendChild(deleteIcon);
    imageWrapper.appendChild(imageItem);

    this.nodes.imageContainer.appendChild(imageWrapper);

    this.toggleStatus(Ui.status.UPLOADING);
  }

  hidePreview() {
    if (!this.nodes.imagePreloader) {
      return;
    }
    this.nodes.imagePreloader.style.backgroundImage = '';
    this.toggleStatus(Ui.status.EMPTY);
  }

  fillImage(url: string) {
    const tag = /\.mp4$/.test(url) ? 'VIDEO' : 'IMG';

    let attributes: { [key: string]: string | boolean } = {
      src: url
    };

    let eventName = 'load';

    if (tag === 'VIDEO') {
      attributes.autoplay = true;
      attributes.loop = true;
      attributes.muted = true;
      attributes.playsinline = true;

      eventName = 'loadeddata';
    }

    this.nodes.imageEl = make(tag, this.CSS.imageEl, attributes);

    if (this.nodes.imageEl) {
      this.nodes.imageEl.addEventListener(eventName, () => {
        this.toggleStatus(Ui.status.FILLED);

        if (this.nodes.imagePreloader) {
          this.nodes.imagePreloader.style.backgroundImage = '';
        }
      });
    }

    if (this.nodes.imageEl) {
      this.nodes.imageContainer.appendChild(this.nodes.imageEl);
    }
  }

  render(toolData: any) {
    if (!toolData.file || Object.keys(toolData.file).length === 0) {
      this.toggleStatus(Ui.status.EMPTY);
    } else {
      this.toggleStatus(Ui.status.UPLOADING);
    }

    return this.nodes.wrapper;
  }

  toggleStatus(status: string) {
    for (const statusType in Ui.status) {
      if (Ui.status.hasOwnProperty(statusType)) {
        this.nodes.wrapper.classList.toggle(
          `${this.CSS.wrapper}--${Ui.status[statusType]}`,
          status === Ui.status[statusType]
        );
      }
    }
  }

  applyTune(tuneName: string, status: boolean) {
    this.nodes.wrapper.classList.toggle(
      `${this.CSS.wrapper}--${tuneName}`,
      status
    );
  }
}
