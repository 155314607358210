export const actions = {
  fetchVacanciesRequest: () =>
    ({
      type: '[VACANCIES] FETCH_VACANCIES_REQUEST'
    } as const),

  fetchVacanciesSuccess: (vacancies: IVacancies) =>
    ({
      type: '[VACANCIES] FETCH_VACANCIES_SUCCESS',
      payload: vacancies
    } as const),

  fetchVacanciesError: (message: string) =>
    ({
      type: '[VACANCIES] FETCH_VACANCIES_ERROR',
      payload: message
    } as const),

  clearVacanciesData: () =>
    ({
      type: '[VACANCIES] CLEAR_VACANCIES_DATA'
    } as const)
};
