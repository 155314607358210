import { Component } from 'react';
import { API } from '@editorjs/editorjs';
import { BLOCK_COLOR_NUM } from '../consts';
import { BlockColors } from '../enums';
import { make } from 'plugins/utils/makeElement';

import './index.css';

interface IItem {
  price: string;
  time: string;
  title: string;
  description: string;
}

interface IPropsData {
  content: IItem[];
  params: {
    color: {
      label: string;
      value: string;
    };
    isHidden: boolean;
  };
}

interface IPriceListPluginProps {
  data: IPropsData;
  api: API;
}

class PriceListPlugin extends Component<IPriceListPluginProps> {
  api: API;

  nodes: {
    wrapper: HTMLDivElement;
    wrapperFlex: HTMLDivElement;
    item: [];
    buttonAdd: HTMLButtonElement;
    labelBlockColor: HTMLLabelElement;
    selectBlockColor: HTMLSelectElement;
  };

  _data: {
    content: IItem[];
    params: {
      color: {
        label: string;
        value: string;
      };
      isHidden: boolean;
    };
  };

  constructor(props: IPriceListPluginProps) {
    super(props);
    this.api = this.props.api;

    this.nodes = {
      wrapper: make('div', this.CSS.wrapper),
      wrapperFlex: make('div', this.CSS.wrapperFlex),
      item: [],
      buttonAdd: make('button', this.CSS.buttonAdd),
      labelBlockColor: make('label', this.CSS.labelBlockColor),
      selectBlockColor: make('select', this.CSS.selectBlockColor)
    };

    this._data = {
      content: [],
      params: {
        color: {
          label: 'Белый',
          value: '#ffffff'
        },
        isHidden: false
      }
    };

    this.initNodes();

    this.data = this.props.data;
  }

  initNodes() {
    this.initNodesInnerText();

    this.initSelectsOptions();

    this.nodes.wrapper.appendChild(this.nodes.wrapperFlex);
  }

  initNodesInnerText() {
    this.nodes.buttonAdd.innerText = '+';
    this.nodes.labelBlockColor.innerText = 'Цвет блока: ';
  }

  initSelectsOptions() {
    for (let i = 0; i < BLOCK_COLOR_NUM; i++) {
      let option = make('option');
      option.text = BlockColors[i];
      this.nodes.selectBlockColor.add(option);
    }
  }

  get CSS() {
    return {
      wrapper: 'wrapper',
      wrapperFlex: 'pricelist__items',
      item: 'pricelist__item',
      buttonAdd: ['pricelist__button'],
      itemTitle: 'pricelist__item_title',
      itemPrice: 'pricelist__item_price',
      itemDuration: 'pricelist__item_duration',
      itemDescription: 'pricelist__item_description',
      containerLeft: 'pricelist__item_left',
      containerRight: 'pricelist__item_right',
      deleteButton: 'pricelist__item_delete',
      labelBlockColor: 'pricelist__label-block-color',
      selectBlockColor: 'pricelist__input-color'
    };
  }

  get data() {
    return this._data;
  }

  set data(data) {
    if (Object.keys(data).length !== 0) {
      this._data = data;
    }

    this.nodes.selectBlockColor.oninput = () => {
      this.nodes.wrapper.style.backgroundColor =
        this.setOutputColorValue() as string;
    };

    this.nodes.wrapper.style.backgroundColor =
      this.setOutputColorValue() as string;

    this.nodes.wrapperFlex.appendChild(this.nodes.labelBlockColor);
    this.nodes.wrapperFlex.appendChild(this.nodes.selectBlockColor);

    this.nodes.wrapperFlex.appendChild(this.nodes.buttonAdd);

    this.nodes.buttonAdd.onclick = () => {
      this.addItem();
    };

    for (let i in this.data.content) {
      this.addItem(i);
    }
  }

  addItem(i?: any) {
    if (!i) {
      this.data.content.push({
        description: '',
        title: '',
        time: '',
        price: ''
      });
    }
    let container = make('div', this.CSS.item);

    let title, price, time, description;

    if (i) {
      ({ title, price, time, description } = this.data.content[i]);
    }
    let titleElem = make('input', this.CSS.itemTitle);
    let priceElem = make('input', this.CSS.itemPrice);
    let timeElem = make('input', this.CSS.itemDuration);
    let descriptionElem = make('input', this.CSS.itemDescription);

    let deleteButton = make('button', this.CSS.deleteButton);
    deleteButton.innerText = 'x';

    if (i) {
      titleElem.value = title !== undefined ? title : '';
      priceElem.value = price !== undefined ? price : '';
      timeElem.value = time !== undefined ? time : '';
      descriptionElem.value = description !== undefined ? description : '';
    }

    titleElem.placeholder = 'Заголовок';
    priceElem.placeholder = 'Цена';
    timeElem.placeholder = 'Время';
    descriptionElem.placeholder = 'Описание';

    let leftDiv = make('div', this.CSS.containerLeft);
    let rightDiv = make('div', this.CSS.containerRight);

    leftDiv.appendChild(titleElem);
    leftDiv.appendChild(descriptionElem);

    rightDiv.appendChild(priceElem);
    rightDiv.appendChild(timeElem);

    container.appendChild(leftDiv);
    container.appendChild(rightDiv);
    container.appendChild(deleteButton);

    this.nodes.wrapperFlex.appendChild(container);

    this.itemSetListeners(
      deleteButton,
      titleElem,
      priceElem,
      timeElem,
      descriptionElem
    );
  }

  itemSetListeners(
    deleteButton: HTMLButtonElement,
    titleElem: HTMLInputElement,
    priceElem: HTMLInputElement,
    timeElem: HTMLInputElement,
    descriptionElem: HTMLInputElement
  ) {
    deleteButton.onclick = () => {
      for (let i = 0; i < this.data.content.length; i++) {
        if (
          this.nodes.wrapperFlex.childNodes[i + 3].childNodes[2] ===
          deleteButton
        ) {
          this.data.content.splice(i, 1);
        }
      }
      deleteButton.parentElement?.remove();
    };

    titleElem.oninput = (e: Event) => {
      for (let i = 0; i < this.nodes.wrapperFlex.childNodes.length - 3; i++) {
        if (
          this.nodes.wrapperFlex.childNodes[i + 3].childNodes[0]
            .childNodes[0] === (e.currentTarget as Node)
        ) {
          this.data.content[i].title = (
            this.nodes.wrapperFlex.childNodes[i + 3].childNodes[0]
              .childNodes[0] as HTMLInputElement
          ).value;
        }
      }
    };

    timeElem.oninput = (e: Event) => {
      for (let i = 0; i < this.nodes.wrapperFlex.childNodes.length - 3; i++) {
        if (
          this.nodes.wrapperFlex.childNodes[i + 3].childNodes[1]
            .childNodes[1] === (e.currentTarget as Node)
        ) {
          this.data.content[i].time = (
            this.nodes.wrapperFlex.childNodes[i + 3].childNodes[1]
              .childNodes[1] as HTMLInputElement
          ).value;
        }
      }
    };

    priceElem.oninput = (e: Event) => {
      for (let i = 0; i < this.nodes.wrapperFlex.childNodes.length - 3; i++) {
        if (
          this.nodes.wrapperFlex.childNodes[i + 3].childNodes[1]
            .childNodes[0] === (e.currentTarget as Node)
        ) {
          this.data.content[i].price = (
            this.nodes.wrapperFlex.childNodes[i + 3].childNodes[1]
              .childNodes[0] as HTMLInputElement
          ).value;
        }
      }
    };

    descriptionElem.oninput = (e: Event) => {
      for (let i = 0; i < this.nodes.wrapperFlex.childNodes.length - 3; i++) {
        if (
          this.nodes.wrapperFlex.childNodes[i + 3].childNodes[0]
            .childNodes[1] === (e.currentTarget as Node)
        ) {
          this.data.content[i].description = (
            this.nodes.wrapperFlex.childNodes[i + 3].childNodes[0]
              .childNodes[1] as HTMLInputElement
          ).value;
        }
      }
    };
  }

  save() {
    return {
      content: this.data.content,
      params: {
        color: {
          label: this.nodes.selectBlockColor.value,
          value: this.setOutputColorValue()
        },
        //warning
        isHidden: false
      }
    };
  }

  setOutputColorValue() {
    if (this.nodes.selectBlockColor.value === 'Белый') {
      return '#ffffff';
    } else if (this.nodes.selectBlockColor.value === 'Серый') {
      return '#f5f5f5';
    }
  }

  render() {
    return this.nodes.wrapper;
  }

  static get toolbox() {
    return {
      icon: '<b>PL</b>',
      title: 'Прайс-лист'
    };
  }
}

export default PriceListPlugin;
