export const transformBlocksFromEditor = (blocks: TEditorBlocks) => {
  const transformBlocks = blocks.map(block => {
    switch (block.type) {
      case 'header': {
        return {
          type: 'heading-block',
          content: {
            content: (block as IEditorBlockHeader).data.text,
            type: (block as IEditorBlockHeader).data.level
          }
        };
      }
      case 'paragraph': {
        const content = (block as IEditorBlockParagraph).data.text.replace(
          /<a href=/i,
          '<a target="_blank" href='
        );
        return {
          type: 'paragraph-block',
          content
        };
      }
      case 'images': {
        return {
          type: 'image-block',
          content: (block as IEditorBlockImages).data.images
        };
      }
      case 'video':
        return {
          type: 'video-block',
          content: (block as IEditorBlockVideo).data.content,
          params: (block as IEditorBlockVideo).data.params
        };
      case 'anchor':
        return {
          type: 'anchor-block',
          content: (block as IEditorBlockAnchor).data.content
        };
      case 'factoid':
        return {
          type: 'factoid-block',
          content: (block as IEditorBlockAnchor).data.content
        };
      case 'quote': {
        return {
          type: 'quote-block',
          content: (block as IEditorBlockQuote).data
        };
      }
      case 'list': {
        return {
          type: 'list-block',
          content: (block as IEditorBlockList).data
        };
      }
      case 'imageWithText': {
        const { data } = block as IEditorBlockImageWithText;
        return {
          type: 'paragraph-with-img-block',
          content: {
            text: data.text,
            title: data.title,
            image: {
              url: data.image.url,
              content: data.image.id,
              alt: data.alt
            },
            params: {
              imageAlign: data.float
            }
          }
        };
      }
      case 'button': {
        const { data } = block as IEditorBlockButton;
        return {
          type: 'button-block',
          content: {
            text: data.content.text
          },
          params: {
            color: {
              label: data.params.color.label,
              value: data.params.color.value
            },
            float: data.params.float,
            isHidden: data.params.isHidden
          }
        };
      }
      case 'priceList': {
        const { data } = block as IEditorBlockPriceList;
        return {
          type: 'price-list-block',
          content: data.content,
          params: data.params
        };
      }
      case 'tariff': {
        const { data } = block as IEditorBlockTariff;
        return {
          type: 'tariff-block',
          content: data.content,
          params: data.params
        };
      }
      case 'services': {
        const { data } = block as IEditorBlockServices;
        return {
          type: 'services-block',
          content: data.content,
          params: data.params
        };
      }
      case 'cards': {
        const { data } = block as IEditorBlockCards;
        return {
          type: 'cards-block',
          content: data.content,
          params: data.params
        };
      }
      case 'cardsNoNumbers': {
        const { data } = block as IEditorBlockCardsNoNumber;
        return {
          type: 'cardsNoNumbers-block',
          content: data.content,
          params: {
            color: data.params.color,
            isHidden: data.params.isHidden,
            numCols: data.params.numCols
          }
        };
      }
      case 'expander': {
        const { data } = block as IEditorBlockExpander;
        return {
          type: 'expander-block',
          content: data.content,
          params: data.params
        };
      }
      default:
        return block;
    }
  });

  return transformBlocks;
};
