import moment from 'moment';
import { useEffect, useState } from 'react';
import { submit } from './submit';
import { hasKey } from 'helpers/hasKey';
import { useSelector } from 'react-redux';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { FormComponentProps } from 'antd/lib/form/Form';
import { handlePublish } from './handlePublish';
import { deleteArticle } from './deleteArticle';

export interface IArticleParamsProps
  extends FormComponentProps<IArticleParamsFormValues> {
  initialValues?: IInitialValues;
  isCreateArticle: boolean;
}

export interface IArticleParamsFormValues {
  alt: string;
  slug: string;
  cover: string;
  title: string;
  tags: number[];
  author: number;
  seoTitle: string;
  services: number[];
  articles: number[];
  complexity: number;
  description: string;
  interested: boolean;
  categories: number[];
  projectsKeys: number[];
  articlesKeys: number[];
  servicesKeys: number[];
  seoDescription: string;
  publishedAt: moment.Moment;
}

interface IInitialValues {
  id: number;
  alt: string;
  slug: string;
  title: string;
  cover: string;
  author: number;
  tags: number[];
  seoTitle: string;
  projects: number[];
  services: number[];
  articles: number[];
  complexity: number;
  description: string;
  interested: boolean;
  isPublished: boolean;
  categories: number[];
  seoDescription: string;
  publishedAt: moment.Moment;
}

interface IPresenterProps {
  initialValues?: IInitialValues;
  form: WrappedFormUtils<IArticleParamsFormValues>;
}

interface IInitKeys {
  servicesKeys: Array<number>;
  articlesKeys: Array<number>;
  projectsKeys: Array<number>;
}

const usePresenter = ({ initialValues, form }: IPresenterProps) => {
  const editor = useSelector((store: IStore) => store.editor);

  const [publishedStatus, setPublishedStatus] = useState<number>(0);

  useEffect(() => {
    if (!initialValues) {
      return;
    }

    const status = initialValues.isPublished ? 0 : 1;

    setPublishedStatus(status);
  }, [initialValues]);

  useEffect(() => {
    if (!initialValues) {
      return;
    }

    const initKeys: IInitKeys = {
      servicesKeys: [],
      articlesKeys: [],
      projectsKeys: []
    };

    const keys: Array<{ initKey: string; index: string }> = [
      { initKey: 'servicesKeys', index: 'services' },
      { initKey: 'articlesKeys', index: 'articles' },
      { initKey: 'projectsKeys', index: 'projects' }
    ];

    keys.forEach(element => {
      if (hasKey(initialValues, element.index)) {
        (initialValues[element.index] as number[]).forEach(() => {
          if (hasKey(initKeys, element.initKey)) {
            initKeys[element.initKey].push(
              Number(initKeys[element.initKey].length)
            );
          }
        });
      }
    });

    form.setFieldsValue({ ...initialValues, ...initKeys });
    // eslint-disable-next-line
  }, []);

  const projects = form.getFieldValue('projectsKeys');
  const articles = form.getFieldValue('articlesKeys');

  const disabled = Boolean(
    projects && articles && [...projects, ...articles].length >= 3
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    form.validateFields((err, values) => {
      const id = initialValues ? initialValues.id : null;
      if (!err) {
        submit(
          {
            ...values,
            blocks: editor.transformBlocks
          },
          id
        );
      }
    });
  };

  const onDeleteArticle = () => {
    if (!initialValues) {
      return;
    }

    const { id } = initialValues;

    deleteArticle(id);
  };

  const onChangePublishedStatus = () => {
    if (!initialValues) {
      return;
    }

    const { id } = initialValues;

    handlePublish({ id, publishedStatus, setPublishedStatus });
  };

  return {
    handleSubmit,
    disabled,
    onChangePublishedStatus,
    publishedStatus,
    onDeleteArticle
  };
};

export default usePresenter;
