import React from 'react';
import { ServiceParams } from 'components/Forms';
import Editor from 'components/Editor';
import styled from 'styled-components';
import usePresenter from './usePresenter';
import { useSelector } from 'react-redux';

const CreateServicePage: React.FC = () => {
  const { showEditor } = useSelector((store: IStore) => store.layout.services);

  usePresenter();

  return (
    <Page>
      <ServiceParams isCreateService />
      {showEditor && (
        <EditorWrapper>
          <Editor />
        </EditorWrapper>
      )}
    </Page>
  );
};

const Page = styled.article`
  width: 100%;
`;

const EditorWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 50px;
  background-color: $white;
  box-shadow: 0 0 4px;
`;

export default CreateServicePage;
