import { useCallback } from 'react';
import { ClickParam } from 'antd/lib/menu';
import { useLocation } from 'react-router';
import { helperRedirect } from 'helpers/redirect';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentPage } from 'store/pagination/actions';

const usePresenter = () => {
  const collapsed = useSelector(
    (store: IStore) => store.layout.sidebar.collapsed
  );
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const handleClick = useCallback(
    (param: ClickParam) => {
      window.localStorage.removeItem('pagination_currentPage');
      dispatch(setCurrentPage(1));

      if (param.key === '/') {
        helperRedirect('/');
        return;
      } else {
        helperRedirect(`/${param.key}`);
      }
    },
    [dispatch]
  );

  return {
    collapsed,
    selectedKeys: [pathname.split('/')[1] || '/'],
    handleClick
  };
};

export default usePresenter;
