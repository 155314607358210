import { useEffect, useCallback } from 'react';
import { actions as layoutActions } from 'store/layout/actions';
import { setCurrentPage } from 'store/pagination/actions';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store/articles/actions';

const usePresenter = () => {
  const dispatch = useDispatch();
  const pagination = useSelector((store: IStore) => store.pagination);
  const { loading, data, error } = useSelector(
    (store: IStore) => store.articles
  );

  useEffect(() => {
    dispatch(actions.fetchArticlesRequest(`page=${pagination.currentPage}`));
    // eslint-disable-next-line
  }, []);

  const onChangeCurrentPage = useCallback(
    (page: number) => {
      dispatch(setCurrentPage(page));
      dispatch(actions.fetchArticlesRequest(`page=${page}`));
    },
    [dispatch]
  );

  const handleShowSearch = useCallback(() => {
    dispatch(layoutActions.handleSearchArticleCollapsed());
  }, [dispatch]);

  const nothingFound = Boolean(data && !data.length);

  const hasContent = Boolean(!loading && !error && data && !nothingFound);

  return {
    handleShowSearch,
    pagination,
    onChangeCurrentPage,
    loading,
    data,
    error,
    hasContent,
    nothingFound
  };
};

export default usePresenter;
