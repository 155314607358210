import moment from 'moment';
import { Select, Form, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { WithPassedFormProps } from 'enhancers/withPassedFormProps';

const { Option } = Select;

interface IYearFieldProps {
  name: string;
  rules?: any[];
  label?: string;
  placeholder?: string;
  initialValue?: number;
  form?: WrappedFormUtils;
  styles?: { [key: string]: string | number };
}

interface IItem {
  value: number;
  label: number;
}

const YearField: React.FC<IYearFieldProps & IFormProps> = ({
  form: { getFieldDecorator },
  rules,
  initialValue,
  label,
  placeholder,
  name,
  styles
}) => {
  const [items, setItems] = useState<IItem[] | null>(null);

  useEffect(() => {
    const lastYear = Number(moment().format('LL').slice(-4));

    const array: IItem[] = [];
    for (let i = 2000; i <= lastYear; i++) {
      array.push({
        label: i,
        value: i
      });
    }
    setItems(array.reverse());
  }, []);

  if (!items) {
    return <Spin size="small" />;
  }

  return (
    <Form.Item label={label} style={styles || { width: '100%' }}>
      {getFieldDecorator(name, {
        rules,
        initialValue
      })(
        <Select
          mode="default"
          placeholder={placeholder}
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
        >
          {items.map(item => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
};

export default WithPassedFormProps(YearField);
