import React from 'react';
import { Pagination, Button } from 'antd';
import { Spinner } from 'components';
import { helperRedirect } from 'helpers/redirect';
import styled from 'styled-components';
import usePresenter from './usePresenter';
import { ServicesItem } from './components';

const ServicesPage: React.FC = () => {
  const {
    pagination,
    onChangeCurrentPage,
    loading,
    data,
    error,
    hasContent,
    nothingFound
  } = usePresenter();

  if (error) {
    return null;
  }

  return (
    <Page>
      <div>
        <ButtonsGroup>
          <Button onClick={() => helperRedirect('/services/create')}>
            Создать услугу
          </Button>
        </ButtonsGroup>
      </div>

      <Content>
        {hasContent && <Services data={data} />}
        {loading && <Spinner size="large" />}
        {nothingFound && (
          <NothingFoundMessage>ничего не найдено</NothingFoundMessage>
        )}
      </Content>

      <div>
        {hasContent && pagination.pageSize && (
          <StyledPagination
            current={pagination.currentPage}
            total={pagination.total}
            pageSize={pagination.pageSize}
            onChange={onChangeCurrentPage}
          />
        )}
      </div>
    </Page>
  );
};

const Services: React.FC<{ data: Array<IServiceItem> | null }> = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <>
      {data.map((item, index) => (
        <ServicesItem data={item} key={index} />
      ))}
    </>
  );
};

const NothingFoundMessage = styled.div`
  font-size: 40px;
  text-align: center;
  width: 100%;
`;

const Page = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: 100%;
`;

const ButtonsGroup = styled.div`
  display: flex;
`;

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 -10px;
`;

const StyledPagination = styled(Pagination)`
  margin-top: 14px;
`;

export default ServicesPage;
