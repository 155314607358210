import { useEffect } from 'react';
import { submit } from './submit';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { FormComponentProps } from 'antd/lib/form/Form';

export interface IAuthorParamsProps
  extends FormComponentProps<IAuthorParamsFormValues> {
  initialValues?: IInitialValues;
}

export interface IAuthorParamsFormValues {
  name: string;
  post: string;
  avatar: string;
  description: string;
}

interface IInitialValues {
  id: number;
  name: string;
  post: string;
  avatar: string;
  description: string;
}

interface IPresenterProps {
  initialValues?: IInitialValues;
  form: WrappedFormUtils<IAuthorParamsFormValues>;
}

const usePresenter = ({ initialValues, form }: IPresenterProps) => {
  useEffect(() => {
    if (!initialValues) {
      return;
    }

    form.setFieldsValue({ ...initialValues });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    form.validateFields((err, values) => {
      const id = initialValues ? initialValues.id : null;
      if (!err) {
        submit({ ...values }, id);
      }
    });
  };

  return { handleSubmit };
};

export default usePresenter;
