import { Http } from 'services/HttpService';
import { notify } from 'utils/notify';

interface IProps {
  id: number;
  publishedStatus: number;
  setPublishedStatus: (status: number) => void;
}

export const handlePublish = ({
  id,
  publishedStatus,
  setPublishedStatus
}: IProps) => {
  Http.post(`projects/${id}/publish/${publishedStatus}`).then(response => {
    if (response.status === 200) {
      if (publishedStatus === 0) {
        setPublishedStatus(1);
        notify.success('Вы скрыли проект');
      } else {
        setPublishedStatus(0);
        notify.success('Вы опубликовали проект');
      }
    }
  });
};
