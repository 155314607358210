import React from 'react';
import styled from 'styled-components';
import usePresenter from './usePresenter';
import { RangeDatePicker } from 'components/Inputs';
import { Input, Checkbox, Row, Col, Button } from 'antd';

const SearchProject = () => {
  const {
    onInputChange,
    onCheckboxChange,
    collapsed,
    onDateChange,
    onSubmit,
    getAllProjects
  } = usePresenter();

  return (
    <Wrapper collapsed={collapsed}>
      <Input.Search onChange={onInputChange} placeholder="Поиск по названию" />

      <FilterWrapper>
        <span>фильтрация проектов:</span>
        <RangeDatePicker onChange={onDateChange} />

        <Checkbox.Group style={{ width: '100%' }} onChange={onCheckboxChange}>
          <Row>
            <Col span={4}>
              <Checkbox value={`&published=on`}>ОПУБЛИКОВАНО</Checkbox>
            </Col>

            <Col span={4}>
              <Checkbox value={`&notPublished=on`}>НЕ ОПУБЛИКОВАНО</Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>

        <Row style={{ width: '100%' }}>
          <Col span={4}>
            <Button type="primary" onClick={onSubmit}>
              Применить фильтр
            </Button>
          </Col>

          <Col span={4}>
            <Button type="danger" onClick={getAllProjects}>
              Загрузить все проекты
            </Button>
          </Col>
        </Row>
      </FilterWrapper>
    </Wrapper>
  );
};

interface IWrapperProps {
  collapsed: boolean;
}

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  min-height: 150px;
  width: 100%;
  margin: 20px 0;
`;

const Wrapper = styled.div<IWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${p =>
    p.collapsed
      ? `
      border: solid #e8e8e8 1px;
      margin: 20px 0;
      padding: 20px;
      overflow: hidden;
      max-height: 300px;
      transition: max-height 0.5s ease-out;
    `
      : `
      overflow: hidden;
      max-height: 0;
    `}
`;

export default SearchProject;
