import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

type CrumbNameType = {
  tag: 'Тег';
  tags: 'Теги';
  author: 'Автор';
  articles: 'Блог';
  authors: 'Авторы';
  create: 'Создание';
  services: 'Услуги';
  rewards: 'Награды';
  projects: 'Проекты';
  vacancies: 'Вакансии';
  about: 'О нас';
  edit: 'Редактирование';
  industry: 'Портфолио - виды отраслей';
  project: 'Портфолио - виды работ';
  article: 'Блог';
  categories: 'Категории фильтров';
};

type PathType =
  | 'articles'
  | 'services'
  | 'rewards'
  | 'create'
  | 'edit'
  | 'tags'
  | 'projects'
  | 'vacancies'
  | 'industry'
  | 'project'
  | 'article'
  | 'categories';

const crumbNames: CrumbNameType = {
  tag: 'Тег',
  tags: 'Теги',
  author: 'Автор',
  articles: 'Блог',
  authors: 'Авторы',
  create: 'Создание',
  services: 'Услуги',
  rewards: 'Награды',
  projects: 'Проекты',
  vacancies: 'Вакансии',
  about: 'О нас',
  edit: 'Редактирование',
  industry: 'Портфолио - виды отраслей',
  project: 'Портфолио - виды работ',
  article: 'Блог',
  categories: 'Категории фильтров'
};

const useBreadcrumbs = () => {
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter(i => i);

  const title = useSelector((store: IStore) => {
    if (!pathSnippets.length) {
      return;
    }

    console.log('STORE>>>>', store);

    const key = pathSnippets[0] as
      | 'articles'
      | 'services'
      | 'rewards'
      | 'tags'
      | 'projects'
      | 'industry'
      | 'authors'
      | 'industry'
      | 'project'
      | 'categories';

    let title;

    switch (key) {
      case 'tags':
        title = store.tag.data && store.tag.data.title;
        break;
      case 'rewards':
        title = store.reward.data && store.reward.data.description;
        break;
      case 'articles':
        title = store.article.data && store.article.data.article.title;
        break;
      case 'services':
        title = store.service.data && store.service.data.service.title;
        break;
      case 'projects':
        title = store.project.data && store.project.data.project.title;
        break;
      case 'authors':
        title = store.author.data && store.author.data.name;
        break;
      case 'categories':
        title = store.category.data && store.category.data.title;
        break;
      default:
        title = '';
        break;
    }

    return title;
  });

  const pathnames = pathSnippets.map((path, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return { path: url, breadcrumbName: crumbNames[path as PathType] || title };
  });

  const crumbs = [{ path: '/', breadcrumbName: 'Главная' }, ...pathnames];

  return { crumbs };
};

export default useBreadcrumbs;
