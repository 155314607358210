import { Component } from 'react';
import { API } from '@editorjs/editorjs';
import { set, omit } from 'lodash';
import Ui from './ui';
import Uploader from './uploader';

interface IResponseImage {
  id: number;
  url: string;
}

class QuotePlugin extends Component<any, any> {
  api: API;
  uploader: any;
  ui: any;
  _data: {
    quote: string;
    name: string;
    company: string;
    position: string;
    image: {
      id: number | null;
      alt: string;
      url: string;
    };
  };

  constructor({ data, api, ...props }: any) {
    super(props);
    this.api = api;

    this.uploader = new Uploader({
      data: this.data,
      onUpload: (response: IResponseImage) => this.onUpload(response),
      onError: (response: string) => this.uploadingFailed(response)
    });

    this.ui = new Ui({
      api,
      onSelectFile: () => {
        this.uploader.uploadSelectedFile();
      },
      handleChange: (event: Event, field: string) =>
        this.handleChange(event, field),
      deleteImage: () => this.deleteImage()
    });

    this._data = {
      quote: '',
      name: '',
      company: '',
      position: '',
      image: {
        id: null,
        alt: '',
        url: ''
      }
    };
    this.data = data;
  }

  handleChange = (event: Event, field: string) => {
    const { innerText } = (event as any).currentTarget;
    if (field === 'alt') {
      set(this._data.image, field, innerText);
    } else set(this._data, field, innerText);
  };

  get data() {
    return this._data;
  }

  set data(data) {
    this._data = data || {};
    this.ui.setInputValues({
      ...omit(data, ['image']),
      alt: data.image ? data.image.alt : ''
    });
    if (data.image) {
      this.ui.showPreview(data.image.url);
    }
  }

  save() {
    return this.data;
  }

  addImage = (image: IResponseImage) => {
    const newImage = { ...this._data.image, id: image.id, url: image.url };
    this._data.image = newImage || { id: null, alt: '', url: '' };
  };

  deleteImage() {
    this._data.image = { id: null, alt: '', url: '' };
    this.ui.hidePreview();
  }

  onUpload(response: IResponseImage) {
    if (response) {
      this.addImage(response);
      this.ui.showPreview(response.url);
    } else {
      this.uploadingFailed('incorrect response: ' + JSON.stringify(response));
    }
  }

  uploadingFailed(errorText: string) {
    console.log('Image Tool: uploading failed because of', errorText);

    this.api.notifier.show({
      message: 'Can not upload an image, try another',
      style: 'error'
    });
    this.ui.hidePreview();
    this.deleteImage();
  }

  render() {
    return this.ui.render(this.data);
  }

  static get toolbox() {
    return {
      icon: 'Q',
      title: 'Цитата'
    };
  }

  static get enableLineBreaks() {
    return true;
  }

  static get contentless() {
    return true;
  }
}

export default QuotePlugin;
