import React from 'react';
import { Modal } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import usePresenter from './usePresenter';

const TinyMceModal = () => {
  const { show, onSave, value, id, onCancel, onChange } = usePresenter();

  return (
    <Modal
      title="Basic Modal"
      visible={show}
      onOk={() => onSave()}
      onCancel={onCancel}
      width={1000}
      destroyOnClose
    >
      <Editor
        initialValue={value}
        id={id}
        apiKey="342yoycfjlwj57h6m7xsjl73lgyt6n9ln0dyrat37uk8nvhh"
        init={{
          placeholder: 'Текст',
          height: 500,
          initialValue: value,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar: `undo redo | formatselect | bold italic backcolor | \
                 alignleft aligncenter alignright alignjustify | \
                 bullist numlist outdent indent | removeformat | help`
        }}
        onEditorChange={onChange}
      />
    </Modal>
  );
};

export default TinyMceModal;
