import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { submit } from './submit';
import { hasKey } from 'helpers/hasKey';
import { useDispatch, useSelector } from 'react-redux';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { FormComponentProps } from 'antd/lib/form/Form';
import { handlePublish } from './handlePublish';
import { deleteProject } from './deleteProject';
import { actions } from '../../../store/layout/actions';

export interface IProjectParamsProps
  extends FormComponentProps<IProjectParamsFormValues> {
  initialValues?: IInitialValues;
  isCreateProject: boolean;
}

export interface IProjectParamsFormValues {
  alt: string;
  slug: string;
  cover: string;
  title: string;
  tildaJs: string;
  tildaCss: string;
  isTilda: boolean;
  tildaHtml: string;
  tags: number[];
  seoTitle: string;
  services: number[];
  articles?: number[];
  projects?: number[];
  description: string;
  interested: boolean;
  categories: number[];
  projectsKeys: number[];
  articlesKeys: number[];
  servicesKeys: number[];
  seoDescription: string;
  publishedAt: moment.Moment;
}

interface IInitialValues {
  id: number;
  alt: string;
  site: string;
  slug: string;
  home: string;
  title: string;
  tildaJs: string;
  tildaCss: string;
  isTilda: boolean;
  tildaHtml: string;
  cover: string;
  tags: number[];
  isUrl: boolean;
  seoTitle: string;
  favorite: boolean;
  projects: number[];
  articles: number[];
  services: number[];
  description: string;
  isPublished: boolean;
  industries: number[];
  categories: number[];
  seoDescription: string;
  siteName: string | null;
  publishedAt: moment.Moment;
}

interface IPresenterProps {
  initialValues?: IInitialValues;
  form: WrappedFormUtils<IProjectParamsFormValues>;
}

interface IInitKeys {
  servicesKeys: Array<number>;
  articlesKeys: Array<number>;
  projectsKeys: Array<number>;
}

const usePresenter = ({ initialValues, form }: IPresenterProps) => {
  const editor = useSelector((store: IStore) => store.editor);

  const dispatch = useDispatch();

  const [publishedStatus, setPublishedStatus] = useState<number>(0);

  useEffect(() => {
    if (!initialValues) {
      return;
    }

    const status = initialValues.isPublished ? 0 : 1;

    setPublishedStatus(status);
  }, [initialValues]);

  useEffect(() => {
    if (initialValues && initialValues.isTilda) {
      dispatch(actions.setProjectEditorVisibility(false));
    } else {
      dispatch(actions.setProjectEditorVisibility(true));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!initialValues) {
      return;
    }

    const initKeys: IInitKeys = {
      servicesKeys: [],
      articlesKeys: [],
      projectsKeys: []
    };

    const keys: Array<{ initKey: string; index: string }> = [
      { initKey: 'servicesKeys', index: 'services' },
      { initKey: 'articlesKeys', index: 'articles' },
      { initKey: 'projectsKeys', index: 'projects' }
    ];

    keys.forEach(element => {
      if (hasKey(initialValues, element.index)) {
        (initialValues[element.index] as number[]).forEach(() => {
          if (hasKey(initKeys, element.initKey)) {
            initKeys[element.initKey].push(
              Number(initKeys[element.initKey].length)
            );
          }
        });
      }
    });

    form.setFieldsValue({ ...initialValues, ...initKeys });
    // eslint-disable-next-line
  }, []);

  const projects = form.getFieldValue('projectsKeys');
  const articles = form.getFieldValue('articlesKeys');

  const disabled = Boolean(
    projects && articles && [...projects, ...articles].length >= 3
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    form.validateFields((err, values) => {
      const id = initialValues ? initialValues.id : null;
      if (!err) {
        submit(
          {
            ...values,
            blocks: editor.transformBlocks
          },
          id
        );
      }
    });
  };

  const handleIsTilda = useCallback(
    ({ target: { checked } }) => {
      dispatch(actions.setProjectEditorVisibility(!checked));
    },
    [dispatch]
  );

  const onDeleteArticle = () => {
    if (!initialValues) {
      return;
    }

    const { id } = initialValues;

    deleteProject(id);
  };

  const onChangePublishedStatus = () => {
    if (!initialValues) {
      return;
    }

    const { id } = initialValues;

    handlePublish({ id, publishedStatus, setPublishedStatus });
  };

  return {
    handleSubmit,
    disabled,
    onChangePublishedStatus,
    publishedStatus,
    onDeleteArticle,
    handleIsTilda
  };
};

export default usePresenter;
