import { useEffect } from 'react';
import { submit } from './submit';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { FormComponentProps } from 'antd/lib/form/Form';

export interface ICategoryParamsProps
  extends FormComponentProps<ICategoryParamsFormValues> {
  initialValues?: IInitialValues;
}

export interface ICategoryParamsFormValues {
  description: string | null;
  seo_title: string;
  seo_description: string | null;
  slug: string;
  title: string;
  service: number[];
  type: string;
}

interface IInitialValues {
  id: number;
  description: string | null;
  seo: {
    title: string;
    description: string | null;
  };
  slug: string;
  title: string;
  type: string;
  service: number[];
}

interface IPresenterProps {
  initialValues?: IInitialValues;
  form: WrappedFormUtils<ICategoryParamsFormValues>;
}

const usePresenter = ({ initialValues, form }: IPresenterProps) => {
  useEffect(() => {
    if (!initialValues) {
      return;
    }

    form.setFieldsValue(mapperInitialToForm(initialValues));

    // eslint-disable-next-line
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    form.validateFields((err, values) => {
      const id = initialValues ? initialValues.id : null;
      if (!err) {
        submit(values, id);
      }
    });
  };

  return { handleSubmit };
};

const mapperInitialToForm = (
  initialValues: IInitialValues
): ICategoryParamsFormValues => {
  return {
    description: initialValues.description,
    seo_description: initialValues.seo.description,
    seo_title: initialValues.seo.title,
    service: initialValues.service,
    slug: initialValues.slug,
    title: initialValues.title,
    type: initialValues.type
  };
};

export default usePresenter;
