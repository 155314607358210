export const transformBlocksFromBackend = (blocks: any) => {
  const transformBlocks = blocks.map((block: any) => {
    switch (block.type) {
      case 'heading-block': {
        return {
          type: 'header',
          data: {
            text: block.content.content,
            level: block.content.type
          }
        };
      }
      case 'paragraph-block': {
        return {
          type: 'paragraph',
          data: {
            text:
              typeof block.content === 'string'
                ? block.content
                : block.content.content
          }
        };
      }
      case 'slider-block': {
        return {
          type: 'images',
          data: {
            images: block.content.map((item: any) => ({
              content: item.content,
              url: item.url,
              alt: item.alt,
              description: item.description,
              image_id: item.image_id,
              image: {
                id: item.image_id,
                original: item.url
              }
            }))
          }
        };
      }
      case 'image-block': {
        return {
          type: 'images',
          data: {
            images: [
              ...block.content.map((item: any) => ({
                url: item.url,
                alt: item.alt,
                description: item.description,
                image_id: item.image_id,
                image: {
                  id: item.image_id,
                  original: item.url
                }
              }))
            ]
          }
        };
      }
      case 'video-block':
        return {
          type: 'video',
          data: {
            content: {
              url: block.content.url,
              description: block.content.description
            }
          }
        };
      case 'factoid-block':
        return {
          type: 'factoid',
          data: {
            content:
              typeof block.content === 'string'
                ? block.content
                : block.content.content
          }
        };
      case 'anchor-block':
        return {
          type: 'anchor',
          data: {
            content: block.content
          }
        };
      case 'quote-block': {
        return {
          type: 'quote',
          data: {
            ...block.content,
            image: {
              id: block.content.image.content,
              url: block.content.image.url,
              alt: block.content.image.alt
            }
          }
        };
      }
      case 'list-block': {
        return {
          type: 'list',
          data: block.content
        };
      }
      case 'paragraph-with-img-block': {
        return {
          type: 'imageWithText',
          data: {
            text: block.content.text,
            title: block.content.title,
            image: {
              id: block.content.image.content,
              url: block.content.image.url
            },
            alt: block.content.image.alt,
            float: block.params.imageAlign || 'right'
          }
        };
      }
      case 'button-block': {
        return {
          type: 'button',
          data: {
            content: block.content,
            params: block.params
          }
        };
      }
      case 'price-list-block': {
        return {
          type: 'priceList',
          data: {
            content: block.content,
            params: block.params
          }
        };
      }
      case 'tariff-block': {
        return {
          type: 'tariff',
          data: {
            content: block.content,
            params: block.params
          }
        };
      }
      case 'services-block': {
        return {
          type: 'services',
          data: {
            content: block.content,
            params: block.params
          }
        };
      }
      case 'cards-block': {
        return {
          type: 'cards',
          data: {
            content: block.content,
            params: block.params
          }
        };
      }
      case 'cardsNoNumbers-block': {
        return {
          type: 'cardsNoNumbers',
          data: {
            content: block.content,
            params: block.params
          }
        };
      }
      case 'expander-block': {
        return {
          type: 'expander',
          data: {
            content: block.content,
            params: block.params
          }
        };
      }
      default:
        return block;
    }
  });

  return transformBlocks;
};
