import { Component } from 'react';
import { API } from '@editorjs/editorjs';
import { v4 as uuidv4 } from 'uuid';
import { BLOCK_COLOR_NUM } from '../consts';
import { BlockColors } from '../enums';
import { make } from 'plugins/utils/makeElement';

import './index.css';

interface IItem {
  contentList: string;
  price: string;
  title: string;
  under_title: string;
}

interface IPropsData {
  content: IItem[];
  params: {
    color: {
      label: string;
      value: string;
    };
    isHidden: boolean;
  };
}

interface ITariffPluginProps {
  data: IPropsData;
  api: API;
}

class TariffPlugin extends Component<ITariffPluginProps> {
  api: API;

  nodes: {
    wrapper: HTMLDivElement;
    containerGrid: HTMLDivElement;
    card: [];
    buttonAdd: HTMLButtonElement;
    labelBlockColor: HTMLLabelElement;
    selectBlockColor: HTMLSelectElement;
  };

  _data: {
    content: IItem[];
    params: {
      color: {
        label: string;
        value: string;
      };
      isHidden: boolean;
    };
  };

  constructor(props: ITariffPluginProps) {
    super(props);
    this.api = this.props.api;

    this.nodes = {
      wrapper: make('div', this.CSS.wrapper),
      containerGrid: make('div', this.CSS.containerGrid),
      card: [],
      buttonAdd: make('button', this.CSS.buttonAdd),
      labelBlockColor: make('label', this.CSS.labelBlockColor),
      selectBlockColor: make('select', this.CSS.selectBlockColor)
    };

    this._data = {
      content: [],
      params: {
        color: {
          label: 'Белый',
          value: '#ffffff'
        },
        isHidden: false
      }
    };

    this.initNodes();

    this.data = this.props.data;
  }

  initNodes() {
    this.initNodesInnerText();
    this.initSelectsOptions();
    this.initNodesAppend();
  }

  initNodesInnerText() {
    this.nodes.buttonAdd.innerText = '+';
    this.nodes.labelBlockColor.innerText = 'Цвет блока: ';
  }

  initSelectsOptions() {
    for (let i = 0; i < BLOCK_COLOR_NUM; i++) {
      let option = document.createElement('option');
      option.text = BlockColors[i];
      this.nodes.selectBlockColor.add(option);
    }
  }

  initNodesAppend() {
    this.nodes.wrapper.appendChild(this.nodes.labelBlockColor);
    this.nodes.wrapper.appendChild(this.nodes.selectBlockColor);

    this.nodes.wrapper.appendChild(this.nodes.buttonAdd);
    this.nodes.wrapper.appendChild(this.nodes.containerGrid);
  }

  get CSS() {
    return {
      wrapper: 'wrapper',
      containerGrid: 'tariff__items',
      card: 'tariff__item',
      buttonAdd: 'tariff__button',
      itemTitle: 'tariff__item_title',
      itemPrice: 'tariff__item_price',
      itemUnderTitle: 'tariff__item_under_title',
      itemContentList: 'tariff__item_content_list',
      deleteButton: 'tariff__item_delete',
      labelBlockColor: 'tariff__label-block-color',
      selectBlockColor: 'tariff__input-color'
    };
  }

  get data() {
    return this._data;
  }

  set data(data) {
    if (Object.keys(data).length !== 0) {
      this._data = data;
    }

    this.nodes.selectBlockColor.oninput = () => {
      this.nodes.wrapper.style.backgroundColor =
        this.setOutputColorValue() as string;
    };

    this.nodes.wrapper.style.backgroundColor =
      this.setOutputColorValue() as string;

    this.nodes.buttonAdd.addEventListener('click', () => {
      this.addItem();
    });

    for (let i in this.data.content) {
      this.addItem(i);
    }
  }

  addItem(i?: any) {
    if (!i) {
      this.data.content.push({
        contentList: '',
        price: '',
        title: '',
        under_title: ''
      });
    }

    let container = make('div', this.CSS.card);

    let title, price, under_title, contentList;

    if (i) {
      ({ title, price, under_title, contentList } = this.data.content[i]);
    }
    let underTitle = under_title;
    let titleElem = make('input', this.CSS.itemTitle);
    let priceElem = make('input', this.CSS.itemPrice);
    let underTitleElem = make('input', this.CSS.itemUnderTitle);
    let contentListElem = make('div', this.CSS.itemContentList, {
      id: uuidv4()
    });

    titleElem.placeholder = 'Заголовок';
    priceElem.placeholder = 'Цена';
    underTitleElem.placeholder = 'Нижний заголовок';
    contentListElem.placeholder = 'Содержимое';

    let deleteButton = make('button', this.CSS.deleteButton);
    deleteButton.innerText = 'x';

    if (i) {
      titleElem.value = title !== undefined ? title : '';
      priceElem.value = price !== undefined ? price : '';
      underTitleElem.value = underTitle !== undefined ? underTitle : '';
      contentListElem.innerHTML = contentList !== undefined ? contentList : '';
    }

    titleElem.placeholder = 'Заголовок';
    priceElem.placeholder = 'Цена';
    underTitleElem.placeholder = 'Нижний заголовок';
    contentListElem.placeholder = 'Содержимое';

    container.appendChild(deleteButton);
    container.appendChild(titleElem);
    container.appendChild(underTitleElem);
    container.appendChild(priceElem);
    container.appendChild(contentListElem);

    this.nodes.containerGrid.appendChild(container);

    this.itemSetListeners(
      contentListElem,
      deleteButton,
      titleElem,
      priceElem,
      underTitleElem
    );
  }

  itemSetListeners(
    contentListElem: HTMLDivElement,
    deleteButton: HTMLButtonElement,
    titleElem: HTMLInputElement,
    priceElem: HTMLInputElement,
    underTitleElem: HTMLInputElement
  ) {
    contentListElem.addEventListener('click', () => {
      const id = contentListElem.id;
      const customEvent = new CustomEvent('openTinymcePlugginModal', {
        detail: {
          id,
          value: contentListElem.innerHTML
        }
      });
      document.dispatchEvent(customEvent);
    });

    deleteButton.addEventListener('click', (e: Event) => {
      for (let i = 0; i < this.data.content.length; i++) {
        if (
          this.nodes.containerGrid.childNodes[i].childNodes[0] === deleteButton
        ) {
          this.data.content.splice(i, 1);
        }
      }
      deleteButton.parentElement?.remove();
    });

    contentListElem.addEventListener('DOMSubtreeModified', (e: Event) => {
      for (let i = 0; i < this.nodes.containerGrid.childNodes.length; i++) {
        if (
          this.nodes.containerGrid.childNodes[i].childNodes[4] ===
          (e.currentTarget as Node)
        ) {
          this.data.content[i].contentList = (
            this.nodes.containerGrid.childNodes[i]
              .childNodes[4] as HTMLDivElement
          ).innerHTML;
        }
      }
    });

    titleElem.addEventListener('input', (e: Event) => {
      for (let i = 0; i < this.nodes.containerGrid.childNodes.length; i++) {
        if (
          this.nodes.containerGrid.childNodes[i].childNodes[1] ===
          (e.currentTarget as Node)
        ) {
          this.data.content[i].title = (
            this.nodes.containerGrid.childNodes[i]
              .childNodes[1] as HTMLInputElement
          ).value;
        }
      }
    });

    priceElem.addEventListener('input', (e: Event) => {
      for (let i = 0; i < this.nodes.containerGrid.childNodes.length; i++) {
        if (
          this.nodes.containerGrid.childNodes[i].childNodes[3] ===
          (e.currentTarget as Node)
        ) {
          this.data.content[i].price = (
            this.nodes.containerGrid.childNodes[i]
              .childNodes[3] as HTMLInputElement
          ).value;
        }
      }
    });

    underTitleElem.addEventListener('input', (e: Event) => {
      for (let i = 0; i < this.nodes.containerGrid.childNodes.length; i++) {
        if (
          this.nodes.containerGrid.childNodes[i].childNodes[2] ===
          (e.currentTarget as Node)
        ) {
          this.data.content[i].under_title = (
            this.nodes.containerGrid.childNodes[i]
              .childNodes[2] as HTMLInputElement
          ).value;
        }
      }
    });
  }

  setOutputColorValue() {
    if (this.nodes.selectBlockColor.value === 'Белый') {
      return '#ffffff';
    } else if (this.nodes.selectBlockColor.value === 'Серый') {
      return '#f5f5f5';
    }
  }

  save() {
    return {
      content: this.data.content,
      params: {
        color: {
          label: this.nodes.selectBlockColor.value,
          value: this.setOutputColorValue()
        },
        isHidden: false //warning
      }
    };
  }

  render() {
    return this.nodes.wrapper;
  }

  static get toolbox() {
    return {
      icon: '<b>Tar</b>',
      title: 'Тариф'
    };
  }
}

export default TariffPlugin;
