import { useSelector } from 'react-redux';
import { submit } from './submit';

interface IPresenterProps {
  title: string | null;
}

const usePresenter = ({ title }: IPresenterProps) => {
  const editor = useSelector((store: IStore) => store.editor);

  const handleSubmit = () => {
    const blocks = editor.transformBlocks;

    if (title) {
      submit({
        title,
        blocks
      });
    }
  };

  return { handleSubmit };
};

export default usePresenter;
