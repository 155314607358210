import React, { useEffect } from 'react';
import { Button } from 'antd';
import { Editor, Spinner } from 'components';
import { useSelector } from 'react-redux';
import { transformBlocksFromBackend } from 'utils/transformBlocksFromBackend';
import { useDispatch } from 'react-redux';
import { actions } from 'store/about/actions';
import styled from 'styled-components';
import usePresenter from './usePresenter';

const AboutPage: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchAboutRequest());
  }, [dispatch]);

  const { data, loading, error } = useSelector((store: IStore) => store.about);

  const hasContent = Boolean(!error && !loading && data);

  return (
    <>
      {hasContent && <Content data={data} />}
      {loading && <Spinner size="large" />}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

const Content: React.FC<{ data: IAbout | null }> = ({ data }) => {
  const { handleSubmit } = usePresenter({ title: data ? data.title : null });

  if (!data) {
    return null;
  }

  return (
    <Page>
      <EditorWrapper>
        <Editor blocks={transformBlocksFromBackend(data.blocks)} />
      </EditorWrapper>
      <FormSubmitButton>
        <Button type="primary" htmlType="button" onClick={handleSubmit}>
          Обновить
        </Button>
      </FormSubmitButton>
    </Page>
  );
};

const Page = styled.article`
  width: 100%;
`;

const EditorWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 50px;
  background-color: $white;
  box-shadow: 0 0 4px;
`;

const ErrorMessage = styled.div`
  font-size: 40px;
  text-align: center;
`;

const FormSubmitButton = styled.div`
  display: flex;
  position: absolute;
  top: 16px;
  right: 150px;
`;

export default AboutPage;
