import React from 'react';
import { FieldsProvider, ControlButtons } from 'components';
import { Form, Rate, Checkbox, Row, Col, Divider } from 'antd';
import usePresenter, { IArticleParamsProps } from './usePresenter';
import {
  TextField,
  SelectField,
  DateField,
  UploadField,
  TextAreaField,
  SelectableGroupField
} from '../Fields';

const ArticleParams: React.FC<IArticleParamsProps> = ({
  form,
  initialValues,
  isCreateArticle
}) => {
  const {
    handleSubmit,
    disabled,
    onChangePublishedStatus,
    publishedStatus,
    onDeleteArticle
  } = usePresenter({
    initialValues,
    form
  });

  return (
    <Form onSubmit={handleSubmit}>
      <FieldsProvider form={form}>
        <Divider className="form-divider" orientation="left">
          Основная информация
        </Divider>
        <Row type="flex" justify="space-between">
          <Col span={10}>
            <TextAreaField
              form={form}
              name="title"
              label="Заголовок"
              placeholder="Введите заголовок"
              rules={[{ required: true, message: 'укажите заголовок' }]}
            />
          </Col>

          <Col span={13}>
            <TextAreaField
              form={form}
              name="description"
              label="Промо описание"
              placeholder="Введите промо описание"
              rules={[{ required: true, message: 'укажите промо описание' }]}
            />
          </Col>
        </Row>

        <Divider />

        <Row type="flex" justify="space-between" style={{ width: '60%' }}>
          <Col span={6}>
            <Form.Item label="Сложность">
              {form.getFieldDecorator('complexity', {
                rules: [{ required: true, message: 'укажите сложность' }]
              })(<Rate />)}
            </Form.Item>
          </Col>

          <Col span={6}>
            <DateField form={form} name="publishedAt" label="Дата публикации" />
          </Col>

          <Col span={10}>
            <TextField
              form={form}
              name="slug"
              label="Url"
              placeholder="Введите url"
              rules={[{ required: true, message: 'укажите url' }]}
            />
          </Col>
        </Row>

        <Divider />

        <Row type="flex" justify="space-between">
          <Col span={8}>
            <SelectField
              form={form}
              name="categories"
              source="categories/selectable"
              label="Категории"
              placeholder="Выберите категории"
              initialValue={
                initialValues ? initialValues.categories : undefined
              }
            />
          </Col>

          <Col span={6}>
            <SelectField
              form={form}
              name="author"
              source="authors/selectable"
              label="Автор"
              placeholder="Выберите автора"
              mode="default"
              initialValue={initialValues ? initialValues.author : undefined}
            />
          </Col>

          <Col span={8}>
            <SelectField
              form={form}
              name="tags"
              source="tags/selectable"
              label="Метки"
              placeholder="Выберите метки"
              initialValue={initialValues ? initialValues.tags : undefined}
            />
          </Col>
        </Row>

        <Divider className="form-divider" orientation="left">
          SEO
        </Divider>
        <Row type="flex" justify="space-between">
          <Col span={10}>
            <TextAreaField
              form={form}
              name="seoTitle"
              label="Заголовок (SEO)"
              placeholder="Введите заголовок для seo"
            />
          </Col>
          <Col span={13}>
            <TextAreaField
              form={form}
              name="seoDescription"
              label="Описание (SEO)"
              placeholder="Введите описание для seo"
            />
          </Col>
        </Row>

        <Divider className="form-divider" orientation="left">
          Дополнительная информация
        </Divider>
        <Row style={{ width: '50%' }} type="flex" justify="space-between">
          <Col span={4}>
            <UploadField
              form={form}
              name="cover"
              initialValue={initialValues && initialValues.cover}
              label="Обложка"
            />
          </Col>
          <Col span={16}>
            <TextField
              form={form}
              name="alt"
              label="Alt"
              placeholder="Введите промо alt"
              rules={[{ required: true, message: 'укажите alt' }]}
            />
          </Col>
        </Row>
        <Divider />
        <Form.Item>
          {form.getFieldDecorator('interested')(
            <Checkbox
              defaultChecked={initialValues && initialValues.interested}
            >
              Интересное
            </Checkbox>
          )}
        </Form.Item>

        <Divider />
        <SelectableGroupField
          label="Услуги"
          initialValues={initialValues && initialValues.services}
          buttonLabel="Добавить услугу"
          inputProps={{
            placeholder: 'Выберете услугу'
          }}
          name="services"
          rules={[
            {
              required: true,
              message: 'Please input service name or delete this field.'
            }
          ]}
        />

        <SelectableGroupField
          label="Статьи"
          initialValues={initialValues && initialValues.articles}
          disabled={disabled}
          buttonLabel="добавить статью"
          inputProps={{
            placeholder: 'Выберете статью'
          }}
          name="articles"
          rules={[
            {
              required: true,
              message: 'Please input service name or delete this field.'
            }
          ]}
        />

        <SelectableGroupField
          label="Проекты"
          initialValues={initialValues && initialValues.projects}
          disabled={disabled}
          buttonLabel="добавить проект"
          inputProps={{
            placeholder: 'Выберете проект'
          }}
          name="projects"
          rules={[
            {
              required: true,
              message: 'Please input project name or delete this field.'
            }
          ]}
        />
        <Divider className="form-divider" orientation="center">
          Редактор
        </Divider>
      </FieldsProvider>

      <ControlButtons
        handlePublish={onChangePublishedStatus}
        onDeleteArticle={onDeleteArticle}
        publishStatus={publishedStatus}
        isCreate={isCreateArticle}
      />
    </Form>
  );
};

export default Form.create<IArticleParamsProps>({ name: 'articleParams' })(
  ArticleParams
);
