// eslint-disable-next-line
import './styles.css';
import Ui from './ui';
import Tunes from './tunes';
import Uploader from './uploader';
import { IResponse } from './uploader';

export interface IImage {
  id: number;
  url: string;
}

export interface IToolData {
  image: IImage | null;
  alt: string;
  text: string;
  title: string;
  float: 'left' | 'right';
}

export default class ImageTool {
  api: any;
  config: any;
  uploader: any;
  tunes: any;
  ui: any;
  _data: IToolData;

  constructor({ data, config, api }: any) {
    this.api = api;

    document.addEventListener('customClick', () => this.save(), false);

    /**
     * Tool's initial config
     */
    this.config = {
      endpoints: config.endpoints || '',
      additionalRequestData: config.additionalRequestData || {},
      additionalRequestHeaders: config.additionalRequestHeaders || {},
      field: config.field || 'image',
      types: config.types || 'image/*',
      buttonContent: config.buttonContent || '',
      uploader: config.uploader || undefined
    };

    /**
     * Module for file uploading
     */
    this.uploader = new Uploader({
      config: this.config,
      onUpload: (response: any) => this.onUpload(response),
      onError: (error: any) => this.uploadingFailed(error)
    });

    /**
     * Module for working with UI
     */
    this.ui = new Ui({
      api,
      config: this.config,
      onSelectFile: () => {
        this.uploader.uploadSelectedFile({
          onPreview: (src: any) => {
            this.ui.showPreloader(src);
          }
        });
      }
    });

    /**
     * Module for working with tunes
     */
    this.tunes = new Tunes({
      api,
      onChange: (tuneName: any) => this.tuneToggled(tuneName)
    });

    /**
     * Set saved state
     */
    this._data = {
      image: null,
      text: '',
      title: '',
      alt: '',
      float: 'left'
    };
    this.data = data;
  }

  /**
   * Set new image file
   */
  set image(image: IImage | null) {
    this._data.image = image;

    if (image && image.url) {
      this.ui.fillImage(image.url);
    }
  }

  /**
   * File uploading callback
   */
  onUpload(response: IResponse) {
    if (response.success) {
      this.image = response.image;
    } else {
      this.uploadingFailed('incorrect response: ' + JSON.stringify(response));
    }
  }

  /**
   * Handle uploader errors
   */
  uploadingFailed(errorText: any) {
    console.log('Image Tool: uploading failed because of', errorText);

    this.api.notifier.show({
      message: 'Couldn’t upload image. Please try another.',
      style: 'error'
    });
    this.ui.hidePreloader();
  }

  /**
   * Callback fired when Block Tune is activated
   */
  tuneToggled(tuneName: 'right' | 'left' | 'change') {
    if (tuneName === 'change') {
      this.ui.onSelectFile();
      return;
    }

    const { float } = this._data;

    if (float !== tuneName) {
      this._data.float = tuneName;
      this.ui.applyTune(tuneName);
    }
  }

  /**
   * Show preloader and upload image file
   */
  uploadFile(file: any) {
    this.uploader.uploadByFile(file, {
      onPreview: (src: any) => {
        this.ui.showPreloader(src);
      }
    });
  }

  get data() {
    return this._data;
  }

  set data(data) {
    this.image = data.image;

    this._data.text = data.text || '';
    this._data.title = data.title || '';
    this._data.alt = data.alt || '';
    this._data.float = data.float;

    this.ui.fillInputs(data.text, data.title, data.alt);
  }

  /**
   * Return Block data
   */
  save() {
    const textInput = this.ui.nodes.textInput;
    const titleInput = this.ui.nodes.titleInput;
    const altInput = this.ui.nodes.altInput;

    this._data.text = textInput.innerHTML;
    this._data.title = titleInput.innerHTML;
    this._data.alt = altInput.innerHTML;

    return this.data;
  }

  /**
   * Renders Block content
   */
  render() {
    return this.ui.render(this.data);
  }

  /**
   * Makes buttons with tunes: add background, add border, stretch image
   */
  renderSettings() {
    return this.tunes.render(this.data);
  }

  /**
   * Fires after clicks on the Toolbox Image Icon
   * Initiates click on the Select File button
   */
  appendCallback() {
    this.ui.nodes.fileButton.click();
  }

  /**
   * Specify paste substitutes
   */
  static get pasteConfig() {
    return {
      /**
       * Paste HTML into Editor
       */
      tags: ['img'],

      /**
       * Paste URL of image into the Editor
       */
      patterns: {
        image: /https?:\/\/\S+\.(gif|jpe?g|tiff|png)$/i
      },

      /**
       * Drag n drop file from into the Editor
       */
      files: {
        mimeTypes: ['image/*']
      }
    };
  }

  static get toolbox() {
    return {
      icon: 'I',
      title: 'Текст и изображение'
    };
  }

  static get enableLineBreaks() {
    return true;
  }
}
