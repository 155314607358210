import { takeLatest } from 'redux-saga/effects';
import { Http } from 'services/HttpService';
import { actions } from './actions';
import { setEditorblocks } from 'store/editor/actions';
import { transformBlocksFromBackend } from 'utils/transformBlocksFromBackend';
import { notify } from 'utils/notify';

export function* fetchVacancies(
  action: ReturnType<typeof actions.fetchVacanciesRequest>
) {
  try {
    yield Http.get<IVacancies>('vacancies')
      .then(response => {
        window.store.dispatch(actions.fetchVacanciesSuccess(response.data));
        window.store.dispatch(
          setEditorblocks(transformBlocksFromBackend(response.data.blocks))
        );
      })
      .catch((error: { message: string }) => {
        notify.error(error);
        window.store.dispatch(actions.fetchVacanciesError(error.message));
        window.store.dispatch(actions.clearVacanciesData());
        window.store.dispatch(setEditorblocks([]));
      });
  } catch (error) {
    console.error(error);
  }
}

export default function* vacanciesSaga() {
  yield takeLatest('[VACANCIES] FETCH_VACANCIES_REQUEST', fetchVacancies);
}
