import React from 'react';
import { LoginForm } from 'components/Forms';
import styled from 'styled-components';

const LoginPage: React.FC = () => (
  <Page>
    <h1>Авторизация</h1>
    <LoginForm />
  </Page>
);

const Page = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 20px;
  margin-top: 5%;
  box-shadow: 0 0 3px 0;
  background-color: $white;
  width: 500px;
  height: 280px;
`;

export default LoginPage;
