import React from 'react';
import { Spinner } from 'components';
import styled from 'styled-components';
import usePresenter from './usePresenter';
import { RewardParams } from 'components/Forms';

const EditRewardPage: React.FC = () => {
  const { data, loading, error, hasContent } = usePresenter();

  return (
    <>
      {hasContent && <Content data={data} />}
      {loading && <Spinner size="large" />}
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </>
  );
};

const Content: React.FC<{ data: IRewardItem | null }> = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <Page>
      <RewardParams initialValues={data} />
    </Page>
  );
};

const Page = styled.article`
  width: 100%;
`;

const ErrorMessage = styled.div`
  font-size: 40px;
  text-align: center;
`;

export default EditRewardPage;
