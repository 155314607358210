export const actions = {
  fetchTagRequest: (id: string) =>
    ({
      type: '[TAG] FETCH_TAG_REQUEST',
      payload: { id }
    } as const),

  fetchTagSuccess: (reward: ITag) =>
    ({
      type: '[TAG] FETCH_TAG_SUCCESS',
      payload: { reward }
    } as const),

  fetchTagError: (message: string) =>
    ({
      type: '[TAG] FETCH_TAG_ERROR',
      payload: { message }
    } as const),

  clearTagData: () =>
    ({
      type: '[TAG] CLEAR_TAG_DATA'
    } as const)
};
