import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { actions } from 'store/tags/actions';
import { helperRedirect } from 'helpers/redirect';
import { useDispatch, useSelector } from 'react-redux';

interface IDataSourceItem {
  key: number;
  description: string;
  slug: string;
  title: string;
  createdAt: string;
}

const usePresenter = () => {
  const [dataSource, setDataSource] = useState<IDataSourceItem[] | undefined>(
    undefined
  );

  const dispatch = useDispatch();
  const { loading, error, data } = useSelector((store: IStore) => store.tags);

  useEffect(() => {
    dispatch(actions.fetchTagsRequest());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }

    let array: IDataSourceItem[] = [];

    for (let item of data) {
      array.push({
        key: item.id,
        title: item.title,
        description: item.description,
        slug: item.slug,
        createdAt: item.created_at
          ? moment(item.created_at).format('DD.MM.YYYY')
          : ''
      });
    }

    setDataSource(array);
  }, [data]);

  return {
    error,
    columns,
    loading,
    dataSource
  };
};

const columns = [
  {
    title: 'Название',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'Описание',
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: 'Англ.версия',
    dataIndex: 'slug',
    key: 'slug'
  },
  {
    title: 'Дата создания',
    dataIndex: 'createdAt',
    key: 'createdAt'
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    render: (text: string, record: IDataSourceItem) => {
      return (
        <Button
          type="primary"
          onClick={() => helperRedirect(`tags/edit/${record.key}`)}
        >
          Редактировать
        </Button>
      );
    }
  }
];

export default usePresenter;
