import React from 'react';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { actions } from 'store/authors/actions';
import { helperRedirect } from 'helpers/redirect';
import { useDispatch, useSelector } from 'react-redux';

interface IDataSourceItem {
  key: number;
  name: string;
  post: string;
  avatar: string;
  description: string;
}

const usePresenter = () => {
  const [dataSource, setDataSource] = useState<IDataSourceItem[] | undefined>(
    undefined
  );

  const dispatch = useDispatch();
  const { loading, error, data } = useSelector(
    (store: IStore) => store.authors
  );

  useEffect(() => {
    dispatch(actions.fetchAuthorsRequest());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }

    let array: IDataSourceItem[] = [];

    for (let item of data) {
      array.push({
        key: item.id,
        name: item.name,
        post: item.post,
        avatar: item.avatar,
        description: item.description
      });
    }

    setDataSource(array);
  }, [data]);

  return {
    error,
    columns,
    loading,
    dataSource
  };
};

const columns = [
  {
    title: 'Имя',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Должность',
    dataIndex: 'post',
    key: 'post'
  },
  {
    title: 'Аватар',
    dataIndex: 'avatar',
    key: 'avatar'
  },
  {
    title: 'Описание',
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    render: (text: string, record: IDataSourceItem) => {
      return (
        <Button
          type="primary"
          onClick={() => helperRedirect(`authors/edit/${record.key}`)}
        >
          Редактировать
        </Button>
      );
    }
  }
];

export default usePresenter;
