import React from 'react';
import { DatePicker } from 'antd';
import { RangePickerValue } from 'antd/lib/date-picker/interface';

const { RangePicker } = DatePicker;

interface IRangeDatePickerProps {
  onChange: (date: RangePickerValue, dateString: [string, string]) => void;
}

const RangeDatePicker: React.FC<IRangeDatePickerProps> = ({ onChange }) => {
  return <RangePicker onChange={onChange} format="MM/DD/YYYY" />;
};

export default RangeDatePicker;
