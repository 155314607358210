import ajax from '@codexteam/ajax';
import { AxiosResponse } from 'axios';
import { Http } from 'services/HttpService';

export interface IResponse {
  image: {
    id: number;
    url: string;
  };
  success: boolean;
}

export default class Uploader {
  data: any;
  config: any;
  onUpload: any;
  onError: any;

  constructor({ data, config, onUpload, onError }: any) {
    this.data = data;
    this.config = config;
    this.onUpload = onUpload;
    this.onError = onError;
  }

  uploadSelectedFile({ onPreview }: any, index: number) {
    const preparePreview = (img: string, index: number) => {
      onPreview(img, index);
    };

    let upload;

    upload = ajax
      .selectFiles({ accept: this.config.types, multiple: false })
      .then(async (files: File[]) => {
        const formData = new FormData();
        formData.append('image', files[0]);

        return await Http.post('images', formData);
      });
    upload
      .then((response: AxiosResponse<IImage>) => {
        console.log(response);
        const data = {
          image: {
            id: response.data.id,
            url: response.data.medium
          },
          success: true
        };
        this.onUpload(data, index);
        preparePreview(data.image.url, index);
      })
      .catch((error: string) => this.onError(error, index));
  }
}
