// import Embed from '@editorjs/embed'
// import Table from '@editorjs/table'
import List from '@editorjs/list';
// import Warning from '@editorjs/warning'
// import Code from '@editorjs/code'
// import LinkTool from '@editorjs/link'
// import Image from '@editorjs/image'
// import Raw from '@editorjs/raw'
import Header from '@editorjs/header';
// import Quote from '@editorjs/quote'
// import Marker from '@editorjs/marker'
// import CheckList from '@editorjs/checklist'
// import Delimiter from '@editorjs/delimiter'
// import InlineCode from '@editorjs/inline-code'
// import SimpleImage from '@editorjs/simple-image'
// import Paragraph from '@editorjs/paragraph'
import { Http } from 'services/HttpService';
import Images from 'plugins/Images';
import Video from 'plugins/Video';
import Anchor from 'plugins/Anchor';
import Factoid from 'plugins/Factoid';
import Quote from 'plugins/Quote';
import ImageWithText from 'plugins/ImageWithText';
import Button from 'plugins/Button';
import PriceList from 'plugins/PriceList';
import Cards from 'plugins/Cards';
import CardsNoNumbers from 'plugins/CardsNoNumbers';
import Tariff from 'plugins/Tariff';
import Services from 'plugins/Services';
import Expander from 'plugins/Expander';

export const EDITOR_JS_TOOLS = {
  images: {
    class: Images,
    config: {
      endpoints: {
        byFile: 'https://atwinta3.dev.atwinta.ru/api/admin/images'
      },
      uploader: {
        uploadByFile(files: File[]) {
          return Array.from(files).map(async el => {
            const formData = new FormData();
            formData.append('image', el);
            return await Http.post('images', formData);
          });
        }
      }
    }
  },
  imageWithText: {
    class: ImageWithText
  },
  video: Video,
  header: Header,
  anchor: Anchor,
  quote: {
    class: Quote,
    inlineToolbar: true
  },
  factoid: {
    class: Factoid,
    inlineToolbar: true
  },
  list: {
    class: List,
    inlineToolbar: true
  },
  button: Button,
  cards: Cards,
  cardsNoNumbers: CardsNoNumbers,
  priceList: PriceList,
  tariff: Tariff,
  services: Services,
  expander: Expander
};
