import React from 'react';
import moment from 'moment';
import { Button, Icon } from 'antd';
import { useEffect, useState } from 'react';
import { actions } from 'store/rewards/actions';
import { helperRedirect } from 'helpers/redirect';
import { useDispatch, useSelector } from 'react-redux';

interface IDataSourceItem {
  createdAt: string;
  description: string;
  key: number;
  level: string;
  project: string;
  reward: string;
  year: number;
  favorite: boolean;
}

const usePresenter = () => {
  const [dataSource, setDataSource] = useState<IDataSourceItem[] | undefined>(
    undefined
  );

  const dispatch = useDispatch();
  const { loading, error, data } = useSelector(
    (store: IStore) => store.rewards
  );

  useEffect(() => {
    dispatch(actions.fetchRewardsRequest());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }

    let array: IDataSourceItem[] = [];

    for (let item of data) {
      array.push({
        key: item.id,
        year: item.year,
        favorite: item.favorite,
        level: item.levelLabel,
        reward: item.typeTitle,
        project: item.projectName,
        description: item.description,
        createdAt: moment(item.createdAt).format('DD.MM.YYYY')
      });
    }

    setDataSource(array);
  }, [data]);

  return {
    columns,
    dataSource,
    loading,
    error
  };
};

const columns = [
  {
    title: 'Награда',
    dataIndex: 'reward',
    key: 'reward'
  },
  {
    title: 'На главной',
    dataIndex: 'favorite',
    key: 'favorite',
    render: (text: string, record: IDataSourceItem) => {
      if (record.favorite) {
        return (
          <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
        );
      }
    }
  },
  {
    title: 'Проект',
    dataIndex: 'project',
    key: 'project'
  },
  {
    title: 'Год',
    dataIndex: 'year',
    key: 'year'
  },
  {
    title: 'Уровень',
    dataIndex: 'level',
    key: 'level'
  },
  {
    title: 'Описание',
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: 'Дата создания',
    dataIndex: 'createdAt',
    key: 'createdAt'
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    render: (text: string, record: IDataSourceItem) => {
      return (
        <Button
          type="primary"
          onClick={() => helperRedirect(`rewards/edit/${record.key}`)}
        >
          Редактировать
        </Button>
      );
    }
  }
];

export default usePresenter;
