import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, Layout, Button } from 'antd';
import { actions } from 'store/layout/actions';
import { userLogout } from 'store/user/actions';
import { helperRedirect } from 'helpers/redirect';
import { Popconfirm } from 'antd';
import styled from 'styled-components';

const { Header: AntdHeader } = Layout;

const Header: React.FC = () => {
  const collapsed = useSelector(
    (store: IStore) => store.layout.sidebar.collapsed
  );
  const dispatch = useDispatch();

  const toggleCollapsed = useCallback(() => {
    dispatch(actions.handleSidebarCollapsed());
  }, [dispatch]);

  const onLogoutButtonClick = useCallback(() => {
    dispatch(userLogout());
    helperRedirect('/login');
    window.localStorage.removeItem('token');
  }, [dispatch]);

  return (
    <AntdHeader
      style={{
        background: '#fff',
        padding: '0 24px',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Icon
        style={{
          fontSize: '18px',
          cursor: 'pointer',
          transition: 'color 0.3s'
        }}
        type={collapsed ? 'menu-unfold' : 'menu-fold'}
        onClick={toggleCollapsed}
      />
      <Popconfirm
        title="Уверены что хотите выйти?"
        okText="Выйти"
        cancelText="Остаться"
        placement="left"
        onConfirm={onLogoutButtonClick}
      >
        <A href="#">
          <Button
            htmlType="button"
            icon="logout"
            style={{ marginLeft: 'auto' }}
          >
            Выход
          </Button>
        </A>
      </Popconfirm>
    </AntdHeader>
  );
};

const A = styled.a`
  margin-left: auto;
`;

export default Header;
