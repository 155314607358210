import { takeLatest } from 'redux-saga/effects';
import { Http } from 'services/HttpService';
import { actions } from './actions';
import { notify } from 'utils/notify';

export function* fetchAuthor(
  action: ReturnType<typeof actions.fetchAuthorRequest>
) {
  try {
    yield Http.get<IAuthor>(`authors/${action.payload.id}`)
      .then(response => {
        window.store.dispatch(actions.fetchAuthorSuccess(response.data));
      })
      .catch((error: { message: string }) => {
        notify.error(error);
        window.store.dispatch(actions.fetchAuthorError(error.message));
        window.store.dispatch(actions.clearAuthorData());
      });
  } catch (error) {
    console.error(error);
  }
}

export default function* authorSaga() {
  yield takeLatest('[AUTHOR] FETCH_AUTHOR_REQUEST', fetchAuthor);
}
