import { Http } from 'services/HttpService';
// import { helperRedirect } from 'helpers/redirect'
import { IRewardsParamsFormValues } from './usePresenter';
import { notify } from 'utils/notify';

interface ISubmitValues extends IRewardsParamsFormValues {
  description: string;
  favorite: boolean;
  level: number;
  projectId: number;
  typeId: number;
  year: number;
}

export function submit(values: ISubmitValues, id: number | null) {
  const data = { ...values };
  if (id) {
    Http.patch(`rewards/${id}`, { ...data })
      .then(response => {
        if (response.status === 200) {
          notify.success('Награда успешно изменена');
        }
      })
      .catch(notify.error);
  } else {
    Http.post('rewards', { ...data })
      .then(response => {
        if (response.status === 200) {
          notify.success('Вы создали награду');
        }
      })
      .catch(notify.error);
  }
}
