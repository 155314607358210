export const actions = {
  fetchServicesRequest: (query: string) =>
    ({
      type: '[SERVICES] FETCH_SERVICES_REQUEST',
      payload: { query }
    } as const),

  fetchServicesSuccess: (article: Array<IServiceItem>) =>
    ({
      type: '[SERVICES] FETCH_SERVICES_SUCCESS',
      payload: article
    } as const),

  fetchServicesError: (message: string) =>
    ({
      type: '[SERVICES] FETCH_SERVICES_ERROR',
      payload: message
    } as const),

  clearServicesData: () =>
    ({
      type: '[SERVICES] CLEAR_SERVICES_DATA'
    } as const)
};
