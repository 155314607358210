import React from 'react';
import { Routes } from 'components';
import { useCheckUser } from 'hooks';
import { history } from 'store/configureStore';
import { Router } from 'react-router';

import 'antd/dist/antd.css';
import './App.css';

const App: React.FC = () => {
  useCheckUser();

  return (
    <Router history={history}>
      <Routes />
    </Router>
  );
};

export default App;
