import React from 'react';
import { Form, Button, Row, Col } from 'antd';
import { FieldsProvider } from 'components';
import styled from 'styled-components';
import { SelectField, TextAreaField, TinyMceField } from '../Fields';
import usePresenter, { ITagParamsProps } from './usePresenter';

const TagParams: React.FC<ITagParamsProps> = ({ form, initialValues }) => {
  const { handleSubmit } = usePresenter({ initialValues, form });

  return (
    <Form onSubmit={handleSubmit}>
      <FieldsProvider form={form}>
        <Row type="flex" justify="space-between" style={{ width: '70%' }}>
          <Col span={6}>
            <TextAreaField
              form={form}
              name="title"
              label="Название"
              placeholder="Введите название"
              rules={[{ required: true, message: 'Введите название' }]}
            />
          </Col>

          <Col span={10}>
            <TextAreaField
              form={form}
              label="Oписание"
              name="description"
              placeholder="Введите описание"
              rules={[{ required: true, message: 'Введите описание' }]}
            />
          </Col>

          <Col span={6}>
            <TextAreaField
              form={form}
              name="slug"
              label="Англ.версия"
              placeholder="Введите название для английской версии"
              rules={[
                {
                  required: true,
                  message: 'Введите название для английской версии'
                }
              ]}
            />
          </Col>
        </Row>

        <SelectField
          label="Услуга"
          mode="default"
          name="service"
          source="services/selectable"
          placeholder="Выберите услугу"
          initialValue={initialValues ? initialValues.service : undefined}
        />

        <TinyMceField
          label="Текст"
          name="text"
          placeholder="Введите текст"
          height={300}
          rules={[{ required: true, message: 'Введите текст' }]}
        />
      </FieldsProvider>

      <FormSubmitButton>
        <Button type="primary" htmlType="submit" size="large">
          Опубликовать
        </Button>
      </FormSubmitButton>
    </Form>
  );
};

const FormSubmitButton = styled(Form.Item)`
  display: flex;
  position: absolute;
  top: 12px;
  right: 150px;
`;

export default Form.create<ITagParamsProps>({ name: 'TagParams' })(TagParams);
