import { Component } from 'react';
import Ui from './ui';
import { toolboxIcon } from './icons/toolbox';
import Uploader from './uploader';
import { API } from '@editorjs/editorjs';

interface IOnUploadResponse {
  images: IImage[];
  success: number;
}

class ImagesPlugin extends Component<any, any> {
  api: API;
  config: any;
  uploader: any;
  ui: any;
  _data: IData;

  constructor({ data, config, api, ...props }: any) {
    super(props);
    this.api = api;

    this.config = {
      endpoints: config.endpoints || '',
      additionalRequestData: config.additionalRequestData || {},
      additionalRequestHeaders: config.additionalRequestHeaders || {},
      field: config.field || 'image',
      types: config.types || 'image/*',
      buttonContent: config.buttonContent || '',
      uploader: config.uploader || undefined
    };

    this.uploader = new Uploader({
      data: this.data,
      config: this.config,
      onUpload: (response: any) => this.onUpload(response),
      onError: (response: any) => this.uploadingFailed(response)
    });

    this.ui = new Ui({
      api,
      getImages: () => this.getImages(),
      config: this.config,
      onSelectFile: () => {
        this.uploader.uploadSelectedFile({
          onPreview: (src: string) => {
            this.ui.showPreview(src);
          }
        });
      },
      deleteImageFromData: (imageId: number) =>
        this.deleteImageFromData(imageId),
      handleChangeAltValue: (event: Event, imageID: number) =>
        this.handleChangeAltValue(event, imageID),
      handleChangeDescriptionValue: (event: Event, imageID: number) =>
        this.handleChangeDescriptionValue(event, imageID)
    });

    this._data = {
      images: []
    };
    this.data = data;
  }

  getImages = (): IEditorImage[] => {
    return this._data.images;
  };

  deleteImageFromData = (imageId: number) => {
    this._data.images = this.data.images.filter(r => r.image_id !== imageId);
  };

  handleChangeAltValue = (
    event: Event | React.ChangeEvent<HTMLInputElement>,
    imageID: number
  ) => {
    const { value } = (event as React.ChangeEvent<HTMLInputElement>)
      .currentTarget;
    const changedElement = this.data.images.findIndex(
      element => element.image_id === imageID
    );
    this._data.images[changedElement].alt = value;
  };

  handleChangeDescriptionValue = (
    event: Event | React.ChangeEvent<HTMLTextAreaElement>,
    imageID: number
  ) => {
    const { value } = (event as React.ChangeEvent<HTMLTextAreaElement>)
      .currentTarget;

    const changedElement = this.data.images.findIndex(
      element => element.image_id === imageID
    );
    this._data.images[changedElement].description = value;
  };

  appendCallback() {
    this.ui.nodes.fileButton.click();
  }

  async onPaste(event: any) {
    switch (event.type) {
      case 'tag':
        const image = event.detail.data;

        /** Images from PDF */
        if (/^blob:/.test(image.src)) {
          const response = await fetch(image.src);
          const file = await response.blob();

          this.uploadFile(file);
          break;
        }

        this.uploadUrl(image.src);
        break;

      case 'pattern':
        const url = event.detail.data;

        this.uploadUrl(url);
        break;

      case 'file':
        const file = event.detail.file;

        this.uploadFile(file);
        break;
    }
  }

  get data() {
    return this._data;
  }

  set data(data) {
    this._data.images = data.images || [];

    if (!data.images) {
      return;
    }

    data.images.forEach(img => this.ui.showPreview(img.image));
  }

  save() {
    this.uploader = new Uploader({
      data: this.data,
      config: this.config,
      onUpload: (response: any) => this.onUpload(response),
      onError: (response: any) => this.uploadingFailed(response)
    });

    return this.data;
  }

  addImages = (images: IImage[]) => {
    const newImages = images.map(img => ({
      image_id: img.id,
      url: null,
      alt: '',
      image: { ...img },
      description: ''
    }));
    this._data.images = [...newImages, ...this.data.images] || [];
  };

  onUpload(response: IOnUploadResponse) {
    if (response.success && response.images) {
      this.addImages(response.images);
    } else {
      this.uploadingFailed('incorrect response: ' + JSON.stringify(response));
    }
  }

  uploadingFailed(errorText: string) {
    console.log('Image Tool: uploading failed because of', errorText);

    this.api.notifier.show({
      message: 'Can not upload an image, try another',
      style: 'error'
    });
    this.ui.hidePreview();
  }

  uploadFile(file: Blob) {
    this.uploader.uploadByFile(file, {
      onPreview: (src: string) => {
        this.ui.showPreview(src);
      }
    });
  }

  uploadUrl(url: string) {
    this.ui.showPreview(url);
    this.uploader.uploadByUrl(url);
  }

  render() {
    return this.ui.render(this.data);
  }

  static get pasteConfig() {
    return {
      tags: ['img'],

      patterns: {
        image: /https?:\/\/\S+\.(gif|jpe?g|tiff|png)$/i
      },

      files: {
        mimeTypes: ['image/*']
      }
    };
  }

  static get toolbox() {
    return {
      icon: toolboxIcon,
      title: 'Галерея изображений'
    };
  }
}

export default ImagesPlugin;
