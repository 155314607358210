import { takeLatest } from 'redux-saga/effects';
import { Http } from 'services/HttpService';
import { actions } from './actions';
import { setEditorblocks } from 'store/editor/actions';
import { transformBlocksFromBackend } from 'utils/transformBlocksFromBackend';
import { notify } from 'utils/notify';

export function* fetchArticle(
  action: ReturnType<typeof actions.fetchArticleRequest>
) {
  try {
    yield Http.get<IArticle>(`articles/${action.payload}`)
      .then(response => {
        window.store.dispatch(actions.fetchArticleSuccess(response.data));
        window.store.dispatch(
          setEditorblocks(
            transformBlocksFromBackend(response.data.article.blocks)
          )
        );
      })
      .catch((error: { message: string }) => {
        notify.error(error);
        window.store.dispatch(actions.fetchArticleError(error.message));
        window.store.dispatch(actions.clearArticleData());
        window.store.dispatch(setEditorblocks([]));
      });
  } catch (error) {
    console.error(error);
  }
}

export default function* articleSaga() {
  yield takeLatest('[ARTICLE] FETCH_ARTICLE_REQUEST', fetchArticle);
}
