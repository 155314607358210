import React, { useCallback } from 'react';
import { helperRedirect } from 'helpers/redirect';
import { Card, Icon } from 'antd';
import styled from 'styled-components';
import styles from 'styles';
import { Tr } from 'components';
import block from 'bem-cn';

import './styles.scss';

const si = block('services-item');

interface IServicesItemProps {
  data: IServiceItem;
}

const { Meta } = Card;

const ServicesItem: React.FC<IServicesItemProps> = ({ data }) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };

  const createdDate = new Date(data.created_at);
  const createdDateFormatted = createdDate.toLocaleDateString('ru-RU', options);

  const handleClick = useCallback(() => {
    helperRedirect(`services/edit/${data.id}`);
  }, [data.id]);

  const getDescription = () => {
    const { description } = data;

    if (description.length >= 50) {
      return `${description.slice(0, 50)}...`;
    } else {
      return description;
    }
  };

  return (
    <Wrapper onClick={handleClick}>
      <StyledCard
        title={data.title}
        actions={[<Icon type="edit" key="edit" />]}
      >
        <Meta
          description={
            <table className={si('table')}>
              <tbody>
                <Tr
                  label="Описание"
                  leftClass={si('cell', { left: true })}
                  rightClass={si('cell')}
                  text={getDescription()}
                />
                <Tr
                  label="Категории"
                  leftClass={si('cell', { left: true })}
                  rightClass={si('cell')}
                  text={data.categories.map(item => item.title).join(', ')}
                />
                <Tr
                  label="Дата создания"
                  leftClass={si('cell', { left: true })}
                  rightClass={si('cell')}
                  text={createdDateFormatted}
                />
              </tbody>
            </table>
          }
        />
      </StyledCard>
    </Wrapper>
  );
};

interface IStyledCardProps {
  children: React.ReactNode;
  title: string;
  actions: Array<React.ReactNode>;
}

const Wrapper = styled.div`
  padding: 10px;
  cursor: pointer;

  ${styles.media.tablet(`
    width: 100%;
  `)}

  ${styles.media.laptop(`
    width: 45%;
  `)}

  ${styles.media.desktop(`
    width: 33.33333333%;
  `)}
`;

const StyledCard = styled(({ children, ...rest }: IStyledCardProps) => (
  <Card {...rest}>{children}</Card>
))`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  font-size: 18px;
  height: 257px;
  width: 100%;
`;
export default ServicesItem;
