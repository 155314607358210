import moment from 'moment';
import { omit } from 'lodash';
import { Http } from 'services/HttpService';
import { helperRedirect } from 'helpers/redirect';
import { IProjectParamsFormValues } from './usePresenter';
import { notify } from 'utils/notify';

interface ISubmitValues extends IProjectParamsFormValues {
  blocks: any;
}

export function submit(values: ISubmitValues, id: number | null) {
  const data = {
    ...omit({ ...values }, [
      'seoDescription',
      'seoTitle',
      'articlesKeys',
      'projectsKeys',
      'servicesKeys',
      'publishedAt'
    ]),
    publishedAt: values.publishedAt
      ? moment(values.publishedAt).format('DD.MM.YYYY')
      : null,
    atricles: values.articles ? values.articles : [],
    projects: values.projects ? values.projects : [],
    seo: {
      title: values.seoTitle,
      description: values.seoDescription
    },
    blocks: values.blocks
  };

  if (id) {
    Http.patch(`projects/${id}`, { ...data })
      .then(response => {
        if (response.status === 200) {
          notify.success('Проект успешно изменен');
        }
      })
      .catch(notify.error);
  } else {
    Http.post('projects', { ...data })
      .then(response => {
        if (response.status === 200) {
          notify.success('Вы создали проект');
          helperRedirect(`edit/${response.data.id}`);
        }
      })
      .catch(notify.error);
  }
}
