import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from 'store/authors/actions';

const usePresenter = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.clearAuthorsData());
    // eslint-disable-next-line
  }, []);
};

export default usePresenter;
