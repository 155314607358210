import { takeLatest } from 'redux-saga/effects';
import { Http } from 'services/HttpService';
import {
  FETCH_USER_REQUEST,
  IFetchUserRequest,
  fetchUserSuccess,
  fetchUserError
} from 'store/user/actions';
import { helperRedirect } from 'helpers/redirect';

import { notify } from 'utils/notify';

interface IResponse {
  access_token: string;
  token_type: string;
  expires_at: string;
}

export function* fetchUser(action: IFetchUserRequest) {
  try {
    yield Http.post<IResponse>('login', {
      name: action.payload.name,
      password: action.payload.password
    })
      .then(response => {
        window.store.dispatch(fetchUserSuccess());
        window.localStorage.setItem('token', response.data.access_token);
        helperRedirect('/');
      })
      .catch((error: { message: string }) => {
        notify.error(error);
        window.store.dispatch(fetchUserError(error.message));
      });
  } catch (error) {
    console.error(error);
  }
}

export default function* userSaga() {
  yield takeLatest(FETCH_USER_REQUEST, fetchUser);
}
