import { rootSaga } from 'store/rootSaga';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';

import { reducer as tagReducer } from 'store/tag/reducer';
import { reducer as userReducer } from 'store/user/reducer';
import { reducer as tagsReducer } from 'store/tags/reducer';
import { reducer as rewardReducer } from 'store/reward/reducer';
import { reducer as layoutReducer } from 'store/layout/reducer';
import { reducer as editorReducer } from 'store/editor/reducer';
import { reducer as authorReducer } from 'store/author/reducer';
import { reducer as rewardsReducer } from 'store/rewards/reducer';
import { reducer as articleReducer } from 'store/article/reducer';
import { reducer as serviceReducer } from 'store/service/reducer';
import { reducer as projectReducer } from 'store/project/reducer';
import { reducer as authorsReducer } from 'store/authors/reducer';
import { reducer as articlesReducer } from 'store/articles/reducer';
import { reducer as servicesReducer } from 'store/services/reducer';
import { reducer as projectsReducer } from 'store/projects/reducer';
import { reducer as categoryReducer } from 'store/category/reducer';
import { reducer as paginationReducer } from 'store/pagination/reducer';
import { reducer as categoriesReducer } from 'store/categories/reducer';
import { reducer as vacanciesReducer } from 'store/vacancies/reducer';
import { reducer as aboutReducer } from 'store/about/reducer';

import { combineReducers, createStore, applyMiddleware, compose } from 'redux';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const reducers = combineReducers({
  tag: tagReducer,
  user: userReducer,
  tags: tagsReducer,
  layout: layoutReducer,
  editor: editorReducer,
  reward: rewardReducer,
  author: authorReducer,
  authors: authorsReducer,
  rewards: rewardsReducer,
  article: articleReducer,
  service: serviceReducer,
  project: projectReducer,
  articles: articlesReducer,
  services: servicesReducer,
  projects: projectsReducer,
  category: categoryReducer,
  pagination: paginationReducer,
  categories: categoriesReducer,
  vacancies: vacanciesReducer,
  about: aboutReducer
});

export const store = createStore(
  reducers,
  compose(
    applyMiddleware(sagaMiddleware),
    typeof window !== 'undefined' &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
      : (f: unknown) => f
  )
);

sagaMiddleware.run(rootSaga);

window.store = store;

type PropertiesTypes<T> = T extends { [key: string]: infer U } ? U : never;
export type InferActionsTypes<
  T extends { [key: string]: (...args: any[]) => any }
> = ReturnType<PropertiesTypes<T>>;
