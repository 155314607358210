import { takeLatest } from 'redux-saga/effects';
import { Http } from 'services/HttpService';
import { actions } from './actions';
import { setEditorblocks } from 'store/editor/actions';
import { transformBlocksFromBackend } from 'utils/transformBlocksFromBackend';
import { notify } from 'utils/notify';

export function* fetchAbout(
  action: ReturnType<typeof actions.fetchAboutRequest>
) {
  try {
    yield Http.get<IAbout>('about')
      .then(response => {
        window.store.dispatch(actions.fetchAboutSuccess(response.data));
        window.store.dispatch(
          setEditorblocks(transformBlocksFromBackend(response.data.blocks))
        );
      })
      .catch((error: { message: string }) => {
        notify.error(error);
        window.store.dispatch(actions.fetchAboutError(error.message));
        window.store.dispatch(actions.clearAboutData());
        window.store.dispatch(setEditorblocks([]));
      });
  } catch (error) {
    console.error(error);
  }
}

export default function* aboutSaga() {
  yield takeLatest('[ABOUT] FETCH_ABOUT_REQUEST', fetchAbout);
}
