export enum Aligns {
  'left',
  'center',
  'right'
}

export enum BlockColors {
  'Белый',
  'Серый'
}

export enum NumOfCols {
  'две',
  'три'
}
