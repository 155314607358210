import { Form } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import React, { useState, useEffect } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { WithPassedFormProps } from 'enhancers/withPassedFormProps';

interface ITinyMceFieldProps {
  name: string;
  rules?: any[];
  label?: string;
  placeholder?: string;
  initialValue?: string;
  form?: WrappedFormUtils;
  height?: number;
}

const TinyMceField: React.FC<ITinyMceFieldProps & IFormProps> = ({
  form: { getFieldDecorator },
  rules,
  initialValue,
  label,
  placeholder,
  name,
  height
}) => {
  const [dvalue, setDvalue] = useState<string>();

  useEffect(() => {
    setDvalue(initialValue);
    // eslint-disable-next-line
  }, []);

  const onChange = (value: string) => {
    setDvalue(value);
  };

  return (
    <Form.Item label={label} style={{ width: '100%' }}>
      {getFieldDecorator(name, {
        rules,
        initialValue,
        getValueFromEvent: () => dvalue
      })(
        <Editor
          apiKey="342yoycfjlwj57h6m7xsjl73lgyt6n9ln0dyrat37uk8nvhh"
          init={{
            placeholder: placeholder,
            height: height || 500,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar: `undo redo | formatselect | bold italic backcolor | \
                 alignleft aligncenter alignright alignjustify | \
                 bullist numlist outdent indent | removeformat | help`
          }}
          onEditorChange={onChange}
        />
      )}
    </Form.Item>
  );
};
export default WithPassedFormProps(TinyMceField);
