interface IAttributes {
  [key: string]: string | number | boolean | { [key: string]: string };
}

export const make = function make(
  tagName: any,
  classNames: any = null,
  attributes: IAttributes = {}
) {
  let el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (let attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};
