import { takeLatest } from 'redux-saga/effects';
import { Http } from 'services/HttpService';
import { actions } from './actions';
import { notify } from 'utils/notify';

export function* fetchTag(action: ReturnType<typeof actions.fetchTagRequest>) {
  try {
    yield Http.get<ITag>(`tags/${action.payload.id}`)
      .then(response => {
        window.store.dispatch(actions.fetchTagSuccess(response.data));
      })
      .catch((error: { message: string }) => {
        notify.error(error);
        window.store.dispatch(actions.fetchTagError(error.message));
        window.store.dispatch(actions.clearTagData());
      });
  } catch (error) {
    console.error(error);
  }
}

export default function* tagSaga() {
  yield takeLatest('[TAG] FETCH_TAG_REQUEST', fetchTag);
}
