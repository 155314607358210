import 'date-fns';
import React from 'react';
import { Form } from 'antd';
import moment from 'moment';
import { DatePicker } from 'antd';
import { WithPassedFormProps } from 'enhancers/withPassedFormProps';
import { WrappedFormUtils } from 'antd/lib/form/Form';

interface IDateFieldProps {
  name: string;
  rules?: any[];
  label?: string;
  placeholder?: string;
  initialValue?: string;
  form?: WrappedFormUtils;
}

const DateField: React.FC<IDateFieldProps & IFormProps> = ({
  form: { getFieldDecorator },
  rules,
  initialValue,
  label,
  placeholder,
  name
}) => {
  return (
    <Form.Item label={label}>
      {getFieldDecorator(name, {
        rules,
        initialValue: initialValue || moment()
      })(<DatePicker placeholder={placeholder} format="DD.MM.YYYY" />)}
    </Form.Item>
  );
};

export default WithPassedFormProps(DateField);
