import { takeLatest } from 'redux-saga/effects';
import { Http } from 'services/HttpService';
import { actions } from './actions';
import { notify } from 'utils/notify';

export function* fetchAuthors() {
  try {
    yield Http.get<IWithPagination<IAuthor[]>>('authors')
      .then(response => {
        window.store.dispatch(actions.fetchAuthorsSuccess(response.data.data));
      })
      .catch((error: { message: string }) => {
        notify.error(error);
        window.store.dispatch(actions.fetchAuthorsError(error.message));
        window.store.dispatch(actions.clearAuthorsData());
      });
  } catch (error) {
    console.error(error);
  }
}

export default function* authorsSaga() {
  yield takeLatest('[AUTHORS] FETCH_AUTHORS_REQUEST', fetchAuthors);
}
