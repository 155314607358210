import { createGlobalStyle } from 'styled-components';
import media from './media';

const styles = {
  media
};

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: sans-serif;
  }

  .form-divider {
    font-size: 22px !important;
    text-transform: uppercase;
    font-weight: 200 !important;
    color: #1890ff !important;
  }
`;

export default styles;
