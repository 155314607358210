import { useEffect, useCallback, useState } from 'react';
import { useDebounce } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store/articles/actions';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

const usePresenter = () => {
  // state ------------------------------------------------------------------------------------------------>

  const [term, setTerm] = useState<string>('');
  const [daterange, setDaterange] = useState<string>('');
  const [publication, setPublication] = useState<string>('');

  // hooks ------------------------------------------------------------------------------------------------>

  const debouncedTerm = useDebounce(term, 500);
  const pagination = useSelector((store: IStore) => store.pagination);
  const collapsed = useSelector(
    (store: IStore) => store.layout.articles.searchCollapsed
  );
  const dispatch = useDispatch();

  // callbacks -------------------------------------------------------------------------------------------->

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setTerm(value);

      if (!value.length) {
        dispatch(
          actions.fetchArticlesRequest(`page=${pagination.currentPage}`)
        );
      }
    },
    [dispatch, pagination.currentPage]
  );

  const onCheckboxChange = (checkedValues: Array<CheckboxValueType>) => {
    setPublication(checkedValues.join(''));
  };

  const onDateChange = (
    date: RangePickerValue,
    dateString: [string, string]
  ) => {
    setDaterange(`daterange=${dateString.join(' - ')}`);
  };

  const onSubmit = useCallback(() => {
    const query = `${daterange}${publication}`;
    query && dispatch(actions.fetchArticlesRequest(query));
  }, [daterange, publication, dispatch]);

  const getAllArticles = useCallback(() => {
    dispatch(actions.fetchArticlesRequest('page=1'));
  }, [dispatch]);

  // lifecycle ---------------------------------------------------------------------------------------------->

  useEffect(() => {
    if (debouncedTerm) {
      dispatch(actions.fetchArticlesRequest(`search=${debouncedTerm}`));
    }
  }, [debouncedTerm, dispatch]);

  return {
    onInputChange,
    collapsed,
    onCheckboxChange,
    onDateChange,
    onSubmit,
    getAllArticles
  };
};

export default usePresenter;
