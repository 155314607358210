import React from 'react';
import styled from 'styled-components';
import usePresenter from './usePresenter';
import { CategoryParams } from 'components/Forms';

const CreateCategoryPage: React.FC = () => {
  usePresenter();

  return (
    <Page>
      <CategoryParams />
    </Page>
  );
};

const Page = styled.article`
  width: 100%;
`;

export default CreateCategoryPage;
