import React from 'react';
import { RewardParams } from 'components/Forms';
import styled from 'styled-components';
import usePresenter from './usePresenter';

const CreateRewardPage: React.FC = () => {
  usePresenter();

  return (
    <Page>
      <RewardParams />
    </Page>
  );
};

const Page = styled.article`
  width: 100%;
`;

export default CreateRewardPage;
