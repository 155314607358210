import React, { useEffect } from 'react';
import { ProjectParams } from 'components/Forms';
import Editor from 'components/Editor';
import { useDispatch, useSelector } from 'react-redux';
import { setEditorblocks } from 'store/editor/actions';
import { actions } from 'store/project/actions';
import styled from 'styled-components';

const CreateProjectPage: React.FC = () => {
  const { showEditor } = useSelector((store: IStore) => store.layout.projects);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setEditorblocks([]));
    dispatch(actions.clearProjectData());
    // eslint-disable-next-line
  }, []);

  return (
    <Page>
      <ProjectParams isCreateProject />
      {showEditor && (
        <EditorWrapper>
          <Editor />
        </EditorWrapper>
      )}
    </Page>
  );
};

const Page = styled.article`
  width: 100%;
`;

const EditorWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 50px;
  background-color: $white;
  box-shadow: 0 0 4px;
`;

export default CreateProjectPage;
