import { takeLatest } from 'redux-saga/effects';
import { Http } from 'services/HttpService';
import { actions } from './actions';
import { notify } from 'utils/notify';

type ResponseType = Array<{ label: string; value: string }>;

export function* fetchCategories(
  action: ReturnType<typeof actions.fetchCategoriesRequest>
) {
  try {
    yield Http.get<ResponseType>(
      `categories/search?type=${action.payload.query}`
    )
      .then(response => {
        window.store.dispatch(actions.fetchCategoriesSuccess(response.data));
      })
      .catch((error: { message: string }) => {
        notify.error(error);
        window.store.dispatch(actions.fetchCategoriesError(error.message));
        window.store.dispatch(actions.clearCategoriesData());
      });
  } catch (error) {
    console.error(error);
  }
}

export default function* categoriesSaga() {
  yield takeLatest('[CATEGORIES] FETCH_CATEGORIES_REQUEST', fetchCategories);
}
