import React, { Component } from 'react';
import EditorJs from '@stfy/react-editor.js';
import { EDITOR_JS_TOOLS } from './plugins';
import { connect } from 'react-redux';
import { setEditorblocks, ISetEditorBlocks } from 'store/editor/actions';
import styled from 'styled-components';
import './styles.css';
import { TinyMceModal } from 'components/modals';

interface IEditorProps {
  setEditorblocks: (blocks: TEditorBlocks) => ISetEditorBlocks;
  blocks: any;
}

class Editor extends Component<IEditorProps, {}> {
  static defaultProps = {
    blocks: []
  };

  render() {
    return (
      <Wrapper>
        <EditorJs
          tools={EDITOR_JS_TOOLS as any}
          onData={(data: any) => {
            this.props.setEditorblocks(data.blocks);
          }}
          data={{
            time: 1556098174501,
            blocks: this.props.blocks,
            version: '2.12.4'
          }}
        />
        <TinyMceModal />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  height: calc(100vh - 116px);
  overflow: scroll;
  overflow-x: hidden;
  padding-top: 75px;
  margin-bottom: 3px;
`;

export default connect(null, { setEditorblocks })(Editor);
