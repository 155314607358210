import React from 'react';
import { Button, Table } from 'antd';
// import styled from 'styled-components'
import usePresenter from './usePresenter';
import { helperRedirect } from 'helpers/redirect';

const TagsPage: React.FC = () => {
  const { dataSource, columns, loading } = usePresenter();

  return (
    <React.Fragment>
      <Button
        style={{ marginBottom: 20 }}
        type="primary"
        onClick={() => helperRedirect('tags/create')}
      >
        Создать Тег
      </Button>
      <Table dataSource={dataSource} columns={columns} loading={loading} />
    </React.Fragment>
  );
};

export default TagsPage;
