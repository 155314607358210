import React, { useState, useCallback } from 'react';
import { Upload, Icon, message, Form } from 'antd';
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';
import { WithPassedFormProps } from 'enhancers/withPassedFormProps';
import { BASE_URL } from 'services/Constants';
import { WrappedFormUtils } from 'antd/lib/form/Form';

function beforeUpload(file: File) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

interface IUloadFieldProps {
  name: string;
  rules?: any[];
  label?: string;
  initialValue?: string;
  form?: WrappedFormUtils;
}

const UploadField: React.FC<IUloadFieldProps & IFormProps> = ({
  form: { getFieldDecorator },
  rules,
  initialValue,
  label,
  name
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | ArrayBuffer | null>('');

  const handleChange = useCallback(
    (info: UploadChangeParam<UploadFile<any>>) => {
      if (info.file.status === 'uploading') {
        setLoading(true);
        return;
      }
      if (info.file.status === 'done') {
        setImageUrl(info.file.response.medium);
        setLoading(false);
        return info.file.response.id;
      }
    },
    []
  );

  const uploadButton = (
    <div>
      <Icon type={loading ? 'loading' : 'plus'} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  if (typeof imageUrl !== 'string') {
    return null;
  }

  const preview = loading ? (
    <Icon type="loading" />
  ) : (
    <img
      src={imageUrl || initialValue}
      alt="avatar"
      style={{ width: '100%' }}
    />
  );

  const token = localStorage.getItem('token');

  return (
    <Form.Item label={label}>
      {getFieldDecorator(name, {
        rules,
        getValueFromEvent: handleChange
      })(
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          headers={{ Authorization: `Bearer ${token}` }}
          action={`${BASE_URL}images`}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {imageUrl || initialValue ? preview : uploadButton}
        </Upload>
      )}
    </Form.Item>
  );
};

export default WithPassedFormProps(UploadField);
