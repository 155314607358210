import React from 'react';
import { Form, Checkbox, Col, Divider, Row } from 'antd';
import { ControlButtons, FieldsProvider } from 'components';
import usePresenter, { IProjectParamsProps } from './usePresenter';
import {
  TextField,
  SelectField,
  DateField,
  UploadField,
  TextAreaField,
  SelectableGroupField
} from '../Fields';
import { useSelector } from 'react-redux';

const ProjectParams: React.FC<IProjectParamsProps> = ({
  form,
  initialValues,
  isCreateProject
}) => {
  const {
    handleSubmit,
    disabled,
    handleIsTilda,
    onChangePublishedStatus,
    publishedStatus,
    onDeleteArticle
  } = usePresenter({ initialValues, form });

  const { showEditor } = useSelector((store: IStore) => store.layout.projects);

  return (
    <Form onSubmit={handleSubmit}>
      <FieldsProvider form={form}>
        <Divider className="form-divider" orientation="left">
          Основная информация
        </Divider>
        <Row type="flex" justify="space-between">
          <Col span={10}>
            <TextAreaField
              form={form}
              name="title"
              label="Заголовок"
              placeholder="Введите заголовок"
              rules={[{ required: true, message: 'укажите заголовок' }]}
            />
          </Col>

          <Col span={13}>
            <TextAreaField
              form={form}
              name="description"
              label="Промо описание"
              placeholder="Введите промо описание"
              rules={[{ required: true, message: 'укажите промо описание' }]}
            />
          </Col>
        </Row>

        <Divider />

        <Row type="flex" gutter={24}>
          <Col>
            <DateField form={form} name="publishedAt" label="Дата публикации" />
          </Col>

          <Col span={6}>
            <TextField
              form={form}
              name="slug"
              label="Url"
              placeholder="Введите url"
              rules={[{ required: true, message: 'укажите url' }]}
            />
          </Col>

          <Col span={6}>
            <TextField
              form={form}
              name="alt"
              label="Alt"
              placeholder="Введите промо alt"
              rules={[{ required: true, message: 'укажите alt' }]}
            />
          </Col>
        </Row>

        <Divider />

        <Row type="flex" justify="space-between" gutter={10}>
          <Col span={8}>
            <SelectField
              form={form}
              name="industries"
              source="categories/search?type=industry"
              label="Отрасли"
              placeholder="Выберите отрасли"
              initialValue={
                initialValues ? initialValues.industries : undefined
              }
            />
          </Col>

          <Col span={8}>
            <SelectField
              form={form}
              name="categories"
              source="categories/search?type=project"
              label="Категории"
              placeholder="Выберите категории"
              initialValue={
                initialValues ? initialValues.categories : undefined
              }
            />
          </Col>

          <Col span={8}>
            <SelectField
              form={form}
              name="tags"
              source="tags/selectable"
              label="Метки"
              placeholder="Выберите метки"
              initialValue={initialValues ? initialValues.tags : undefined}
            />
          </Col>
        </Row>

        <Divider className="form-divider" orientation="left">
          SEO
        </Divider>
        <Row type="flex" justify="space-between">
          <Col span={10}>
            <TextAreaField
              form={form}
              name="seoTitle"
              label="Заголовок (SEO)"
              placeholder="Введите заголовок для seo"
            />
          </Col>
          <Col span={13}>
            <TextAreaField
              form={form}
              name="seoDescription"
              label="Описание (SEO)"
              placeholder="Введите описание для seo"
            />
          </Col>
        </Row>

        <Divider className="form-divider" orientation="left">
          Дополнительная информация
        </Divider>

        <Row gutter={24}>
          <Col span={6}>
            <TextField
              form={form}
              name="site"
              label="Ссылка на сайт"
              placeholder="Введите url"
            />
          </Col>

          <Col span={10}>
            <TextField
              form={form}
              name="siteName"
              label="Название ссылки"
              placeholder="Введите название сайта"
            />
          </Col>
        </Row>

        <Divider />

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item>
              {form.getFieldDecorator('isUrl')(
                <Checkbox defaultChecked={initialValues && initialValues.isUrl}>
                  Открывать без содержания, только сайт (ссылка на сайт)
                </Checkbox>
              )}
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item>
              {form.getFieldDecorator('favorite')(
                <Checkbox
                  defaultChecked={initialValues && initialValues.favorite}
                >
                  Выводить на главную
                </Checkbox>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={24}>
          <Col span={4}>
            <UploadField
              form={form}
              name="home"
              initialValue={initialValues && initialValues.home}
              label="Обложка"
            />
          </Col>

          <Col span={4}>
            <UploadField
              form={form}
              name="cover"
              initialValue={initialValues && initialValues.cover}
              label="Главная"
            />
          </Col>
        </Row>

        <SelectableGroupField
          label="Услуги"
          initialValues={initialValues && initialValues.services}
          buttonLabel="Добавить услугу"
          inputProps={{
            placeholder: 'Выберете услугу'
          }}
          name="services"
          rules={[
            {
              required: true,
              message: 'Please input service name or delete this field.'
            }
          ]}
        />

        <SelectableGroupField
          label="Статьи"
          initialValues={initialValues && initialValues.articles}
          disabled={disabled}
          buttonLabel="добавить статью"
          inputProps={{
            placeholder: 'Выберете статью'
          }}
          name="articles"
          rules={[
            {
              required: true,
              message: 'Please input service name or delete this field.'
            }
          ]}
        />

        <SelectableGroupField
          label="Проекты"
          initialValues={initialValues && initialValues.projects}
          disabled={disabled}
          buttonLabel="добавить проект"
          inputProps={{
            placeholder: 'Выберете проект'
          }}
          name="projects"
          rules={[
            {
              required: true,
              message: 'Please input project name or delete this field.'
            }
          ]}
        />

        <Divider className="form-divider" orientation="center">
          Редактор
        </Divider>

        <Form.Item>
          {form.getFieldDecorator('isTilda')(
            <Checkbox
              onChange={handleIsTilda}
              defaultChecked={initialValues && initialValues.isTilda}
            >
              Тильда
            </Checkbox>
          )}
          {!showEditor && (
            <Row type="flex" justify="space-between" gutter={10}>
              <Col span={8}>
                <TextAreaField
                  form={form}
                  rows={8}
                  name="tildaHtml"
                  label="html"
                  placeholder="html"
                  initialValue={initialValues?.tildaHtml}
                />
              </Col>

              <Col span={8}>
                <TextAreaField
                  form={form}
                  rows={8}
                  name="tildaCss"
                  label="css"
                  placeholder="css"
                  initialValue={initialValues?.tildaCss}
                />
              </Col>

              <Col span={8}>
                <TextAreaField
                  form={form}
                  rows={8}
                  name="tildaJs"
                  label="js"
                  placeholder="js"
                  initialValue={initialValues?.tildaJs}
                />
              </Col>
            </Row>
          )}
        </Form.Item>
      </FieldsProvider>

      <ControlButtons
        handlePublish={onChangePublishedStatus}
        onDeleteArticle={onDeleteArticle}
        publishStatus={publishedStatus}
        isCreate={isCreateProject}
      />
    </Form>
  );
};

export default Form.create<IProjectParamsProps>({ name: 'ProjectParams' })(
  ProjectParams
);
