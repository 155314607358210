import { WrappedFormUtils } from 'antd/lib/form/Form';

const usePresenter = (form: WrappedFormUtils, name: string) => {
  const remove = (k: string) => {
    const keys = form.getFieldValue(`${name}Keys`);

    const newKeys = [
      ...keys.slice(0, k),
      ...keys.slice(k + 1).map((key: number) => key - 1)
    ];

    form.setFieldsValue({
      [`${name}Keys`]: newKeys
    });

    const keys2 = form.getFieldValue(name);
    const key2 = form.getFieldValue(`${name}[${k}]`);

    form.setFieldsValue({
      [name]: keys2.filter((key: string) => key !== key2)
    });
  };

  const add = () => {
    const keys = form.getFieldValue(`${name}Keys`);
    const nextKeys = keys.concat(keys.length);
    form.setFieldsValue({
      [`${name}Keys`]: nextKeys
    });
  };

  const { getFieldDecorator, getFieldValue } = form;

  getFieldDecorator(`${name}Keys`, { initialValue: [] });
  const keys = getFieldValue(`${name}Keys`);

  return {
    remove,
    add,
    keys
  };
};

export default usePresenter;
