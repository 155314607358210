export const SET_CURRENT_PAGE = '[PAGINATION] SET_CURRENT_PAGE';
export const SET_TOTAL_PAGES = '[PAGINATION] SET_TOTAL_PAGES';
export const SET_PAGE_SIZE = '[PAGINATION] SET_PAGE_SIZE';

export interface ISetCurrentPage {
  type: typeof SET_CURRENT_PAGE;
  payload: number;
}

export interface ISetTotalPages {
  type: typeof SET_TOTAL_PAGES;
  payload: number;
}

export interface ISetPageSize {
  type: typeof SET_PAGE_SIZE;
  payload: number;
}

export const setCurrentPage = (pageNumber: number): ISetCurrentPage => ({
  type: SET_CURRENT_PAGE,
  payload: pageNumber
});

export const setTotalPages = (total: number): ISetTotalPages => ({
  type: SET_TOTAL_PAGES,
  payload: total
});

export const setPageSize = (pageSize: number): ISetPageSize => ({
  type: SET_PAGE_SIZE,
  payload: pageSize
});
