import React from 'react';
import { Form, Button, Checkbox, Row, Col, Divider } from 'antd';
import { FieldsProvider } from 'components';
import { SelectField, TextAreaField, YearField } from '../Fields';
import styled from 'styled-components';
import usePresenter, { IRewardParamsProps } from './usePresenter';
import { Http } from '../../../services/HttpService'
import { helperRedirectReplace } from '../../../helpers/redirect'
import { notify } from '../../../utils/notify'

const RewardParams: React.FC<IRewardParamsProps> = ({
  form,
  initialValues
}) => {
  const { handleSubmit } = usePresenter({ initialValues, form });

  const deleteReward = (id?: number) => {
    if (id) {
      Http.delete(`rewards/${id}`).then(response => {
        if (response.status === 200) {
          helperRedirectReplace('/rewards');
          notify.success('Вы удалили награду');
        }
      });
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FieldsProvider form={form}>
        <Row type="flex" gutter={24}>
          <Col span={6}>
            <SelectField
              form={form}
              name="typeId"
              mode="default"
              label="Награда"
              source="rewards/types"
              placeholder="Выберите награду"
              initialValue={initialValues ? initialValues.type : undefined}
            />
            <SelectField
              form={form}
              name="level"
              mode="default"
              label="Уровень"
              source="rewards/levels"
              placeholder="Выберите уровень"
              initialValue={initialValues ? initialValues.level : undefined}
            />
          </Col>

          <Col span={10}>
            <TextAreaField
              form={form}
              name="description"
              label="Oписание"
              placeholder="Введите описание"
              rows={6}
              rules={[{ required: true, message: 'Введите описание' }]}
            />
          </Col>
        </Row>

        <Divider />

        <Form.Item>
          {form.getFieldDecorator('favorite')(
            <Checkbox defaultChecked={initialValues && initialValues.favorite}>
              Выводить на главную
            </Checkbox>
          )}
        </Form.Item>

        <Divider />

        <Row type="flex" gutter={24}>
          <Col span={8}>
            <SelectField
              form={form}
              label="Проект"
              mode="default"
              name="projectId"
              source="projects/selectable"
              placeholder="Выберите проект"
              initialValue={initialValues ? initialValues.projectId : undefined}
            />
          </Col>
          <Col span={4}>
            <YearField
              form={form}
              name="year"
              label="Год"
              initialValue={initialValues ? initialValues.year : undefined}
            />
          </Col>
        </Row>
      </FieldsProvider>

      <FormSubmitButton>
        <Button type="primary" htmlType="submit" size="large">
          Опубликовать
        </Button>
        <Button type="danger" htmlType="button" size="large" onClick={() => deleteReward(initialValues?.id)}>
          Удалить
        </Button>
      </FormSubmitButton>
    </Form>
  );
};

const FormSubmitButton = styled(Form.Item)`
  display: flex;
  position: absolute;
  top: 12px;
  right: 150px;
`;

export default Form.create<IRewardParamsProps>({ name: 'RewardParams' })(
  RewardParams
);
