import { takeLatest } from 'redux-saga/effects';
import { Http } from 'services/HttpService';
import { actions } from './actions';
import { setEditorblocks } from 'store/editor/actions';
import { transformBlocksFromBackend } from 'utils/transformBlocksFromBackend';
import { notify } from 'utils/notify';

export function* fetchService(
  action: ReturnType<typeof actions.fetchServiceRequest>
) {
  try {
    yield Http.get<IService>(`services/${action.payload}`)
      .then(({ data }) => {
        window.store.dispatch(actions.fetchServiceSuccess(data));
        window.store.dispatch(
          setEditorblocks(transformBlocksFromBackend(data.service.blocks))
        );
      })
      .catch((error: { message: string }) => {
        notify.error(error);
        window.store.dispatch(actions.fetchServiceError(error.message));
        window.store.dispatch(actions.clearServiceData());
        window.store.dispatch(setEditorblocks([]));
      });
  } catch (error) {
    console.error(error);
  }
}

export default function* serviceSaga() {
  yield takeLatest('[SERVICE] FETCH_SERVICE_REQUEST', fetchService);
}
