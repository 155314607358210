import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { actions } from 'store/author/actions';

const usePresenter = () => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  useEffect(() => {
    dispatch(actions.fetchAuthorRequest(params.id));
  }, [dispatch, params.id]);

  const { data, loading, error } = useSelector((store: IStore) => store.author);

  const hasContent = Boolean(!error.message && !loading && data);

  return {
    data,
    error,
    loading,
    hasContent
  };
};

export default usePresenter;
