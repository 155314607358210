import React from 'react';
import { Input, Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { WithPassedFormProps } from 'enhancers/withPassedFormProps';

const { TextArea } = Input;

interface ITextAreaFieldProps {
  name: string;
  rules?: any[];
  label?: string;
  placeholder?: string;
  initialValue?: string;
  form?: WrappedFormUtils;
  rows?: number;
}

const TextAreaField: React.FC<ITextAreaFieldProps & IFormProps> = ({
  form: { getFieldDecorator },
  rules,
  initialValue,
  label,
  placeholder,
  name,
  rows
}) => (
  <Form.Item label={label} style={{ width: '100%' }}>
    {getFieldDecorator(name, {
      rules,
      initialValue
    })(<TextArea placeholder={placeholder} rows={rows} />)}
  </Form.Item>
);

export default WithPassedFormProps(TextAreaField);
