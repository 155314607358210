import React from 'react';
import usePresenter from './usePresenter';
import { Layout, Menu, Icon } from 'antd';

const { SubMenu } = Menu;
const { Sider } = Layout;

const Sidebar: React.FC = () => {
  const { collapsed, selectedKeys, handleClick } = usePresenter();

  return (
    <Sider trigger={null} collapsible collapsed={!collapsed} width={300}>
      <Menu theme="dark" mode="inline" selectedKeys={selectedKeys}>
        <Menu.Item onClick={handleClick} key="projects">
          <Icon type="book" />
          <span>Портфолио</span>
        </Menu.Item>

        <Menu.Item onClick={handleClick} key="articles">
          <Icon type="edit" />
          <span>Блог</span>
        </Menu.Item>

        <Menu.Item onClick={handleClick} key="services">
          <Icon type="container" />
          <span>Услуги</span>
        </Menu.Item>

        <SubMenu
          key="categories"
          title={
            <span>
              <Icon type="switcher" />
              <span>Категории фильтров</span>
            </span>
          }
        >
          <Menu.Item onClick={handleClick} key="industry">
            <Icon type="area-chart" />
            <span>Портфолио - виды отраслей</span>
          </Menu.Item>

          <Menu.Item onClick={handleClick} key="project">
            <Icon type="project" />
            <span>Портфолио - виды работ</span>
          </Menu.Item>

          <Menu.Item onClick={handleClick} key="article">
            <Icon type="message" />
            <span>Блог</span>
          </Menu.Item>
        </SubMenu>

        <Menu.Item onClick={handleClick} key="rewards">
          <Icon type="star" />
          <span>Награды</span>
        </Menu.Item>

        <Menu.Item onClick={handleClick} key="tags">
          <Icon type="tags" />
          <span>Теги</span>
        </Menu.Item>

        <Menu.Item onClick={handleClick} key="authors">
          <Icon type="user" />
          <span>Авторы</span>
        </Menu.Item>

        <Menu.Item onClick={handleClick} key="vacancies">
          <Icon type="book" />
          <span>Вакансии</span>
        </Menu.Item>

        <Menu.Item onClick={handleClick} key="about">
          <Icon type="book" />
          <span>О нас</span>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
