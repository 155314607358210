import React from 'react';
import { Button, Table } from 'antd';
import usePresenter from './usePresenter';
import { helperRedirect } from 'helpers/redirect';

const Category: React.FC = () => {
  const { dataSource, columns, loading } = usePresenter();

  return (
    <React.Fragment>
      <Button
        style={{ marginBottom: 20 }}
        type="primary"
        onClick={() => helperRedirect('categories/create')}
      >
        Создать Категорию
      </Button>
      <Table dataSource={dataSource} columns={columns} loading={loading} />
    </React.Fragment>
  );
};

export default Category;
