import React from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';

interface IFeldsProviderProps {
  children: JSX.Element[];
  form: WrappedFormUtils;
}

const FieldsProvider: React.FC<IFeldsProviderProps> = ({ children, form }) => (
  <>
    {React.Children.map(children, child => React.cloneElement(child, { form }))}
  </>
);

export default FieldsProvider;
