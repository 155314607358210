import { SET_EDITOR_BLOCKS, ISetEditorBlocks } from './actions';
import { transformBlocksFromEditor } from 'utils/transformBlocksFromEditor';

interface IEditorReduceState {
  blocks: TEditorBlocks | [];
  transformBlocks: any[];
}

const initialState = {
  blocks: [],
  transformBlocks: []
};

export const reducer = (
  state: IEditorReduceState = initialState,
  action: ISetEditorBlocks
) => {
  switch (action.type) {
    case SET_EDITOR_BLOCKS: {
      return {
        ...state,
        blocks: action.payload,
        transformBlocks: transformBlocksFromEditor(action.payload)
      };
    }
    default:
      return state;
  }
};
