const points = {
  mobile: 0,
  tablet: 600,
  laptop: 1200,
  desktop: 1500
};

const mobile = (content: string) =>
  `@media (max-width: ${points.tablet - 1}px) { ${content} }`;
const laptopUp = (content: string) =>
  `@media (min-width: ${points.laptop}px) { ${content} }`;
const laptopDown = (content: string) =>
  `@media (max-width: ${points.desktop - 1}px) { ${content} }`;
const tabletUp = (content: string) =>
  `@media (min-width: ${points.tablet}px) { ${content} }`;
const tabletDown = (content: string) =>
  `@media (max-width: ${points.laptop - 1}px) { ${content} }`;

const tablet = (content: string) =>
  `@media (min-width: ${points.tablet}px) and (max-width: ${
    points.laptop - 1
  }px) { ${content} }`;

const laptop = (content: string) =>
  `@media (min-width: ${points.laptop}px) and (max-width: ${
    points.desktop - 1
  }px) { ${content} }`;

const desktop = (content: string) =>
  `@media (min-width: ${points.desktop}px) { ${content} }`;

export default {
  points,
  mobile,
  tablet,
  tabletUp,
  tabletDown,
  laptop,
  laptopUp,
  laptopDown,
  desktop
};
