import { Component } from 'react';
import { API } from '@editorjs/editorjs';
import { BLOCK_COLOR_NUM } from '../consts';
import { Aligns, BlockColors } from '../enums';
import { make } from 'plugins/utils/makeElement';

import './index.css';

interface IPropsData {
  content: {
    text: string;
  };
  params: {
    color: {
      label: string;
      value: string;
    };
    float: string;
  };
}

interface IButtonPluginProps {
  data: IPropsData;
  api: API;
}

class ButtonPlugin extends Component<IButtonPluginProps> {
  api: API;

  nodes: {
    wrapper: HTMLDivElement;
    containerFlex: HTMLDivElement;
    button: HTMLButtonElement;
    labelFloat: HTMLLabelElement;
    selectFloat: HTMLSelectElement;
    labelContent: HTMLLabelElement;
    inputContent: HTMLInputElement;
    labelBlockColor: HTMLLabelElement;
    selectBlockColor: HTMLSelectElement;
  };

  _data: {
    content: {
      text: string;
    };
    params: {
      color: {
        label: string;
        value: string;
      };
      float: string;
    };
  };

  constructor(props: IButtonPluginProps) {
    super(props);
    this.api = this.props.api;

    this.nodes = {
      wrapper: make('div', this.CSS.wrapper),
      containerFlex: make('div', this.CSS.containerFlex),
      button: make('button', this.CSS.button),
      labelFloat: make('label', this.CSS.labelFloat),
      selectFloat: make('select', this.CSS.selectFloat),
      labelContent: make('label', this.CSS.labelContent),
      inputContent: make('input', this.CSS.inputContent),
      labelBlockColor: make('label', this.CSS.labelBlockColor),
      selectBlockColor: make('select', this.CSS.selectBlockColor)
    };

    this._data = {
      content: {
        text: ''
      },
      params: {
        color: {
          label: 'Белый',
          value: '#ffffff'
        },
        float: 'left'
      }
    };

    this.initNodes();

    this.data = this.props.data;
  }

  initNodes() {
    this.initLabelsText();
    this.initSelectsOptions();

    this.nodes.inputContent.placeholder = 'Метка кнопки: ';

    this.initNodesAppend();
  }

  initNodesAppend() {
    this.nodes.containerFlex.appendChild(this.nodes.button);
    this.nodes.wrapper.appendChild(this.nodes.containerFlex);
    this.nodes.wrapper.appendChild(this.nodes.labelContent);
    this.nodes.wrapper.appendChild(this.nodes.inputContent);
    this.nodes.wrapper.appendChild(this.nodes.labelFloat);
    this.nodes.wrapper.appendChild(this.nodes.selectFloat);
    this.nodes.wrapper.appendChild(this.nodes.labelBlockColor);
    this.nodes.wrapper.appendChild(this.nodes.selectBlockColor);
  }

  initLabelsText() {
    this.nodes.labelFloat.innerText = 'Смещение: ';
    this.nodes.labelContent.innerText = 'Содержимое: ';
    this.nodes.labelBlockColor.innerText = 'Цвет блока: ';
  }

  initSelectsOptions() {
    for (let i = 0; i < 3; i++) {
      let option = document.createElement('option');
      option.text = Aligns[i];
      this.nodes.selectFloat.add(option);
    }

    for (let i = 0; i < BLOCK_COLOR_NUM; i++) {
      let option = document.createElement('option');
      option.text = BlockColors[i];
      this.nodes.selectBlockColor.add(option);
    }
  }

  get CSS() {
    return {
      wrapper: 'wrapper',
      containerFlex: 'button-block',
      button: ['button-block__button', 'primary__button'],
      labelFloat: 'button-block__label-float',
      selectFloat: 'button-block__select-float',
      labelContent: 'button-block__label-content',
      inputContent: 'button-block__input-content',
      labelBlockColor: 'button-block__label-block-color',
      selectBlockColor: 'button-block__input-color'
    };
  }

  get data() {
    return this._data;
  }

  set data(data) {
    if (Object.keys(data).length !== 0) {
      this._data = data;
    }

    this.nodes.inputContent.oninput = () => {
      this.data.content.text = this.nodes.inputContent.value;

      this.nodes.button.innerText = this.nodes.inputContent.value;
    };

    this.nodes.selectFloat.oninput = () => {
      this.data.params.float = this.nodes.selectFloat.value;

      let currentAlign = this.nodes.containerFlex.classList[1];
      this.nodes.containerFlex.classList.remove(currentAlign);
      this.nodes.containerFlex.classList.add(
        `button-block__${
          this.nodes.selectFloat.options[
            this.nodes.selectFloat.options.selectedIndex
          ].innerHTML
        }`
      );
    };

    this.nodes.selectBlockColor.oninput = () => {
      this.data.params.color.label = this.nodes.selectBlockColor.value;
      this.nodes.wrapper.style.backgroundColor =
        this.setOutputColorValue() as string;
    };

    this.setNodesValuesFromBackend();
  }

  setNodesValuesFromBackend() {
    if (this.data.content !== undefined) {
      this.nodes.button.innerText = this.data.content.text
        ? this.data.content.text
        : '';
      this.nodes.inputContent.value = this.data.content.text
        ? this.data.content.text
        : '';
    }
    if (this.data.params !== undefined) {
      if (this.data.params.float !== undefined) {
        this.nodes.selectFloat.value = this.data.params.float;
        this.nodes.containerFlex.classList.add(
          `button-block__${this.data.params.float}`
        );
      }
      this.nodes.selectBlockColor.value = this.data.params.color.label;
      this.nodes.wrapper.style.backgroundColor =
        this.setOutputColorValue() as string;
    }
  }

  save() {
    return {
      content: {
        text: this.data.content.text
      },
      params: {
        color: {
          label: this.data.params.color.label,
          value: this.setOutputColorValue()
        },
        float: this.data.params.float,
        //warning (always false)
        isHidden: false
      }
    };
  }

  setOutputColorValue() {
    if (this.data.params.color.label === 'Белый') {
      return '#ffffff';
    } else if (this.data.params.color.label === 'Серый') {
      return '#f5f5f5';
    }
  }

  render() {
    return this.nodes.wrapper;
  }

  static get conversionConfig() {
    return {
      export: 'text',
      import: 'text'
    };
  }

  static get toolbox() {
    return {
      icon: '<b>B</b>',
      title: 'Кнопка'
    };
  }
}

export default ButtonPlugin;
