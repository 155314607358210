import React from 'react';
import { Input, Form } from 'antd';
import { WithPassedFormProps } from 'enhancers/withPassedFormProps';
import { WrappedFormUtils } from 'antd/lib/form/Form';

interface ITextFieldProps {
  name: string;
  rules?: any[];
  label?: string;
  placeholder?: string;
  initialValue?: string;
  type?: string;
  form?: WrappedFormUtils;
}

const TextField: React.FC<ITextFieldProps & IFormProps> = ({
  form: { getFieldDecorator },
  rules,
  initialValue,
  label,
  placeholder,
  name,
  type
}) => (
  <Form.Item label={label} style={{ width: '100%' }}>
    {getFieldDecorator(name, {
      rules,
      initialValue
    })(<Input placeholder={placeholder} type={type} />)}
  </Form.Item>
);

export default WithPassedFormProps(TextField);
