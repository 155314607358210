export const actions = {
  fetchProjectsRequest: (query: string) =>
    ({
      type: '[PROJECTS] FETCH_PROJECTS_REQUEST',
      payload: { query }
    } as const),

  fetchProjectsSuccess: (projects: IProjectItem[]) =>
    ({
      type: '[PROJECTS] FETCH_PROJECTS_SUCCESS',
      payload: { projects }
    } as const),

  fetchProjectsError: (message: string) =>
    ({
      type: '[PROJECTS] FETCH_PROJECTS_ERROR',
      payload: { message }
    } as const),

  clearProjectsData: () =>
    ({
      type: '[PROJECTS] CLEAR_PROJECTS_DATA'
    } as const)
};
