export const actions = {
  fetchAuthorsRequest: () =>
    ({
      type: '[AUTHORS] FETCH_AUTHORS_REQUEST'
    } as const),

  fetchAuthorsSuccess: (authors: Array<IAuthor>) =>
    ({
      type: '[AUTHORS] FETCH_AUTHORS_SUCCESS',
      payload: { authors }
    } as const),

  fetchAuthorsError: (message: string) =>
    ({
      type: '[AUTHORS] FETCH_AUTHORS_ERROR',
      payload: { message }
    } as const),

  clearAuthorsData: () =>
    ({
      type: '[AUTHORS] CLEAR_AUTHORS_DATA'
    } as const)
};
