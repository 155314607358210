import moment from 'moment';

export function getInitialValues(data: IProject) {
  const { project, isPublished } = data;

  const initialValues = {
    id: project.id,
    alt: project.alt,
    site: project.site,
    slug: project.slug,
    tags: project.tags,
    isUrl: project.isUrl,
    title: project.title,
    home: project.images.home,
    projects: project.projects,
    siteName: project.siteName,
    articles: project.articles,
    services: project.services,
    favorite: project.favorite,
    tildaJs: project.tildaJs,
    isTilda: project.isTilda,
    tildaCss: project.tildaCss,
    tildaHtml: project.tildaHtml,
    cover: project.images.cover,
    description: project.description,
    seoTitle: project.seo ? project.seo.title : '',
    seoDescription: project.seo ? project.seo.description : '',
    industries: project.filters.industries.map(item => item.id),
    categories: project.filters.categories.map(item => item.id),
    publishedAt: project.publishedAt ? moment(project.publishedAt) : moment(),
    isPublished
  };

  return initialValues;
}
