import { useEffect } from 'react';
import { helperRedirect } from 'helpers/redirect';
import { fetchUserSuccess } from 'store/user/actions';

const useCheckUser = () => {
  const token = window.localStorage.getItem('token');

  useEffect(() => {
    if (token) {
      window.store.dispatch(fetchUserSuccess());
      return;
    }
    helperRedirect('/login');
  }, [token]);
  return null;
};

export default useCheckUser;
