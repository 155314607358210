import { useEffect, useState, useCallback } from 'react';

const usePresenter = () => {
  const [show, setShow] = useState<boolean>(false);
  const [id, setId] = useState<string>('');
  const [value, setValue] = useState<string>('');

  const onOpenModal = useCallback(
    (event: CustomEvent<{ id: string; value: string }>) => {
      setShow(!show);
      setId(event.detail.id);
      setValue(event.detail.value);
    },
    [show]
  );

  useEffect(() => {
    document.addEventListener(
      'openTinymcePlugginModal',
      event => onOpenModal(event as CustomEvent<{ id: string; value: string }>),
      false
    );
  }, [onOpenModal]);

  const onChange = (value: string) => {
    setValue(value);
  };

  const onCancel = useCallback(() => {
    setShow(!show);
  }, [show]);

  const onSave = () => {
    const element = document.getElementById(id);

    if (!element) {
      return;
    }

    element.innerHTML = value;
    setShow(false);
  };

  return {
    onSave,
    onChange,
    onOpenModal,
    onCancel,
    show,
    value,
    id
  };
};

export default usePresenter;
