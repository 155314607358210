export const SET_EDITOR_BLOCKS = '[EDITOR] SET_EDITOR_BLOCKS';

export interface ISetEditorBlocks {
  readonly type: typeof SET_EDITOR_BLOCKS;
  readonly payload: TEditorBlocks;
}

export const setEditorblocks = (blocks: TEditorBlocks): ISetEditorBlocks => ({
  type: SET_EDITOR_BLOCKS,
  payload: blocks
});
