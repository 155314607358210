import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from "antd";
import ru_RU from "antd/lib/locale/ru_RU";

import { store } from 'store/configureStore';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { GlobalStyle } from 'styles';

ReactDOM.render(
  <ConfigProvider locale={ru_RU}>
    <Provider store={store}>
    <App />
    <GlobalStyle />
  </Provider>
  </ConfigProvider>,
  document.getElementById('root')
);
serviceWorker.unregister();
