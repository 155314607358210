import React from 'react';
import { Form, Button, Select, Row, Col } from 'antd';
import styled from 'styled-components';
import { FieldsProvider } from 'components';
import usePresenter, { ICategoryParamsProps } from './usePresenter';
import { SelectField, TextAreaField, TinyMceField } from '../Fields';

const { Option } = Select;

const CategoryParams: React.FC<ICategoryParamsProps> = ({
  form,
  initialValues
}) => {
  const { handleSubmit } = usePresenter({ initialValues, form });

  const types = [
    {
      label: 'Блог',
      value: 'article'
    },
    {
      label: 'Портфолио - виды отраслей',
      value: 'industry'
    },
    {
      label: 'Портфолио - виды работ',
      value: 'project'
    }
  ];

  return (
    <Form onSubmit={handleSubmit}>
      <FieldsProvider form={form}>
        <Row type="flex" justify="space-between" gutter={24}>
          <Col span={12}>
            <TextAreaField
              form={form}
              name="title"
              label="Название"
              placeholder="Введите название"
              rules={[{ required: true, message: 'Введите название' }]}
            />
          </Col>
          <Col span={12}>
            <TextAreaField
              form={form}
              name="slug"
              label="Англ.версия"
              placeholder="Введите название для английской версии"
              rules={[
                {
                  required: true,
                  message: 'Введите название для английской версии'
                }
              ]}
            />
          </Col>
        </Row>

        <TinyMceField
          name="description"
          label="Описание"
          placeholder="Введите описание"
          height={200}
        />

        <Row type="flex" justify="space-between" gutter={24}>
          <Col span={12}>
            <Form.Item label="Тип">
              {form.getFieldDecorator('type', {
                rules: [{ required: true, message: 'Выберите тип' }],
                initialValue: initialValues ? initialValues.type : undefined
              })(
                <Select placeholder="Выберите тип">
                  {types.map(item => (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <SelectField
              form={form}
              label="Услуга"
              mode="multiple"
              name="service"
              source="services/selectable"
              placeholder="Выберите услугу"
              initialValue={initialValues ? initialValues.service : undefined}
            />
          </Col>
        </Row>

        <Row type="flex" justify="space-between" gutter={24}>
          <Col span={12}>
            <TextAreaField
              form={form}
              label="Seo заголовок"
              name="seo_title"
              placeholder="Введите seo заголовок"
              rules={[{ required: true, message: 'Введите seo заголовок' }]}
            />
          </Col>
          <Col span={12}>
            <TextAreaField
              form={form}
              label="Seo описание"
              name="seo_description"
              placeholder="Введите seo описание"
              rules={[{ required: true, message: 'Введите seo описание' }]}
            />
          </Col>
        </Row>
      </FieldsProvider>

      <FormSubmitButton>
        <Button type="primary" htmlType="submit" size="large">
          Опубликовать
        </Button>
      </FormSubmitButton>
    </Form>
  );
};

const FormSubmitButton = styled(Form.Item)`
  display: flex;
  position: absolute;
  top: 12px;
  right: 150px;
`;

export default Form.create<ICategoryParamsProps>({ name: 'CategoryParams' })(
  CategoryParams
);
