import { all } from 'redux-saga/effects';

import tagSaga from 'store/tag/saga';
import userSaga from 'store/user/saga';
import tagsSaga from 'store/tags/saga';
import authorSaga from 'store/author/saga';
import rewardSaga from 'store/reward/saga';
import authorsSaga from 'store/authors/saga';
import articleSaga from 'store/article/saga';
import serviceSaga from 'store/service/saga';
import rewardsSaga from 'store/rewards/saga';
import projectSaga from 'store/project/saga';
import projectsSaga from 'store/projects/saga';
import articlesSaga from 'store/articles/saga';
import servicesSaga from 'store/services/saga';
import categorySaga from 'store/category/saga';
import categoriesSaga from 'store/categories/saga';
import vacanciesSaga from 'store/vacancies/saga';
import aboutSaga from 'store/about/saga';

export function* rootSaga() {
  yield all([
    tagSaga(),
    userSaga(),
    tagsSaga(),
    authorSaga(),
    rewardSaga(),
    serviceSaga(),
    rewardsSaga(),
    articleSaga(),
    projectSaga(),
    authorsSaga(),
    projectsSaga(),
    articlesSaga(),
    servicesSaga(),
    categorySaga(),
    categoriesSaga(),
    vacanciesSaga(),
    aboutSaga()
  ]);
}
