import React from 'react';
import { Sidebar, Header, BreadcrumbItem } from 'components';
import { Layout, Breadcrumb } from 'antd';
import styled from 'styled-components';
import { useBreadcrumbs } from 'hooks';

const { Content } = Layout;

const DefaultLayout: React.FC = ({ children }) => {
  const { crumbs } = useBreadcrumbs();

  return (
    <Layout style={{ height: '100vh' }}>
      <Sidebar />
      <Layout>
        <Header />
        <StyledBreadcrumbs
          itemRender={BreadcrumbItem}
          routes={crumbs}
        ></StyledBreadcrumbs>
        <Content
          style={{
            margin: '10px',
            padding: 24,
            background: '#fff',
            overflow: 'auto'
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

const StyledBreadcrumbs = styled(props => <Breadcrumb {...props} />)`
  margin: 15px;
  margin-bottom: 5px;
  font-size: 18px;
`;

export default DefaultLayout;
