import { History } from 'history';

let history: History | null = null;

export function setHistory(h: History) {
  history = h;
}

export function helperRedirect(to: string) {
  history && history.push(to);
}

export function helperRedirectReplace(to: string) {
  history && history.replace(to);
}
