import { Select, Form, Spin } from 'antd';
import { Http } from 'services/HttpService';
import { ModeOption } from 'antd/lib/select';
import React, { useEffect, useState } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { WithPassedFormProps } from 'enhancers/withPassedFormProps';

const { Option } = Select;

interface ISelectFieldProps {
  name: string;
  source: string;
  rules?: any[];
  label?: string;
  placeholder?: string;
  initialValue?: number[] | number | string;
  mode?: ModeOption;
  form?: WrappedFormUtils;
  styles?: { [key: string]: string | number };
}

const SelectField: React.FC<ISelectFieldProps & IFormProps> = ({
  form: { getFieldDecorator },
  rules,
  initialValue,
  label,
  placeholder,
  name,
  source,
  mode,
  styles
}) => {
  const [items, setItems] = useState<Array<{
    value: string;
    label: string;
  }> | null>(null);

  useEffect(() => {
    Http.get<Array<{ value: string; label: string }>>(source).then(response =>
      setItems(response.data)
    );
  }, [source]);

  if (!items) {
    return <Spin size="small" />;
  }

  return (
    <Form.Item label={label} style={styles || { width: '100%' }}>
      {getFieldDecorator(name, {
        rules,
        initialValue
      })(
        <Select
          mode={mode || 'multiple'}
          placeholder={placeholder}
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}
        >
          {items.map(item => (
            <Option key={item.value} value={Number(item.value)}>
              {item.label}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
};

export default WithPassedFormProps(SelectField);
