import React from 'react';
import { Button } from 'antd';
import { useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import { helperRedirect } from 'helpers/redirect';
import { actions } from 'store/categories/actions';
import { useDispatch, useSelector } from 'react-redux';

interface IDataSourceItem {
  key: string;
  title: string;
}

const usePresenter = () => {
  const [dataSource, setDataSource] = useState<IDataSourceItem[] | undefined>(
    undefined
  );

  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const { loading, error, data } = useSelector(
    (store: IStore) => store.categories
  );

  useEffect(() => {
    dispatch(actions.fetchCategoriesRequest(pathname.replace('/', '')));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }

    let array: IDataSourceItem[] = [];

    for (let item of data) {
      array.push({
        key: item.value,
        title: item.label
      });
    }

    setDataSource(array);
  }, [data]);

  return {
    error,
    columns,
    loading,
    dataSource
  };
};

const columns = [
  {
    title: 'Название',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    render: (text: string, record: IDataSourceItem) => {
      return (
        <Button
          type="primary"
          onClick={() => helperRedirect(`categories/edit/${record.key}`)}
        >
          Редактировать
        </Button>
      );
    }
  }
];

export default usePresenter;
