import { takeLatest } from 'redux-saga/effects';
import { Http } from 'services/HttpService';
import { actions } from './actions';

import {
  setCurrentPage,
  setPageSize,
  setTotalPages
} from 'store/pagination/actions';
import { notify } from 'utils/notify';

export function* fetchArticles(
  action: ReturnType<typeof actions.fetchArticlesRequest>
) {
  try {
    yield Http.get<IWithPagination<Array<IArticleItem>>>(
      `articles?${action.payload.query}`
    )
      .then(response => {
        window.store.dispatch(actions.fetchArticlesSuccess(response.data.data));
        window.store.dispatch(setCurrentPage(response.data.current_page));
        window.store.dispatch(setPageSize(response.data.per_page));
        window.store.dispatch(setTotalPages(response.data.total));
      })
      .catch((error: { message: string }) => {
        notify.error(error);
        window.store.dispatch(actions.fetchArticlesError(error.message));
        window.store.dispatch(actions.clearArticlesData());
      });
  } catch (error) {
    console.error(error);
  }
}

export default function* articlesSaga() {
  yield takeLatest('[ARTICLES] FETCH_ARTICLES_REQUEST', fetchArticles);
}
