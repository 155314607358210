import { actions } from './actions';
import { InferActionsTypes } from 'store/configureStore';

const initialState = {
  data: null as ITagItem[] | null,
  loading: false,
  error: {
    message: null as string | null
  }
};

export const reducer = (
  state: typeof initialState = initialState,
  action: InferActionsTypes<typeof actions>
) => {
  switch (action.type) {
    case '[TAGS] FETCH_TAGS_REQUEST': {
      return {
        ...state,
        data: null,
        loading: true,
        error: {
          message: null
        }
      };
    }
    case '[TAGS] FETCH_TAGS_SUCCESS': {
      return {
        ...state,
        data: action.payload.tags,
        loading: false,
        error: {
          message: null
        }
      };
    }
    case '[TAGS] FETCH_TAGS_ERROR': {
      return {
        ...state,
        error: {
          message: action.payload.message
        },
        loading: false
      };
    }
    case '[TAGS] CLEAR_TAGS_DATA': {
      return {
        ...state,
        data: null
      };
    }
    default:
      return state;
  }
};
