import {
  IFetchUserRequest,
  IFetchUserSuccess,
  IFetchUserError,
  IUserLogout,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  USER_LOGOUT
} from './actions';

const initialState = {
  isAuth: false,
  loading: false
};

export const reducer = (
  state: typeof initialState = initialState,
  action: IFetchUserRequest | IFetchUserSuccess | IFetchUserError | IUserLogout
) => {
  switch (action.type) {
    case FETCH_USER_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case FETCH_USER_SUCCESS: {
      return {
        ...state,
        isAuth: true,
        loading: false
      };
    }
    case FETCH_USER_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case USER_LOGOUT: {
      return {
        ...state,
        isAuth: false
      };
    }
    default:
      return state;
  }
};
