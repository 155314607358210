export const actions = {
  fetchAboutRequest: () =>
    ({
      type: '[ABOUT] FETCH_ABOUT_REQUEST'
    } as const),

  fetchAboutSuccess: (about: IAbout) =>
    ({
      type: '[ABOUT] FETCH_ABOUT_SUCCESS',
      payload: about
    } as const),

  fetchAboutError: (message: string) =>
    ({
      type: '[ABOUT] FETCH_ABOUT_ERROR',
      payload: message
    } as const),

  clearAboutData: () =>
    ({
      type: '[ABOUT] CLEAR_ABOUT_DATA'
    } as const)
};
