import { actions } from './actions';
import { InferActionsTypes } from 'store/configureStore';

const initialState = {
  sidebar: {
    collapsed: true
  },
  articles: {
    searchCollapsed: false
  },
  services: {
    showEditor: false
  },
  projects: {
    showEditor: false
  }
};

export const reducer = (
  state: typeof initialState = initialState,
  action: InferActionsTypes<typeof actions>
) => {
  switch (action.type) {
    case '[LAYOUT] HANDLE_SIDEBAR_COLLAPSED': {
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          collapsed: !state.sidebar.collapsed
        }
      };
    }
    case '[LAYOUT] HANDLE_SEARCH_ARTICLE_COLLAPSED': {
      return {
        ...state,
        articles: {
          ...state.articles,
          searchCollapsed: !state.articles.searchCollapsed
        }
      };
    }
    case '[LAYOUT] HANDLE_SERVICE_EDITOR_VISIBILITY': {
      return {
        ...state,
        services: {
          ...state.services,
          showEditor: action.payload.visibility
        }
      };
    }
    case '[LAYOUT] HANDLE_PROJECT_EDITOR_VISIBILITY': {
      return {
        ...state,
        projects: {
          ...state.projects,
          showEditor: action.payload.visibility
        }
      };
    }
    default:
      return state;
  }
};
