export const actions = {
  fetchTagsRequest: () =>
    ({
      type: '[TAGS] FETCH_TAGS_REQUEST'
    } as const),

  fetchTagsSuccess: (tags: Array<ITagItem>) =>
    ({
      type: '[TAGS] FETCH_TAGS_SUCCESS',
      payload: { tags }
    } as const),

  fetchTagsError: (message: string) =>
    ({
      type: '[TAGS] FETCH_TAGS_ERROR',
      payload: { message }
    } as const),

  clearTagsData: () =>
    ({
      type: '[TAGS] CLEAR_TAGS_DATA'
    } as const)
};
