import React from 'react';
import { Form, Button } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { FieldsProvider } from 'components';
import { useDispatch } from 'react-redux';
import { fetchUserRequest } from 'store/user/actions';
import { TextField } from '../Fields';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

interface ILoginFormValues {
  name: string;
  password: string;
}

const LoginForm: React.FC<FormComponentProps<ILoginFormValues>> = ({
  form
}) => {
  const loading = useSelector((store: IStore) => store.user.loading);
  const dispatch = useDispatch();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        dispatch(fetchUserRequest(values));
      }
    });
  };

  return (
    <SForm onSubmit={handleSubmit}>
      <FieldsProvider form={form}>
        <TextField
          name="name"
          placeholder="Логин"
          rules={[{ required: true, message: 'введите логин' }]}
        />
        <TextField
          name="password"
          type="password"
          placeholder="Пароль"
          rules={[{ required: true, message: 'введите пароль' }]}
        />
      </FieldsProvider>
      <Form.Item>
        <Button type="primary" htmlType="submit" size="large" loading={loading}>
          Войти
        </Button>
      </Form.Item>
    </SForm>
  );
};

const SForm = styled(Form)`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 420px;
`;

export default Form.create({ name: 'loginForm' })(LoginForm);
