export const actions = {
  fetchArticlesRequest: (query: string) =>
    ({
      type: '[ARTICLES] FETCH_ARTICLES_REQUEST',
      payload: { query }
    } as const),

  fetchArticlesSuccess: (article: Array<IArticleItem>) =>
    ({
      type: '[ARTICLES] FETCH_ARTICLES_SUCCESS',
      payload: article
    } as const),

  fetchArticlesError: (message: string) =>
    ({
      type: '[ARTICLES] FETCH_ARTICLES_ERROR',
      payload: message
    } as const),

  clearArticlesData: () =>
    ({
      type: '[ARTICLES] CLEAR_ARTICLES_DATA'
    } as const)
};
