import { useEffect } from 'react';
import { submit } from './submit';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { FormComponentProps } from 'antd/lib/form/Form';

export interface IRewardParamsProps
  extends FormComponentProps<IRewardsParamsFormValues> {
  initialValues?: IInitialValues;
}

export interface IRewardsParamsFormValues {
  year: number;
  description: string;
  projectId: number;
  favorite: boolean;
  typeId: number;
  level: number;
}

interface IInitialValues {
  id: number;
  type: number;
  year: number;
  level: number;
  createdAt: string;
  favorite: boolean;
  projectId: number;
  typeTitle: string;
  levelType: string;
  levelLabel: string;
  description: string;
  projectName: string;
  projectSlug: string;
}

interface IPresenterProps {
  initialValues?: IInitialValues;
  form: WrappedFormUtils<IRewardsParamsFormValues>;
}

const usePresenter = ({ initialValues, form }: IPresenterProps) => {
  useEffect(() => {
    if (!initialValues) {
      return;
    }

    form.setFieldsValue({ ...initialValues });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    form.validateFields((err, values) => {
      const id = initialValues ? initialValues.id : null;
      if (!err) {
        submit(
          {
            ...values
          },
          id
        );
      }
    });
  };

  return { handleSubmit };
};

export default usePresenter;
