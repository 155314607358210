import React, { useEffect } from 'react';
import { ArticleParams } from 'components/Forms';
import Editor from 'components/Editor';
import { useDispatch } from 'react-redux';
import { setEditorblocks } from 'store/editor/actions';
import { actions } from 'store/article/actions';
import styled from 'styled-components';

const CreateArticlePage: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setEditorblocks([]));
    dispatch(actions.clearArticleData());
    // eslint-disable-next-line
  }, []);

  return (
    <Page>
      <ArticleParams isCreateArticle />
      <EditorWrapper>
        <Editor />
      </EditorWrapper>
    </Page>
  );
};

const Page = styled.article`
  width: 100%;
`;

const EditorWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 50px;
  background-color: $white;
  box-shadow: 0 0 4px;
`;

export default CreateArticlePage;
