// eslint-disable-next-line
import './styles.css';
import Ui from './ui';
import Uploader from './uploader';
import { IResponse } from './uploader';

export interface IImage {
  id: number | null | undefined;
  url: string;
}

export interface ICard {
  alt: string | null;
  image: string;
  image_id: number | null;
  description: string;
  title: string;
  url: string | null;
}

class CardsNoNumbers {
  api: any;
  config: any;
  uploader: any;
  tunes: any;
  ui: any;
  _data: {
    content: ICard[];
    params: {
      color: {
        label: string;
        value: string;
      };
      isHidden: boolean;
      numCols: string;
    };
  };

  constructor({ data, config, api }: any) {
    this.api = api;

    document.addEventListener('customClick', () => this.save(), false);

    this.config = {
      endpoints: config.endpoints || '',
      additionalRequestData: config.additionalRequestData || {},
      additionalRequestHeaders: config.additionalRequestHeaders || {},
      field: config.field || 'image',
      types: config.types || 'image/*',
      buttonContent: config.buttonContent || '',
      uploader: config.uploader || undefined
    };

    this.uploader = new Uploader({
      config: this.config,
      onUpload: (response: any, index: number) =>
        this.onUpload(response, index),
      onError: (error: any, index: number) => this.uploadingFailed(error, index)
    });

    this.ui = new Ui({
      api,
      config: this.config,
      onSelectFile: (index: number) => {
        this.uploader.uploadSelectedFile(
          {
            onPreview: (src: any, index: number) => {
              this.ui.showPreloader(src, index);
            }
          },
          index
        );
      },
      addCard: () => {
        this.data.content.push({
          alt: null,
          description: '',
          image: '',
          image_id: null,
          title: '',
          url: null
        });
      },
      setTitle: (title: string, index: number) => {
        this.data.content[index].title = title;
      },
      setDesc: (desc: string, index: number) => {
        this.data.content[index].description = desc;
      },
      setAlt: (alt: string, index: number) => {
        this.data.content[index].alt = alt;
      },
      setUrl: (url: string, index: number) => {
        this.data.content[index].url = url;
      },
      deleteCard: (index: number) => {
        this.data.content.splice(index, 1);
      }
    });

    this._data = {
      content: [],
      params: {
        color: {
          label: 'Белый',
          value: '#ffffff'
        },
        isHidden: false,
        numCols: '3'
      }
    };

    this.data = data;
  }

  get data() {
    return this._data;
  }

  set data(data) {
    if (Object.keys(data).length !== 0) {
      for (let i = 0; i < data.content.length; i++) {
        this.ui.nodes.buttonAdd.dispatchEvent(new Event('click'));

        this._data.content[i].alt = data.content[i].alt;
        this._data.content[i].description = data.content[i].description;
        this._data.content[i].title = data.content[i].title;
        this._data.content[i].url = data.content[i].url;
        this._data.content[i].image = data.content[i].image;
        this._data.content[i].image_id = data.content[i].image_id;

        this.ui.nodes.cards[i].childNodes[1].value =
          this._data.content[i].title;
        this.ui.nodes.cards[i].childNodes[4].value = this._data.content[i].alt;
        this.ui.nodes.cards[i].childNodes[5].value = this._data.content[i].url;
        this.ui.nodes.cards[i].childNodes[6].innerHTML =
          this._data.content[i].description;
        this.ui.nodes.selectBlockColor.value = this._data.params.color.label;

        this.setImage(
          {
            url: this._data.content[i].image,
            id: this._data.content[i].image_id
          },
          i
        );

        this.ui.nodes.selectBlockColor.dispatchEvent(new Event('input'));
      }
    }
  }

  save() {
    return {
      content: this.data.content,
      params: {
        color: {
          label: this.ui.nodes.selectBlockColor.value,
          value: this.ui.setOutputColorValue()
        },
        //warning
        isHidden: false,
        numCols: this.ui.nodes.selectNumCols.value
      }
    };
  }

  setImage(image: IImage, index: number) {
    this._data.content[index].image = image.url;
    this._data.content[index].image_id = image.id!;

    if (image && image.url) {
      this.ui.fillImage(image.url, index);
    }
  }

  onUpload(response: IResponse, index: number) {
    if (response.success) {
      this.setImage(response.image, index);
    } else {
      this.uploadingFailed(
        'incorrect response: ' + JSON.stringify(response),
        index
      );
    }
  }

  uploadingFailed(errorText: any, index: number) {
    console.log('Image Tool: uploading failed because of', errorText);

    this.api.notifier.show({
      message: 'Couldn’t upload image. Please try another.',
      style: 'error'
    });
    this.ui.hidePreloader(index);
  }

  uploadFile(file: any) {
    this.uploader.uploadByFile(file, {
      onPreview: (src: any) => {
        this.ui.showPreloader(src);
      }
    });
  }

  render() {
    return this.ui.render(this.data);
  }

  static get pasteConfig() {
    return {
      tags: ['img'],

      patterns: {
        image: /https?:\/\/\S+\.(gif|jpe?g|tiff|png)$/i
      },

      files: {
        mimeTypes: ['image/*']
      }
    };
  }

  static get toolbox() {
    return {
      icon: 'CNN',
      title: 'Карточки без номеров'
    };
  }

  static get enableLineBreaks() {
    return true;
  }
}

export default CardsNoNumbers;
