import ajax from '@codexteam/ajax';
import { AxiosResponse } from 'axios';

interface IOnUploadResponse {
  images: IImage[];
  success: number;
}

export default class Uploader {
  data: any;
  config: any;
  onUpload: any;
  onError: any;

  constructor({ data, config, onUpload, onError }: any) {
    this.data = data;
    this.config = config;
    this.onUpload = onUpload;
    this.onError = onError;
  }

  uploadSelectedFile({ onPreview }: any) {
    const preparePreview = (files: IImage[]) => {
      files.forEach(file => {
        onPreview(file);
      });
    };

    let upload;

    // custom uploading
    if (
      this.config.uploader &&
      typeof this.config.uploader.uploadByFile === 'function'
    ) {
      upload = ajax
        .selectFiles({ accept: this.config.types, multiple: true })
        .then(async (files: File[]) => {
          const promises = this.config.uploader.uploadByFile(files);

          return await Promise.all<AxiosResponse<IImage>>(promises)
            .then(res => res.map(el => el.data))
            .then(res => ({ images: [...res], success: 1 }));
        });

      // default uploading
    } else {
      upload = ajax
        .transport({
          url: this.config.endpoints.byFile,
          data: this.config.additionalRequestData,
          accept: this.config.types,
          headers: this.config.additionalRequestHeaders,
          beforeSend: (files: any) => {
            preparePreview(files[0]);
          },
          fieldName: this.config.field
        })
        .then((response: any) => response.body);
    }

    upload
      .then((response: IOnUploadResponse) => {
        this.onUpload(response);
        preparePreview(response.images);
      })
      .catch((error: string) => this.onError(error));
  }

  uploadByUrl(url: string) {
    let upload;

    if (
      this.config.uploader &&
      typeof this.config.uploader.uploadByUrl === 'function'
    ) {
      upload = this.config.uploader.uploadByUrl(url);

      if (!isPromise(upload)) {
        console.warn(
          'Custom uploader method uploadByUrl should return a Promise'
        );
      }
    } else {
      upload = ajax
        .post({
          url: this.config.endpoints.byUrl,
          data: Object.assign(
            {
              url: url
            },
            this.config.additionalRequestData
          ),
          type: ajax.contentType.JSON,
          headers: this.config.additionalRequestHeaders
        })
        .then((response: any) => response.body);
    }

    upload
      .then((response: any) => {
        this.onUpload(response);
      })
      .catch((error: any) => {
        this.onError(error);
      });
  }

  uploadByFile(file: any, { onPreview }: any) {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = e => {
      if (!e.target) {
        return;
      }
      onPreview(e.target.result);
    };

    let upload;

    if (
      this.config.uploader &&
      typeof this.config.uploader.uploadByFile === 'function'
    ) {
      upload = this.config.uploader.uploadByFile(file);

      if (!isPromise(upload)) {
        console.warn(
          'Custom uploader method uploadByFile should return a Promise'
        );
      }
    } else {
      const formData = new FormData();

      formData.append(this.config.field, file);

      if (
        this.config.additionalRequestData &&
        Object.keys(this.config.additionalRequestData).length
      ) {
        Object.entries(this.config.additionalRequestData).forEach(
          ([name, value]) => {
            formData.append(name, value as any);
          }
        );
      }

      upload = ajax
        .post({
          url: this.config.endpoints.byFile,
          data: formData,
          type: ajax.contentType.JSON,
          headers: this.config.additionalRequestHeaders
        })
        .then((response: any) => response.body);
    }

    upload
      .then((response: any) => {
        this.onUpload(response);
      })
      .catch((error: any) => {
        this.onError(error);
      });
  }
}

function isPromise(object: any) {
  return Promise.resolve(object) === object;
}
