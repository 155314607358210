import { useEffect, useState, useCallback } from 'react';
import { Http } from 'services/HttpService';
import { notify } from 'utils/notify';

const usePresenter = (slug: string) => {
  const [show, setShow] = useState<boolean>(false);
  const [id, setId] = useState<string>('');
  const [value, setValue] = useState<string>('');

  const onOpenModal = useCallback(
    (event: CustomEvent<{ id: string; value: string }>) => {
      Http.get(`${slug}`).then(response => {
        if (response.status === 200) {
          console.log(response);
          setId(event.detail.id);
          setValue(response.data.info.description);
          setShow(!show);
        }
      });
    },
    [show] // eslint-disable-line
  );

  useEffect(() => {
    document.addEventListener(
      'openTinymcePlugginModal',
      event => onOpenModal(event as CustomEvent<{ id: string; value: string }>),
      false
    );
  }, [onOpenModal]);

  const onChange = (value: string) => {
    setValue(value);
  };

  const onClick = () => {
    setShow(!show);
  };

  const onCancel = useCallback(() => {
    setShow(!show);
  }, [show]);

  const onSave = () => {
    const element = document.getElementById(id);

    if (!element) {
      return;
    }

    element.innerHTML = value;
    setShow(false);
    Http.post(`page-information/${slug}`, { description: value })
      .then(response => {
        if (response.status === 200) {
          notify.success('Описание изменено');
        }
      })
      .catch(notify.error);
  };

  return {
    onSave,
    onChange,
    onOpenModal,
    onCancel,
    onClick,
    show,
    value,
    id
  };
};

export default usePresenter;
