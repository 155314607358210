export const actions = {
  fetchCategoriesRequest: (query: string) =>
    ({
      type: '[CATEGORIES] FETCH_CATEGORIES_REQUEST',
      payload: { query }
    } as const),

  fetchCategoriesSuccess: (
    categories: Array<{ label: string; value: string }>
  ) =>
    ({
      type: '[CATEGORIES] FETCH_CATEGORIES_SUCCESS',
      payload: { categories }
    } as const),

  fetchCategoriesError: (message: string) =>
    ({
      type: '[CATEGORIES] FETCH_CATEGORIES_ERROR',
      payload: { message }
    } as const),

  clearCategoriesData: () =>
    ({
      type: '[CATEGORIES] CLEAR_CATEGORIES_DATA'
    } as const)
};
