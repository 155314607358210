import {
  SET_CURRENT_PAGE,
  SET_TOTAL_PAGES,
  SET_PAGE_SIZE,
  ISetCurrentPage,
  ISetTotalPages,
  ISetPageSize
} from './actions';

const initialState = {
  currentPage:
    Number(window.localStorage.getItem('pagination_currentPage')) || 1,
  total: undefined as number | undefined,
  pageSize: undefined as number | undefined
};

export const reducer = (
  state: typeof initialState = initialState,
  action: ISetCurrentPage | ISetTotalPages | ISetPageSize
) => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      window.localStorage.setItem(
        'pagination_currentPage',
        String(action.payload)
      );
      return {
        ...state,
        currentPage: action.payload
      };
    case SET_TOTAL_PAGES:
      return {
        ...state,
        total: action.payload
      };
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload
      };
    default:
      return state;
  }
};
