import ajax from '@codexteam/ajax';
import { Http } from 'services/HttpService';

interface IOnUploadResponse {
  id: number;
  original: string;
  medium: string;
  large: string;
}

export default class Uploader {
  data: any;
  onUpload: any;
  onError: any;

  constructor({ data, onUpload, onError }: any) {
    this.data = data;
    this.onUpload = onUpload;
    this.onError = onError;
  }

  uploadSelectedFile() {
    ajax.selectFiles({ multiple: false }).then((files: File[]) => {
      return Array.from(files).map(async el => {
        const formData = new FormData();
        formData.append('image', el);
        return Http.post<IOnUploadResponse>('images', formData)
          .then(response => {
            this.onUpload({
              id: response.data.id,
              url: response.data.medium
            });
          })
          .catch((error: string) => {
            this.onError(error);
          });
      });
    });
  }
}
