import moment from 'moment';

export function getInitialValues(data: IService) {
  const { service, isPublished } = data;

  const initialValues = {
    id: service.id,
    alt: service.alt,
    slug: service.slug,
    title: service.title,
    tildaJs: service.tildaJs,
    isTilda: service.isTilda,
    icon: service.images.icon,
    articles: service.articles,
    projects: service.projects,
    isShowed: service.isShowed,
    cover: service.images.cover,
    tildaCss: service.tildaCss,
    tildaHtml: service.tildaHtml,
    isFavorite: service.isFavorite,
    url: service.url,
    categories: service.categories,
    description: service.description,
    seoTitle: service.seo ? service.seo.title : '',
    seoDescription: service.seo ? service.seo.description : '',
    publishedAt: service.publishedAt ? moment(service.publishedAt) : moment(),
    isPublished
  };

  return initialValues;
}
